import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'home';
export default {

    loadInfosHomes(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_INFO_HOME', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadHomeFilter(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}home-company/${id}`)
                .then(response => {
                    context.commit('LOAD_INFO_HOME', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

}