import AdminComponent from '@/components/dashboard/AdminComponent'
import WeatherAppSearch from '@/views/search/WeatherAppSearch'
import WeatherAppCreate from '@/views/create/WeatherAppCreate'
import WeatherAppUpdate from '@/views/update/WeatherAppUpdate'
import WeatherAppShow from '@/views/show/WeatherAppShow'

export default {
    path: '/weather-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'weather-app-search', component: WeatherAppSearch },
        { path: 'create', name: 'weather-app-create', component: WeatherAppCreate },
        { path: 'edit/:id', name: 'weather-app-edit', component: WeatherAppUpdate, props: true },
        { path: 'show/:id', name: 'weather-app-show', component: WeatherAppShow, props: true },
    ]
}