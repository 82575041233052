<template>
    <PreLoader/>
    <div class="toast-container">
        <MyToasts/>
    </div>

    <div class="toast" v-if="exibirErro">
        <div class="sidebar error"></div>
        <div class="content">
            <div class="header">
                <h3>Oops!</h3>
            </div>
            <div class="text">
                <p>Sem conexão com a internet</p>
            </div>
        </div>
    </div>

    <div class="toast" v-if="exibirSucesso">
        <div class="sidebar success"></div>
        <div class="content">
            <div class="header">
                <h3>Sucesso</h3>
            </div>
            <div class="text">
                <p>Conectado a internet</p>
            </div>
        </div>
    </div>


    <nav-menu v-if="!currentNamePageLogin && !currentNamePageRecoverPassword" :open-menu="toggle" @menu-resp="toggleMenu"/>
    <div class="painel" v-if="!currentNamePageLogin && !currentNamePageRecoverPassword" :class="{ toggle: toggle }">
        <div class="header">
            <h1>{{ currentNamePage }}</h1>
            <div class="menu-toggle" @click="toggleMenu()">
                <div class="hamburger-menu-button">
                    <div class="hamburger-menu" :class="{ open: toggle }"></div>
                </div>
            </div>
            <i class="bell outline icon"></i>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
                <div :key="route.name">
                    <component :is="Component"/>
                </div>
            </transition>
        </router-view>
    </div>

    <div v-else>
        <router-view></router-view>
    </div>

</template>

<script>
import NavMenu from '@/components/nav/NavMenu';
import MyToasts from '@/components/toast/MyToasts';
import PreLoader from '@/components/PreLoader';

// import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    name: 'AdminComponent',

    components: {
        NavMenu,
        MyToasts,
        PreLoader
    },

    mounted() {
        window.addEventListener("offline", () => {
            this.exibirErro = true
            this.$store.commit('PRELOADER', true)
        }, false)

        window.addEventListener("online", () => {
            this.exibirSucesso = true
            setTimeout(() => {
                this.exibirSucesso = false
                this.exibirErro = false
            }, 1500)
            this.$store.commit('PRELOADER', false)
        }, false)
    },

    data() {
        return {
            toggle: false,

            exibirErro: false,
            exibirSucesso: false,

        }
    },

    methods: {
        toggleMenu() {
            this.toggle = !this.toggle;
        }
    },

    computed: {
        currentNamePage() {
            return this.$store.state.var.namePage
        },

        currentNamePageLogin() {
            return this.$route.name == 'login'
        },

        currentNamePageRecoverPassword() {
            return this.$route.name == 'recover-password'
        }
    }
}
</script>

<style scoped>
.toast-container {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 105;
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 75px;
    border-bottom: 1px solid #ddd;
}

.header h1 {
    margin-left: 50px;
    font-size: 1.9em;
    text-transform: uppercase;
}

.painel {
    /* padding: 0 25px 0 25px; */
    position: relative;
    float: right;
    min-height: 100vh;
    width: calc(100% - 260px);
    background-color: #f4f3ef;
    padding-bottom: 50px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/*@media (max-width: 1290px) {
    .toggle {
        transform: translate3d(260px, 0, 0);
    }

    .header {
        width: 100%;
        transition: all 0.4s linear;
    }

    .header h1 {
        margin-left: 75px;
        font-size: 1.3em;
    }

    .painel {
        width: 100%;
        transition: all 0.4s linear;
    }
}*/

@media (max-width: 991px) {
    .toggle {
        transform: translate3d(260px, 0, 0);
    }

    .header {
        width: 100%;
        transition: all 0.4s linear;
    }

    .header h1 {
        margin-left: 75px;
        font-size: 1.3em;
    }

    .painel {
        width: 100%;
        transition: all 0.4s linear;
    }
}
</style>

<style scoped>
:root {
    --cream-color: #faf8f7;
    --light-grey-color: #e7e7e7;
    --grey-color: #cccccc;
    --white-color: #FFFFFF;
    --green-color: #009900;
    --red-color: #990000;
    --blue-color: #009999;
}

.success,
.error {
    color: var(--white-color);
}

.success {
    background-color: var(--green-color);
}

.error {
    background-color: var(--red-color);
}

.toast {
    right: 0;
    margin: 15px;
    position: absolute;
    z-index: 105;
    width: 350px;
    min-height: 50px;
    display: flex !important;
    flex-direction: row;
    padding: 1rem;
    box-sizing: border-box;
    background-color: var(--cream-color);
    justify-content: center;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 0.5rem;
}

.toast p {
    font-weight: 100;
}

.toast .sidebar {
    width: .5rem;
}

.toast .content,
.toast .header,
.toast .text {
    width: 100%;
    display: flex;
}

.toast .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    box-sizing: border-box;
}

.toast .header,
.toast .text {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.toast .header {
    height: 25px;
    margin-bottom: 1rem;
}

.toast .header button {
    margin-left: auto;
    text-decoration: none;
    border: none;
    cursor: pointer;
    background: none;
    font-size: 1rem;
}

.toast .header button:hover {
    background: var(--light-grey-color);
}

.toast .content {
    flex-grow: 1;
}
</style>