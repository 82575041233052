<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome do Grupo:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.permission }" v-for="(permission, index) in selectPermissions"
                    :key="index">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input " type="checkbox" @click="checkAll(index)" :value="index"
                            :id="'checkBoxMaster' + index" :name="index">
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>
                    <div class="form-check " v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input type="checkbox" :value="option.value" :id="indexTwo"
                            :class="'form-check-input checkBoxAll' + index + ' checkOne' + option.value"
                            @click="checOne(index, permission)">
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd" />
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.commit('PRELOADER', true)
        this.loadGroups()
        this.checkACL()
    },

    name: 'GroupUserCreate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
    },

    data() {
        return {
            buttonAdd: {
                route: '/group-user/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                name: '',
                status: '',
                permissions: []
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        loadGroups() {
            this.$store.dispatch('loadSelectPermissions', 'page')
                .then(() => { })
                .catch(() => { this.$store.commit('addToast', ACTION_MESSAGES.errorDefault) })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeGroupUser', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$router.push({ name: 'group-user-edit', params: { id: r.data.id } })
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('group-user-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        checOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            var cont = 0;
            for (var i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    var indexArry = this.formDate.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArry !== -1) {
                        this.formDate.permissions.splice(indexArry, 1);
                    }
                }
            }
            if (cont >= permission.options.length) {
                document.getElementById("checkBoxMaster" + index).checked = true
            } else {
                document.getElementById("checkBoxMaster" + index).checked = false
            }
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (var i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (var cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    var indexArry = this.formDate.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArry !== -1) {
                        this.formDate.permissions.splice(indexArry, 1);
                    }
                }
            }
        },
    },

    computed: {
        selectPermissions() {
            return this.$store.state.var.selectPermissions
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }
}

</script>
