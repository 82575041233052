<template>
    <router-link :to="itens.route" class="btn btn-secundary btnAdd" :tooltip="itens.tooltip">
        <i class="icon"><img :src="require(`@/assets/icones/${itens.icon}.svg`)"></i>
        {{ itens.name }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonAdd',
    props: {
        itens: {
            "type": Object,
            "default": []
        },
    },
}
</script>

<style scoped>
.btnAdd {
    /* min-width: 115px; */
    min-width: 42px;
    padding: 10px;
    line-height: 20px;
    height: 42px;
    background-color: #66615b;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out
}

.btnAdd:hover {
    color: #fff;
    background-color: #505050;
    border-color: #505050;
}

.btnAdd a {
    text-decoration: none;
    color: #fff;
    padding: 10px;
}

.btnAdd:not([disabled]):hover,
.btnAdd:not([disabled]):active {
    color: #fff;
    background-color: #505050 !important;
    border-color: #505050;

}

[tooltip]::after {
    font-size: 12px;
}

i.icon img {
    vertical-align: bottom;
    filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(110%) contrast(110%)
}
</style>