<template>
    <painel-component :title="'Ver'">
        <form>

            <div class="container-avatar-user">
                <img v-if="formDate.photo" :src="formDate.photo">
                <img v-else src="@/assets/img/avatar.png">
                {{ formDate.name }}
            </div>


            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Login (Email):</label>
                    <input-component disabled :type-input="'email'" v-model="formDate.email" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                    <label>Nome do Usuário:</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'">
                    <label>Sexo:</label>

                    <input type="radio" id="feminino" name="radio" v-model="formDate.sexo" value="1" disabled>
                    <label for="feminino">Feminino</label>

                    <input type="radio" id="masculino" name="radio" v-model="formDate.sexo" value="2" disabled>
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Grupo:</label>
                    <multiselect v-model="formDate.group_id" :searchable="true" :options="selectGroupUser" disabled
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive" disabled
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input" disabled type="checkbox" id="checkBoxMaster">
                        <label class="form-check-label">Empresas</label>
                    </div>

                    <div class="form-check" v-for="(company, index) in selectCompanyRestrict" :key="index">
                        <input type="checkbox" disabled :value="company.value" :id="'checkOne' + company.value"
                            :class="'form-check-input checkBoxAll'">
                        <label class="form-check-label">{{ company.name }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>

        </form>
    </painel-component>
</template>
  
<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.$store.commit('PRELOADER', true)
        this.loadUser()
    },

    name: 'UserShow',

    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
    },

    props: { id: { required: true } },

    data() {
        return {
            buttonAdd: {
                route: '/user/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            formDate: {
                name: '',
                email: '',
                group_id: '',
                status: '',
                photo: '',
                sexo: '',
                companies: []
            },

        }
    },

    methods: {
        loadUser() {
            this.$store.dispatch('loadUser', this.id).then((r) => {
                this.formDate = r;
                this.formDate.updatepassword = false;
                this.checkACL()
                this.$store.dispatch('loadSelectGroupUser')
                this.$store.dispatch('loadSelectCompanyRestrict')
                setTimeout(() => {
                    this.checkBoxCheckedStart()
                }, 1100)
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        checkBoxCheckedStart() {
            let totalpage = this.selectCompanyRestrict.length;
            let cont = 0;
            let i = 0;
            let companiesList = this.formDate.companies;
            this.selectCompanyRestrict.forEach(function (element) {
                if (companiesList.includes(element.value)) {
                    document.getElementById("checkOne" + element.value).checked = true
                    cont = cont + 1;
                }
                if (cont >= totalpage) {
                    document.getElementById("checkBoxMaster").checked = true
                }
                i = i + 1;
            });
        },
    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        selectGroupUser() {
            return this.$store.state.var.selectGroupUser
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        }
    },

    beforeMount() {
        this.selectGroupUser.push({ "label": "Master", "value": 1 })
    }
}
</script>
  
<style scoped>
.container-avatar-user {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    justify-content: flex-start;
    height: 130px;
    width: 100%;
}

.container-avatar-user>img {
    border-radius: 50%;
    margin-right: 15px;
    width: 100px;
}

@media(max-width: 991px) {
    .container-avatar-user {
        margin: 5% 0;
        flex-direction: column;
        align-items: center;
    }
}
</style>