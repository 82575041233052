export default {
    icon: 'archive',
    name: 'Conteúdo',
    route: '/content/search',
    pagePermissions: [
        'content-folder-search',
        'content-folder-create',
        'content-folder-edit',
        'content-folder-delete',
    ],

    pageLinksNavCheck: [
        'content-folder-search',
        'content-folder-create',
        'content-folder-edit',
        'content-folder-delete',
    ]
}