import actions from '@/store/modules/menu-table-item/actions'
import getters from '@/store/modules/menu-table-item/getters'
import mutations from '@/store/modules/menu-table-item/mutations'
import state from '@/store/modules/menu-table-item/state'

export default {
    actions,
    getters,
    mutations,
    state
}