export default {
    icon: 'iphone_portrait',
    name: 'Aplicativo',
    route: '/app/search',
    pagePermissions: [
        "app-clima-edit",
        "app-clima-search",
        "app-clima-delete",
        "app-clima-create",
        "app-news-edit",
        "app-news-search",
        "app-news-delete",
        "app-news-create",
        "app-lottery-edit",
        "app-lottery-search",
        "app-lottery-delete",
        "app-lottery-create",
        "app-clock-edit",
        "app-clock-search",
        "app-clock-delete",
        "app-clock-create",
        "app-menu-table-edit",
        "app-menu-table-search",
        "app-menu-table-delete",
        "app-menu-table-create",
        "app-youtube-edit",
        "app-youtube-search",
        "app-youtube-delete",
        "app-youtube-create",
        "app-web-url-edit",
        "app-web-url-search",
        "app-web-url-delete",
        "app-web-url-create",
        "app-notice-elevator-edit",
        "app-notice-elevator-search",
        "app-notice-elevator-delete",
        "app-notice-elevator-create",
        "app-obituary-room-edit",
        "app-obituary-room-search",
        "app-obituary-room-delete",
        "app-obituary-room-create",
        "app-obituary-room-add",
        "app-obituary-room-add-edit",
        "app-obituary-room-add-delete"
    ],

    pageLinksNavCheck: [
        "app-clima-edit",
        "app-clima-search",
        "app-clima-delete",
        "app-clima-create",
        "app-news-edit",
        "app-news-search",
        "app-news-delete",
        "app-news-create",
        "app-lottery-edit",
        "app-lottery-search",
        "app-lottery-delete",
        "app-lottery-create",
        "app-clock-edit",
        "app-clock-search",
        "app-clock-delete",
        "app-clock-create",
        "app-menu-table-edit",
        "app-menu-table-search",
        "app-menu-table-delete",
        "app-menu-table-create",
        "app-youtube-edit",
        "app-youtube-search",
        "app-youtube-delete",
        "app-youtube-create",
        "app-web-url-edit",
        "app-web-url-search",
        "app-web-url-delete",
        "app-web-url-create",
        "app-notice-elevator-edit",
        "app-notice-elevator-search",
        "app-notice-elevator-delete",
        "app-notice-elevator-create",
        "app-obituary-room-edit",
        "app-obituary-room-search",
        "app-obituary-room-delete",
        "app-obituary-room-create",
        "app-obituary-room-add",
        "app-obituary-room-add-edit",
        "app-obituary-room-add-delete"
    ]
}