import axios from "axios";
import { URL_BASE } from "@/configs/constants";
const RESOURCE = 'campaing-contents';

export default {

    loadCampaingsContents(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_CAMPAIGN_CONTENT', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}