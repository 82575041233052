<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <div class="window-modal-content" v-if="modalContent">
            <MenuTableBackground :id="idModal" @close-modal-content="closeModalContent"/>
        </div>

        <div class="action-buttons">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button-one :itens="buttonGroup.buttonBackground" @click="openModalContent(id)"/>
                <button-one :itens="buttonGroup.buttonItens" @click="routeMenuTableItem(id)"/>
                <button-one :itens="buttonGroup.buttonPreview" @click="routePreview(formDate.urlPreview)"/>
                <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonThree" @click="routeAdd"/>
            </div>
        </div>

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome do APP:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.company_id }">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                                 :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <hr>
            <h1>Configurações do Layout</h1>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.font_id }">
                    <label>Fonte:</label>
                    <multiselect v-model="formDate.font_id" :searchable="true" :options="selectFontsMenuTable"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.text_align }">
                    <label>Alinhamento Texto:</label>
                    <multiselect v-model="formDate.text_align" :searchable="true" :options="selectAlingMenuTable"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.margin_top }">
                    <label>Margem Topo %:</label>
                    <input-component :type-input="'number'" v-model="formDate.margin_top"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.list_transparency }">
                    <label>Transparência %:</label>
                    <input-component :type-input="'number'" v-model="formDate.list_transparency"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.font_color_1 }">
                    <label>Cor da 1º fonte:</label>
                    <input style="width: 100%;" type="color" class="form-control form-control-color"
                           v-model="formDate.font_color_1">
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.list_color_1 }">
                    <label>Cor da 1º listra:</label>
                    <input style="width: 100%;" type="color" class="form-control form-control-color"
                           v-model="formDate.list_color_1">
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.font_color_2 }">
                    <label>Cor da 2º fonte:</label>
                    <input style="width: 100%;" type="color" class="form-control form-control-color"
                           v-model="formDate.font_color_2">
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.list_color_2 }">
                    <label>Cor da 2º listra:</label>
                    <input style="width: 100%;" type="color" class="form-control form-control-color"
                           v-model="formDate.list_color_2">
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                                   :class="{ 'formError': errors.text_upercase }">
                    <label>Texto em Maiúsculo:</label>

                    <input type="radio" id="nao-upercase" name="radio" v-model="formDate.text_upercase" value="1">
                    <label for="nao-upercase">Não</label>

                    <input type="radio" id="sim-upercase" name="radio" v-model="formDate.text_upercase" value="2">
                    <label for="sim-upercase">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                                   :class="{ 'formError': errors.font_bold }">
                    <label>Fonte em Negrito:</label>

                    <input type="radio" id="nao-bold" name="radio-1" v-model="formDate.font_bold" value="1">
                    <label for="nao-bold">Não</label>

                    <input type="radio" id="sim-bold" name="radio-1" v-model="formDate.font_bold" value="2">
                    <label for="sim-bold">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                                   :class="{ 'formError': errors.font_italic }">
                    <label>Fonte em Itálico:</label>

                    <input type="radio" id="nao-italic" name="radio-2" v-model="formDate.font_italic" value="1">
                    <label for="nao-italic">Não</label>

                    <input type="radio" id="sim-italic" name="radio-2" v-model="formDate.font_italic" value="2">
                    <label for="sim-italic">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                                   :class="{ 'formError': errors.font_underline }">
                    <label>Fonte em Sublinhado:</label>

                    <input type="radio" id="nao-underline" name="radio-3" v-model="formDate.font_underline" value="1">
                    <label for="nao-underline">Não</label>

                    <input type="radio" id="sim-underline" name="radio-3" v-model="formDate.font_underline" value="2">
                    <label for="sim-underline">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import MenuTableBackground from '@/views/search/MenuTableBackground';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Menu Tabela')
        this.$store.commit('PRELOADER', true)
        this.loadMenuTableApp()
        this.checkACL()
    },

    name: 'MenuTableUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        MenuTableBackground
    },

    props: {
        id: {required: true}
    },

    data() {
        return {
            buttonAdd: {
                route: '/menu-table-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonThree: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            buttonGroup: {
                buttonBackground: {
                    icon: 'picture',
                    type: 'button',
                    tooltip: 'Alterar Background'
                },

                buttonItens: {
                    icon: 'list_add',
                    type: 'button',
                    tooltip: 'Adicionar Itens'
                },

                buttonPreview: {
                    icon: 'airplay',
                    type: 'button',
                    tooltip: 'Pré Visualizar'
                },
            },

            formDate: {
                name: '',
                status: '',
                company_id: '',
                font_id: '',
                margin_top: '',
                list_transparency: '',
                text_align: '',
                font_color_1: '',
                font_color_2: '',
                list_color_1: '',
                list_color_2: '',
                text_upercase: '',
                font_bold: '',
                font_italic: '',
                font_underline: '',
                urlPreview: ''
            },

            idModal: '',
            modalContent: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        routePreview(url) {
            window.open(url, '_blank', 'noreferrer');
        },

        routeMenuTableItem(id) {
            this.$router.push({name: 'menu-table-item-search', params: {id: id}})
        },

        routeAdd() {
            this.$router.push({name: 'menu-table-app-create'})
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateMenuTableApp', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        loadMenuTableApp() {
            this.$store.dispatch('loadFontsMenuTable')
            this.$store.dispatch('loadSelectCompanyRestrict')
            this.$store.dispatch('loadMenuTableApp', this.id)
                .then((r) => {
                    this.formDate = r
                    this.formDate.id = this.id
                })
                .catch(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                })
        },

        closeModalContent() {
            this.modalContent = !this.modalContent
        },

        openModalContent(id) {
            this.modalContent = !this.modalContent
            this.idModal = id
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 14)
                .then((r) => {
                    if (r.data.indexOf('app-menu-table-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('app-menu-table-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {

        selectFontsMenuTable() {
            return this.$store.state.var.selectFontsMenuTable
        },

        selectAlingMenuTable() {
            return this.$store.state.var.selectAlingMenuTable
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

    }

}
</script>

<style scoped>
.action-buttons {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.window-modal-content {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.window-modal-content > div {
    width: 65%;
    height: 67%;
}

@media (max-width: 1366px) {
    .window-modal-content > div {
        overflow: auto;
        width: 85%;
        height: 86%;
    }
}

@media (max-width: 1280px) {
    .window-modal-content > div {
        width: 70%;
        height: 80%;
    }
}

@media (max-width: 991px) {
    .window-modal-content > div {
        width: 95%;
        height: 95%;
        padding: 12% 5%;
    }
}

@media (max-width: 414px) {
    .window-modal-content > div {
        width: 95%;
        height: 90%;
        padding: 5%;
    }
}
</style>