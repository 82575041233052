import AdminComponent from '@/components/dashboard/AdminComponent'
import GroupUserSearch from '@/views/search/GroupUserSearch'
import GroupUserCreate from '@/views/create/GroupUserCreate'
import GroupUserUpdate from '@/views/update/GroupUserUpdate'
import GroupUserShow from '@/views/show/GroupUserShow'

export default {
    path: '/group-user',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'group-user-search', component: GroupUserSearch },
        { path: 'create', name: 'group-user-create', component: GroupUserCreate },
        { path: 'edit/:id', name: 'group-user-edit', component: GroupUserUpdate, props: true },
        { path: 'show/:id', name: 'group-user-show', component: GroupUserShow, props: true }
    ]
}