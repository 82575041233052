<template>
    <painel-component :title="'Ver'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Nome do APP:</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>URL:</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.url" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Tempo exibição em segundos:</label>
                    <input-component disabled :type-input="'number'" v-model="formDate.time" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect disabled v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Empresa:</label>
                    <Multiselect disabled label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                        :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'App Web')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.commit('PRELOADER', true)
        this.loadWebApp()
        this.checkACL()
    },

    props: {
        id: { required: true }
    },

    name: 'WebAppShow',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
    },

    data() {
        return {
            buttonAdd: {
                route: '/web-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            formDate: {
                id: this.id,
                name: '',
                url: '',
                time: '',
                status: '',
                company_id: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadWebApp() {
            this.$store
                .dispatch("loadWebApp", this.id)
                .then((r) => {
                    this.$store.dispatch("loadSelectCompany");
                    this.$store.dispatch("loadSelectLayout");
                    this.formDate = r;
                    this.formDate.id = this.id
                })
                .catch(() => {
                    this.$store.commit("addToast", ACTION_MESSAGES.errorDefault);
                });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 16)
                .then((r) => {
                    if (r.data.indexOf('app-web-url-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>