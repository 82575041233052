<template>
    <painel-component :title="'Informações do Arquivo'">
        <div @click="$emit('close-file-info')" class="btn-close"></div>

        <form class="mt-5">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Enviado por:</label>
                    <p class="field-text">{{ formDate.name_creator }}</p>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Data de Envio:</label>
                    <p class="field-text">{{ formDate.date_send }}</p>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Hora de Envio:</label>
                    <p class="field-text">{{ formDate.hour_send }}</p>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Tipo:</label>
                    <p class="field-text">{{ formDate.type }}</p>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Duração:</label>
                    <p class="field-text">{{ formDate.time }}</p>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Duplicado:</label>
                    <p class="field-text">{{ statusString(formDate.double) }}</p>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Sincronizado:</label>
                    <p class="field-text">{{ statusString(formDate.synced) }}</p>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';

import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.loadInfosAppFile()
    },

    props: {
        idCampaing: { required: true },
        idAppFile: { required: true }
    },

    name: 'FileCampaignInfo',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    data() {
        return {

            formDate: {
                name_creator: '',
                photo_creator: '',
                date_send: '',
                hour_send: '',
                double: '',
                synced: '',
                type: '',
                time: ''
            },

            params: {
                idCampaing: this.idCampaing,
                idAppFile: this.idAppFile
            }

        }
    },

    methods: {
        loadInfosAppFile() {
            this.$store.dispatch('loadInfosAppFile', this.params).then((r) => {
                this.formDate = r;
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        statusString(value) {
            if (value == 1)
                return 'Não'

            if (value == 2)
                return 'Sim'
        },
    },

}

</script>

<style scoped>
.field-text {
    overflow: hidden;
    white-space: nowrap;
    height: 42px;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #f0f0f0;
    padding: 0.5rem;
    margin: 0;
    text-overflow: ellipsis;
}

label {
    margin-bottom: 1%;
    color: #1d1d1b;
    position: relative;
    left: 10px;
    text-transform: uppercase;
}

label::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    left: -10px;
    background-color: #F97F2C;
}

.btn-close {
    margin: 2%;
    position: absolute;
    top: 0;
    right: 0;
}
</style>
