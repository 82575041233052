<template>
    <painel-component :title="'Adicionar Items'">
        <ErrorComponent :errors="errors" v-if="showError"/>
        <div class="btn-close" @click="$emit('close')"></div>

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome do APP:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.code }">
                    <QuillEditor :contentType="'html'" ref="myEditor" v-model:content="formDate.notice"
                                 theme="snow" style="height: 250px; width: 100%;" :toolbar="toolbar"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import {ACTION_MESSAGES} from '@/configs/messages';
import {Quill, QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "18px",
    "24px",
    "36px",
    "48px",
    "64px",
    "82px",
    "150px",
];
Quill.register(Size, true);

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Avisos')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.loadNoticeAppItem()
    },

    props: {
        id: {required: true},
        idapp: {required: true}
    },

    name: 'NoticeAppItemsUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonOne,
        QuillEditor
    },

    data() {
        return {
            buttonOne: {
                type: 'button',
                icon: 'floppy',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            formDate: {
                id: this.id,
                app_id: this.idapp,
                name: '',
                notice: ''
            },

            params: {
                id: this.id,
                idapp: this.idapp,
            },

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['link', 'image'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction

                [{
                    size: [
                        "8px",
                        "9px",
                        "10px",
                        "11px",
                        "12px",
                        "14px",
                        "18px",
                        "24px",
                        "36px",
                        "48px",
                        "64px",
                        "82px",
                        "150px",
                    ],
                },
                ], // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'align': []}],                                        // remove formatting button
            ],

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadNoticeAppItem() {
            this.$store.dispatch("loadNoticeAppItem", this.params)
                .then((r) => {
                    this.formDate = r;
                    this.$refs.myEditor.setHTML(r.notice)
                })
                .catch(() => {
                    this.$store.commit("addToast", ACTION_MESSAGES.errorDefault);
                });
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateNoticeAppItem', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                        this.$emit('close')
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                        this.$emit('close')
                    })
            }
        },
    },
}
</script>

<style scoped>
.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}
</style>

<style>
.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value) !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    overflow: auto;
    height: 250px;
    border-color: #d1d5db;
}
</style>