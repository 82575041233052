<template>
    <painel-component :title="'Selecionar Background'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <div v-if="modalFile" class="window-modal-content">
            <MenuTableBackgroundFile @close-modal-file="closeModalFile" @submit-file="closeFileSubmit" :id="formDate.id"
                                     :folder_id="folder_id"/>
        </div>

        <div @click="$emit('close-modal-content')" class="btn-close"></div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Nome da Pasta:</label>
                <input-component :type-input="'text'" v-model="formDate.name"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Empresa:</label>
                <multiselect label="name" v-model="formDate.company_id" :searchable="true"
                             :options="selectCompanyRestrict"
                             :noResultsText="this.$store.state.var.selectNotSearch"/>
            </row-col-component>

            <row-col-component class="mt-4-my"
                               :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                <button-one :itens="buttonOne" @submit="submit"/>
            </row-col-component>
        </row-component>

        <div class="content-file-explorer">
            <div class="file-content" v-for="(folder, index) in content_menu_table_app.data" :key="index">
                <div class="file-link-div" @click="openModalFile(folder.id)" :tooltip="folder.name">
                    <img src="@/assets/icones/folder_closed.svg">
                    <p>{{ folder.name }}</p>
                </div>
            </div>
        </div>

        <button-pagination class="pagination-settings" :pagination="content_menu_table_app" :offset="3"
                           @paginate="loadContentsMenusTablesApps"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';
import MenuTableBackgroundFile from '@/views/search/MenuTableBackgroundFile';

export default {
    created() {
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.loadContentsMenusTablesApps(1)
    },

    name: 'MenuTableBackground',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonPagination,
        MenuTableBackgroundFile
    },

    props: {
        id: {required: true}
    },

    emits: ['close-modal-content'],

    data() {
        return {
            buttonOne: {
                icon: 'search',
                tooltip: 'Buscar',
                type: 'button'
            },

            formDate: {
                id: this.id,
                name: '',
                company_id: ''
            },

            folder_id: '',
            modalFile: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        submit() {
            this.loadContentsMenusTablesApps(1)
            this.searchSubmit = true;
        },

        loadContentsMenusTablesApps(page) {
            this.showLoder = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadContentsMenusTablesApps', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.showLoder = true
                    this.searchSubmit = false
                });
            }
        },

        closeModalFile() {
            this.modalFile = !this.modalFile
        },

        closeFileSubmit() {
            this.modalFile = !this.modalFile
            this.$emit('close-modal-content')
        },

        openModalFile(id) {
            this.folder_id = id
            this.modalFile = !this.modalFile
        }

    },

    computed: {

        content_menu_table_app() {
            return this.$store.state.menu_table_app.content_menu_table_app
        },

        params() {
            return {
                page: this.content_menu_table_app.current_page,
                id: this.formDate.id,
                name: this.formDate.name,
                company_id: this.formDate.company_id
            }
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

    }

}
</script>

<style scoped>
.window-modal-content {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    position: fixed;
    place-items: center;
}

.window-modal-content > div {
    width: 65%;
    height: 67%;
}

.pagination-settings {
    right: 0;
    position: absolute;
    bottom: 0;
    margin: 0 30px;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.mt-4-my {
    margin-top: 1.5rem;
}

.content-file-explorer {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    min-height: 100%;
    border-radius: 0 0 10px 10px;
}

.file-content {
    font-size: 14px;
    position: relative;
    display: flex;
    height: fit-content;
    width: 105px;
    margin: 5px 10px;
    align-items: center;
    flex-direction: column;
    word-wrap: break-word;
    text-align: center;
}

.file-link-div {
    height: 100%;
    width: 100%;
}

.file-link-div > img {
    height: 80px;
}

.file-link-div p {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-link-div:hover {
    cursor: pointer;
    background-color: rgb(192, 192, 192, 0.4);
}
</style>

<style scoped>

@media (max-width: 1366px) {
    .window-modal-content > div {
        overflow: auto;
        width: 85%;
        height: 86%;
    }
}

@media (max-width: 1280px) {
    .window-modal-content > div {
        width: 70%;
        height: 80%;
    }
}

@media (max-width: 991px) {
    .file-content p {
        -webkit-line-clamp: 2;
    }

    .mt-4-my {
        margin: 0;
    }

    .window-modal-content > div {
        width: 95%;
        height: 95%;
        padding: 12% 5%;
    }

}

@media (max-width: 414px) {
    .file-content {
        text-align: center;
        align-items: center;
        width: 85px;
        height: 110px;
    }

    .window-modal-content > div {
        width: 95%;
        height: 90%;
        padding: 5%;
        overflow: scroll;
    }

    .file-content img {
        width: 60%;
    }

    .mt-4-my {
        margin: 0;
    }

    .btn-close {
        margin: 5%;
    }

}
</style>