import {createStore} from 'vuex'

import Auth from '@/store/modules/auth'
import Campaign from '@/store/modules/campaign'
import CampaignAddPlayer from '@/store/modules/campaign-add-player'
import CampaignApp from '@/store/modules/campaign-app'
import CampaignContent from '@/store/modules/campaign-content'
import CampaignFile from '@/store/modules/campaign-file'
import ClockApp from '@/store/modules/clock-app'
import Company from '@/store/modules/company'
import ContentFile from '@/store/modules/content-file'
import ContentFolder from '@/store/modules/content-folder'
import ExternalApi from '@/store/modules/external-api'
import Group from '@/store/modules/group'
import Home from '@/store/modules/home'
import LotteryApp from '@/store/modules/lottery-app'
import MenuTableApp from '@/store/modules/menu-table-app'
import MenuTableItem from '@/store/modules/menu-table-item'
import NewsApp from '@/store/modules/news-app'
import NoticeApp from '@/store/modules/notice-app'
import NoticeAppContent from "@/store/modules/notice-app-content";
import ObituaryApp from "@/store/modules/obituary-app";
import ObituaryLocationApp from "@/store/modules/obituary-location-app";
import Player from '@/store/modules/player'
import PlayerAddCampaign from '@/store/modules/player-add-campaign'
import Preloader from '@/store/modules/preloader'
import Spot from '@/store/modules/spot'
import Toasts from '@/store/modules/toasts'
import Tutorial from "@/store/modules/tutorial";
import User from '@/store/modules/user'
import UserLocation from "@/store/modules/user-location";
import Var from '@/store/modules/var'
import WebApp from '@/store/modules/web-app'
import WeatherApp from '@/store/modules/weather-app'
import YoutubeApp from '@/store/modules/youtube-app'

export default createStore({
    modules: {
        auth: Auth,
        campaign: Campaign,
        campaign_add_player: CampaignAddPlayer,
        campaign_app: CampaignApp,
        campaign_content: CampaignContent,
        campaign_file: CampaignFile,
        clock_app: ClockApp,
        company: Company,
        content_file: ContentFile,
        content_folder: ContentFolder,
        external_api: ExternalApi,
        group: Group,
        home: Home,
        lottery_app: LotteryApp,
        menu_table_app: MenuTableApp,
        menu_table_item: MenuTableItem,
        news_app: NewsApp,
        notice_app: NoticeApp,
        notice_app_content: NoticeAppContent,
        obituary_app: ObituaryApp,
        obituary_location_app: ObituaryLocationApp,
        player: Player,
        player_add_campaign: PlayerAddCampaign,
        preloader: Preloader,
        spot: Spot,
        toasts: Toasts,
        tutorial: Tutorial,
        user: User,
        user_location: UserLocation,
        var: Var,
        web_app: WebApp,
        weather_app: WeatherApp,
        youtube_app: YoutubeApp
    }
})