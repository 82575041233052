import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// BootStrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
// CSS
import "@/assets/css/main.css"

import { NAME_TOKEN } from '@/configs/constants'

const tokenAcess = localStorage.getItem(NAME_TOKEN)
if (tokenAcess)
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAcess}`;

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://methoduz.com/';
}
else { axios.defaults.baseURL = 'https://methoduz.d7mmarketing.com.br'; }
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')

store.dispatch('loginCheck')
    .catch(() => { store.dispatch('logout') })