<template>
    <painel-component :title="'Informações Player'">
        <div class="btn-close" @click="$emit('close')"></div>

        <div class="container-tabs">
            <div class="card tabs">
                <input id="tab-1" type="radio" class="tab tab-selector" checked="checked" name="tab"/>
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/info_circle.svg"></i>
                    <span>Informações</span>
                </label>
                <input id="tab-2" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/settings.svg"></i>
                    <span>Logs</span>
                </label>
                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <p class="field-text">{{ formDate.name }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Tipo de Mídia:</label>
                                    <p class="field-text">{{ formDate.type_midia_name }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Segmento:</label>
                                    <p class="field-text">{{ formDate.segment_name }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Localização:</label>
                                    <p class="field-text">{{ formDate.spot_name }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Empresa:</label>
                                    <p class="field-text">{{ formDate.company_name }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Token:</label>
                                    <p class="field-text">{{ formDate.token }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Criado por:</label>
                                    <p class="field-text">{{ formDate.creator_user_name }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Ultima Comunicação:</label>
                                    <p class="field-text">{{ formDate.ultima_comunicacao }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Status Online:</label>
                                    <p class="field-text">{{ statusOnline(formDate.online) }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Status TV:</label>
                                    <p class="field-text">{{ statusTV(formDate.tela) }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Modelo:</label>
                                    <p class="field-text">{{ formDate.modelo }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Versão do Android:</label>
                                    <p class="field-text">{{ formDate.versao_android }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Versão do Aplicativo:</label>
                                    <p class="field-text">{{ formDate.versao_aplicativo }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>IP Externo:</label>
                                    <p class="field-text">{{ formDate.ultimo_ip_externo }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>IP Interno:</label>
                                    <p class="field-text">{{ formDate.ultimo_ip_interno }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Resolução:</label>
                                    <p class="field-text">{{ formDate.resolucao_tela }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>MAC WIFI:</label>
                                    <p class="field-text">{{ formDate.mac_wifi }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>MAC ETHERNET:</label>
                                    <p class="field-text">{{ formDate.mac_ethernet }}</p>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Root:</label>
                                    <p class="field-text">{{ statusRoot(formDate.root) }}</p>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Memória Usada:</label>
                                    <p class="field-text">{{ formDate.memoria }}</p>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-2"></div>
                </section>
            </div>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';

import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.loadPlayerInfo()
        this.checkACL()
    },

    props: {
        id: {required: true}
    },

    name: 'PlayerInfoShow',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    data() {
        return {

            formDate: {
                name: '',
                token: '',
                creator_user_name: '',
                online: '',
                tela: '',
                modelo: '',
                versao_android: '',
                versao_aplicativo: '',
                ultimo_ip_externo: '',
                ultimo_ip_interno: '',
                resolucao_tela: '',
                mac_wifi: '',
                root: '',
                memoria: '',
                type_midia_name: '',
                segment_name: '',
                spot_name: '',
                company_name: '',
                ultima_comunicacao: ''
            },

        }
    },

    methods: {
        loadPlayerInfo() {
            this.$store.dispatch('updateNamePage', 'Player')
            this.$store.dispatch('loadPlayerInfo', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        statusOnline(value) {
            if (value == 1)
                return 'Offline'

            if (value == 2)
                return 'Online'
        },

        statusTV(value) {
            if (value == 1)
                return 'Desligada'

            if (value == 2)
                return 'Ligada'
        },

        statusRoot(value) {
            if (value == 1)
                return 'Não'

            if (value == 2)
                return 'Sim'
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('player-search') !== -1) {
                        return
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

}

</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.tabs > input[type="radio"] + label {
    width: 50%;
}

#tab-2:checked ~ .glider {
    left: 50%;
}

.glider {
    width: 50%;
}

.field-text {
    overflow: hidden;
    white-space: nowrap;
    height: 42px;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #f0f0f0;
    padding: 0.5rem;
    margin: 0;
    text-overflow: ellipsis;
}

label {
    margin-bottom: 1%;
    color: #1d1d1b;
    position: relative;
    left: 10px;
    text-transform: uppercase;
}

label::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    left: -10px;
    background-color: #F97F2C;
}

.btn-close {
    top: 0;
    right: 0;
    position: absolute;
    margin: 2% 3%;
}
</style>
