<template>
    <router-link class="btnEdit" :to="{ name: route, params: { id: idParams } }" tooltip="Editar">
        <img src="@/assets/icones/create.svg">
    </router-link>
</template>

<script>
export default {
    name: 'ButtonEdit',

    props: {
        route: { type: String, required: true },
        idParams: { required: true },
    }
}
</script>

<style scoped>
.btnEdit {
    border: 0;
    outline: none;
    background-color: transparent;
    margin-right: 15px;
}

.btnEdit img {
    filter: invert(56%) sepia(66%) saturate(874%) hue-rotate(77deg) brightness(91%) contrast(82%);
}

.btnEdit:not([disabled]):hover,
.btnEdit:not([disabled]):active {
    background: none !important;
}
</style>