export default {
    icon: 'play_button',
    name: 'Tutorial',
    route: '/tutorial',
    pagePermissions: [
        'video-show'
    ],

    pageLinksNavCheck: [
        'video-show'
    ]
}