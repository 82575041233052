<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <div class="player-add-campaign-button" :data-campaign="formDate.total_campaing_add">
            <button-one :itens="buttonTwo" flow="down" @click="addCampaign = !addCampaign"/>
        </div>

        <div class="player-add-campaign" v-if="addCampaign">
            <PlayerAddCampaign class="player-add-campaign-container" :id="id"
                               @close-player-add-campaign="closeAddCampaign"/>
        </div>

        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonThree" @click="routeAdd"/>

        <form class="mt-5">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   v-if="formDate.show_shuffle_content">
                    <label>Embaralhar conteúdo:</label>

                    <input v-model="formDate.shuffle_content" type="radio" name="radio" id="nao" value="1">
                    <label for="nao">Não</label>

                    <input v-model="formDate.shuffle_content" type="radio" name="radio" id="sim" value="2">
                    <label for="sim">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   v-if="formDate.show_log">
                    <label>Ativar Logs:</label>

                    <input v-model="formDate.log" type="radio" name="log" id="nao-log" value="1">
                    <label for="nao-log">Não</label>

                    <input v-model="formDate.log" type="radio" name="log" id="sim-log" value="2">
                    <label for="sim-log">Sim</label>
                </row-col-component>

              <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                 v-if="formDate.force_orientation == 2">
                <label>Flip (Virar):</label>

                <input v-model="formDate.layout_flip" type="radio" name="flip" id="nao-flip" value="1">
                <label for="nao-flip">Não</label>

                <input v-model="formDate.layout_flip" type="radio" name="flip" id="sim-flip" value="2">
                <label for="sim-flip">Sim</label>
              </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Token:</label>
                    <input-component :type-input="'text'" v-model="formDate.token" disabled/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.type_midia_id }">
                    <label>Tipo de Mídia:</label>
                    <Multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                                 :options="selectTypeMediaPlayer" v-model="formDate.type_midia_id"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.force_orientation }">
                    <label>Forçar Orientação:</label>
                    <Multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                                 :options="selectNoYes" v-model="formDate.force_orientation"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.segmento_id }">
                    <label>Segmento:</label>
                    <Multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                                 :options="selectSegmentPlayer" v-model="formDate.segmento_id"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-5 col-xl-3 col-lg-5 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.spot_id }">
                    <label>Localização:</label>
                    <Multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch" label="name"
                                 :options="selectSpot" v-model="formDate.spot_id"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.orientation }">
                    <label>Resolução em Pixel:</label>
                    <Multiselect label="name" v-model="formDate.orientation" :options="selectResolutionPlayer"
                                 :searchable="true" @select="selectResolution(formDate.orientation)"
                                 :noResultsText="this.$store.state.var.selectNotSearch">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.width }">
                    <label>Largura(Pixel):</label>
                    <input-component
                            :disabled="formDate.orientation != 3 && formDate.orientation != 6  && formDate.orientation != 9"
                            v-model="formDate.width" :type-input="'text'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.heigth }">
                    <label>Altura(Pixel):</label>
                    <input-component
                            :disabled="formDate.orientation != 3 && formDate.orientation != 6 && formDate.orientation != 9"
                            v-model="formDate.heigth" :type-input="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import {ACTION_MESSAGES} from '@/configs/messages';
import PlayerAddCampaign from '@/views/create/PlayerAddCampaign';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Player')
        this.$store.commit('PRELOADER', true)
        this.loadPlayer()
        this.checkACL()
    },

    name: 'PlayerUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
        PlayerAddCampaign
    },

    props: {
        id: {required: true}
    },

    data() {
        return {
            buttonAdd: {
                route: '/player/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonTwo: {
                icon: 'display',
                type: 'button',
                name: '',
                tooltip: 'Adicionar Player'
            },

            buttonThree: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                token: '',
                name: '',
                type_midia_id: '',
                segmento_id: '',
                force_orientation: '',
                spot_id: '',
                width: '',
                heigth: '',
                show_log: '',
                log: '',
                orientation: '',
                shuffle_content: '',
                show_shuffle_content: '',
                total_campaing_add: '',
                layout_flip:1,
            },

            show_log: '',

            addCampaign: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadPlayer() {
            this.$store.dispatch('loadPlayer', this.id).then((r) => {
                this.formDate = r;
                if(this.formDate.layout_flip == null)
                  this.formDate.layout_flip=1
                this.$store.dispatch('loadSelectResolutionPlayer')
                this.$store.dispatch('loadSelectTypeMediaPlayer')
                this.$store.dispatch('loadSelectSegmentPlayer')
                this.$store.dispatch('loadSelectSpot')
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        routeAdd() {
            this.$router.push({name: 'player-create'})
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updatePlayer', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                        this.loadPlayer()
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        selectResolution(value) {
            switch (value) {
                case 1:
                    this.formDate.width = '1280'
                    this.formDate.heigth = '720'
                    break
                case 2:
                    this.formDate.width = '1920'
                    this.formDate.heigth = '1080'
                    break
                case 3:
                    this.formDate.width = ''
                    this.formDate.heigth = ''
                    break
                case 4:
                    this.formDate.width = '720'
                    this.formDate.heigth = '1280'
                    break
                case 5:
                    this.formDate.width = '1080'
                    this.formDate.heigth = '1920'
                    break
                case 6:
                    this.formDate.width = ''
                    this.formDate.heigth = ''
                    break
                case 7:
                    this.formDate.width = '720'
                    this.formDate.heigth = '1280'
                    break
                case 8:
                    this.formDate.width = '1080'
                    this.formDate.heigth = '1920'
                    break
                case 9:
                    this.formDate.width = ''
                    this.formDate.heigth = ''
                    break
            }
        },

        closeAddCampaign() {
            this.addCampaign = !this.addCampaign
            this.loadPlayer(1)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('player-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('player-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectResolutionPlayer() {
            return this.$store.state.var.selectResolutionPlayer
        },

        selectTypeMediaPlayer() {
            return this.$store.state.var.selectTypeMediaPlayer
        },

        selectSegmentPlayer() {
            return this.$store.state.var.selectSegmentPlayer
        },

        selectNoYes() {
            return this.$store.state.var.selectNoYes
        },

        selectSpot() {
            return this.$store.state.var.selectSpot
        },

    }
}

</script>

<style scoped>
.player-add-campaign-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.player-add-campaign-button::before {
    content: attr(data-campaign);
    z-index: 1;
    width: 25px;
    height: 25px;
    top: -35%;
    right: -20%;
    color: black;
    padding: 0.0rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    background-color: #d3d3d3;
}

.player-add-campaign {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.player-add-campaign > .player-add-campaign-container {
    width: 80%;
    height: 85%;
    overflow-y: scroll;
}

.add-button {
    top: 0;
    right: 0;
    margin: 2% 5%;
    position: absolute;
}

@media (max-width: 1280px) {
    .add-button {
        right: 30px;
        margin: 2% 5%;
    }
}

@media (max-width: 991px) {
    .player-add-campaign > .player-add-campaign-container {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
    }

    .add-button {
        right: 40px;
        margin: 2% 5%;
    }
}

@media (max-width: 425px) {
    .player-add-campaign > .player-add-campaign-container {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
    }
}

@media (max-width: 375px) {
    .player-add-campaign > .player-add-campaign-container {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
    }
}
</style>