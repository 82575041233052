<template>
    <div class="main">
        <MyToasts/>

        <div class="banner">
            <span>
                <h1>Bem-vindo ao Methoduz!</h1>
                <p>Software de gerenciamento</p>
            </span>
        </div>

        <div class="form">

            <div class="logo"><img src="@/assets/img/logo.png" alt=""></div>

            <input type="checkbox" id="switch"/>
            <label class="flip-container">
                <div class="flipper">
                    <div class="front">
                        <p>Entrar</p>
                        <p>Novo por aqui? <a href="#">Crie uma conta</a></p>

                        <form autocomplete="off" @submit.prevent="login">
                            <label>Email</label>
                            <input type="text" placeholder="Digite seu email" v-model="formDate.email">

                            <label>Senha</label>

                            <label class="label-flip" for="switch">Esqueceu a senha?</label>
                            <input type="password" placeholder="Digite sua senha" v-model="formDate.password">

                            <button-one @submit="login" :itens="buttonOne" :class="{ 'preloderSend': sendForm }"/>
                        </form>
                    </div>
                    <div class="back">
                        <form autocomplete="off" @submit.prevent="resetPasswordEmail">
                            <p></p>
                            <p>Esqueceu sua senha para acesso ao sistema <span>Methoduz</span>, não se preocupe informe
                                seu e-mail de
                                cadastro que você possui no sistema <span>Methoduz</span> e reenviaremos sua senha.</p>

                            <label>Email</label>
                            <label class="label-flip" for="switch">Login</label>
                            <input type="email" v-model="formDateEmail.email" placeholder="Digite seu email">

                            <button-one @submit="resetPasswordEmail" :itens="buttonTwo"
                                        :class="{ 'preloderSend': sendForm }"/>
                        </form>
                    </div>
                </div>
            </label>

        </div>
    </div>
</template>

<script>
import ButtonOne from '@/components/buttons/ButtonOne'
import MyToasts from '@/components/toast/MyToasts'
import {ACTION_MESSAGES} from '@/configs/messages'

export default {
    name: 'LoginView',
    components: {
        ButtonOne,
        MyToasts
    },

    created() {
        if (this.$store.state.auth.email) {
            this.formDate.email = this.$store.state.auth.email
        }
    },

    data() {
        return {
            sendForm: false,
            buttonOne: {
                icon: 'enter',
                name: 'Entrar',
                type: 'submit'
            },

            formDate: {
                email: '',
                password: ''
            },

            formDateEmail: {
                email: '',
            },

            buttonTwo: {
                icon: 'paper_plane',
                name: 'Enviar',
                type: 'button'
            },
        }
    },

    methods: {
        login() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('login', this.formDate)
                    .then(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', ACTION_MESSAGES.login);
                        setTimeout(() => this.$router.push({name: 'home'}), 2100);
                    })
                    .catch(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', ACTION_MESSAGES.loginError);
                    })
            }
        },

        resetPasswordEmail() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('resetPasswordEmail', this.formDateEmail)
                    .then(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', {
                            title: 'Sucesso!',
                            type: 'success',
                            time: 2500,
                            message: 'Parabéns, as informações para redefinir a senha, foram enviadas no e-mail informado!'
                        });
                        this.$store.state.auth.email = this.formDateEmail.email
                        setTimeout(() => {
                            this.$router.replace({name: 'recover-password'})
                        }, 4000)
                    })
                    .catch(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            time: 60000,
                            message: 'Esse e-mail não está cadastrado no banco de dados!'
                        });
                    })
            }
        }

    },
}

</script>

<style scoped>
/* ########################################################## */
.flip-container {
    display: inline-block;
    width: 100%;
    height: 20%;
    perspective: 1000px;
}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

#switch {
    display: none;
}

#switch:checked ~ .flip-container .flipper {
    transform: rotateY(180deg);
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}

.back p {
    font-size: 13px !important;
}

.back p span {
    margin: 0;
    color: #FF7700 !important;
}

/* ########################################################## */

.main {
    height: 100vh;
    width: 100vw;
    display: flex;
    font-family: 'Segoe UI', sans-serif;
}

.banner {
    height: 100%;
    width: 70%;
    background: rgba(2, 2, 2, 0.7) url(@/assets/img/bg.jpg) no-repeat center;
    background-blend-mode: darken;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

span {
    width: 600px;
    text-align: left;
    margin-right: 25px;
    border-left: 3px solid #fff;
}

h1 {
    color: #fff;
    font-size: 3rem;
}

span p {
    color: #fff;
    margin-left: 4px;
    text-transform: uppercase;
    font-size: 1rem;
}

.form {
    height: 75%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
}

form {
    position: relative;
    padding-top: 20px;
    height: 240px;
}

.logo {
    position: absolute;
    height: 100px;
    width: 250px;
    top: 15%;
}

.logo img {
    height: auto;
    width: 100%;
}

.form p ~ p,
span p ~ p {
    font-size: 1rem;
}

.form p {
    font-size: 2rem;
}

.front a,
.label-flip {
    color: #FF7700 !important;
    font-weight: normal;
}

.front a,
.label-flip:hover {
    color: #000;
}

.label-flip {
    width: unset;
    text-decoration: underline;
    position: absolute;
    right: 0;
}

label {
    width: 100%;
    font-weight: bold;
}

input {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 5px;
    height: 40px;
    width: 100%;
    display: block;
    outline: none;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
}

button {
    height: 40px;
    width: 100%;
    background: #FF7700;
    border-radius: 5px;
    border: 0;
    color: #fff;
    font-weight: bold;
}

button:hover {
    background: #fff;
    border: 1px solid #FF7700;
    color: #000;
}

@media (max-width: 991px) {
    .banner {
        display: none;
    }

    .form {
        width: 50%;
        height: 70%;
    }
}

@media (max-width: 768px) {
    body {
        flex-direction: column;
    }

    .banner {
        display: none;
    }

    .form {
        height: 100%;
        width: 100%;
        padding: 20px;
    }
}
</style>