<template>
    <ModalComponent @submit-logout="destroySpotHour" @close-modal="closeModal" :visible="visible" :message="message"/>
    <div class="modal-add-opening-hour" v-if="modal_add_hour">
        <div class="container-clone-div">
            <div class="div-close-modal-add-hour" @click="closeModalHour">X</div>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'">
                <input type="radio" id="horario" name="radio" v-model="formDateHour.date_close_check" value="1">
                <label for="horario">Horário</label>

                <input @click="clearDaysWeek" type="radio" id="des-feriado" name="radio"
                       v-model="formDateHour.date_close_check" value="2">
                <label for="des-feriado">Desligar Feriado</label>
            </row-col-component>

            <div v-if="formDateHour.date_close_check == 1">
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.name }">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDateHour.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.hour }">
                        <label>Horário:</label>
                        <input-component :type-input="'time'" v-model="formDateHour.hour"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.type }">
                        <label>Ligar / Desligar:</label>
                        <multiselect v-model="formDateHour.type" :searchable="true" :options="selectSpotPoweOn"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>
                </row-component>
            </div>

            <div v-else>
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.name }">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDateHour.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.date_close }">
                        <label>Data:</label>
                        <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formDateHour.date_close"/>
                    </row-col-component>
                </row-component>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   v-if="formDateHour.date_close_check == 1">
                    <label>Dias da Semana:</label>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="1">
                        <label class="form-check-label">Segunda-Feira</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="2">
                        <label class="form-check-label">Terça-Feira</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="3">
                        <label class="form-check-label">Quarta-Feira</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="4">
                        <label class="form-check-label">Quinta-Feira</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="5">
                        <label class="form-check-label">Sexta-Feira</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="6">
                        <label class="form-check-label">Sábado</label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="formDateHour.days_week" type="checkbox" value="7">
                        <label class="form-check-label">Domingo</label>
                    </div>

                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Player:</label>
                    <div v-if="spot_player.length > 0">
                        <div class="form-check" v-for="(data, index) in spot_player" :key="index">
                            <input class="form-check-input" v-model="formDateHour.players" type="checkbox"
                                   :value="data.value">
                            <label class="form-check-label">{{ data.label }}</label>
                        </div>
                    </div>
                    <p class="mt-2" v-else>Nenhum player cadastrado nessa localização</p>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component v-if="edit_hour == false"
                                   :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonFive" @click="submitHour"/>
                </row-col-component>
                <row-col-component v-else :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonOne" @click="updateSpotHour"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>
        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd"/>

        <div class="container-tabs myy-carousel">
            <div class="card tabs">
                <input id="tab-1" type="radio" class="tab tab-selector" checked="checked" name="tab"/>
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/info_circle.svg"></i>
                    <span>Informações</span>
                </label>
                <input id="tab-2" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/settings.svg"></i>
                    <span>Equipamento</span>
                </label>
                <input id="tab-3" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-3" class="tab tab-default">
                    <i><img src="@/assets/icones/clock.svg"></i>
                    <span>Horário de funcionamento</span>
                </label>
                <input id="tab-4" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-4" class="tab tab-warning">
                    <i><img src="@/assets/icones/wifi.svg"></i>
                    <span>Senhas Wifi</span>
                </label>
                <input id="tab-5" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-5" class="tab tab-warning">
                    <i><img src="@/assets/icones/users.svg"></i>
                    <span>Responsáveis</span>
                </label>
                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.name }">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formDate.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Status:</label>
                                    <multiselect v-model="formDate.status" :searchable="true"
                                                 :options="selectActiveInactive"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.company_id }">
                                    <label>Empresa:</label>
                                    <multiselect label="name" v-model="formDate.company_id" :searchable="true"
                                                 :options="selectCompanyRestrict"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.state }">
                                    <label>Estado:</label>
                                    <multiselect v-model="formDate.state" :searchable="true" :options="selectCityState"
                                                 @select="loadSelectCity(formDate.state)"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.city }">
                                    <label>Cidade:</label>
                                    <multiselect v-model="formDate.city" :searchable="true" :options="selectCity"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.cep }">
                                    <label>CEP:</label>
                                    <input-component v-mask="'#####-###'" :type-input="'text'" v-model="formDate.cep"
                                                     @change="searchApiCEP"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Bairro:</label>
                                    <input-component :type-input="'text'" v-model="formDate.district"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Endereço:</label>
                                    <input-component :type-input="'text'" v-model="formDate.address"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5'">
                                    <label>Referência:</label>
                                    <input-text-area :rows="4" v-model="formDate.complement"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne"
                                                @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>

                        </form>
                        <hr>
                        <h1>Observações Internas</h1>
                        <div class="container-obs">
                            <div class="container-obs-user" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                <div class="row-user-obs">
                                    <img :src="obs.user_photo" v-if="obs.user_photo">
                                    <img src="@/assets/img/avatar.png" v-else>
                                    <p>Criado por: {{ obs.user_name }}</p>
                                    <span><img src="@/assets/icones/clock.svg">{{ obs.hour_create }}</span>
                                    <span><img src="@/assets/icones/calendar_month.svg">{{ obs.date_create }}</span>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </div>
                        </div>
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5'">
                                    <label>Observações Internas:</label>
                                    <input-text-area :rows="5" v-model="formDateObs.obs"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one style="width: 100%" :itens="buttonThree" @click="submitObs"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-2">
                        <form>
                            <button-one :itens="buttonFour" @click="addEquipments"/>
                            <div class="container-clone-div" v-for="(equipments, index) in equipmentsAdd" :key="index">
                                <button class="trash-button m-2" type="button" @click="removeEquipments(index)"
                                        v-if="index > 0" tooltip="Remover">
                                    <img src="@/assets/icones/trash.svg">
                                </button>
                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Nome Equipamento:</label>
                                        <input-component :type-input="'text'" v-model="equipments.name"/>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <row-component class="mt-5">
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one style="width: 100%" :itens="buttonOne" @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-3">
                        <form>
                            <button-one :itens="buttonFour" @click="openModalHour"/>
                            <row-component v-if="formDate.openingHours.length == 0">
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                                    <div class="alert alert-secondary icon-message" role="alert">
                                        <img src="@/assets/icones/clock.svg">
                                        Adicione um novo horário
                                    </div>
                                </row-col-component>
                            </row-component>

                            <div class="container-clone-div" v-for="(data, index) in formDate.openingHours"
                                 :key="index">

                                <button class="trash-button m-2" type="button" @click="openModal(data)"
                                        tooltip="Remover">
                                    <img src="@/assets/icones/trash.svg">
                                </button>

                                <button type="button" class="edit-button" @click="showModalHour(data.id)"
                                        tooltip="Editar">
                                    <img src="@/assets/icones/pen.svg">
                                </button>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'">
                                    <input disabled type="radio" :id="'horario' + index" v-model="data.date_close_check"
                                           value="1">
                                    <label :for="'horario' + index">Horário</label>

                                    <input disabled type="radio" :id="'desligar' + index"
                                           v-model="data.date_close_check"
                                           value="2">
                                    <label :for="'desligar' + index">Desligar Feriado</label>

                                </row-col-component>

                                <div v-if="data.date_close_check == 1">
                                    <row-component>
                                        <row-col-component
                                                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                            <label>Nome:</label>
                                            <input-component disabled :type-input="'text'" v-model="data.name"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                                :class="{ 'formError': errors.name }">
                                            <label>Horário:</label>
                                            <input-component disabled="" :type-input="'time'"
                                                             v-model="data.hour"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                            <label>Ligar / Desligar:</label>
                                            <multiselect v-model="data.type" :searchable="true"
                                                         :options="selectSpotPoweOn"
                                                         disabled
                                                         :noResultsText="this.$store.state.var.selectNotSearch"/>
                                        </row-col-component>
                                    </row-component>
                                </div>

                                <div v-else>
                                    <row-component>
                                        <row-col-component
                                                :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                            <label>Nome:</label>
                                            <input-component disabled :type-input="'text'" v-model="data.name"/>
                                        </row-col-component>

                                        <row-col-component
                                                :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                            <label>Data:</label>
                                            <input-component v-mask="'##/##/####'" :type-input="'text'" disabled
                                                             v-model="data.date_close"/>
                                        </row-col-component>
                                    </row-component>
                                </div>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                            v-if="data.date_close_check == 1">
                                        <label>Dias da Semana:</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="1">
                                            <label class="form-check-label">Segunda-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="2">
                                            <label class="form-check-label">Terça-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="3">
                                            <label class="form-check-label">Quarta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="4">
                                            <label class="form-check-label">Quinta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="5">
                                            <label class="form-check-label">Sexta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="6">
                                            <label class="form-check-label">Sábado</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                   disabled value="7">
                                            <label class="form-check-label">Domingo</label>
                                        </div>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Player:</label>
                                        <div class="form-check" v-for="(spot, index) in spot_player" :key="index">
                                            <input class="form-check-input" v-model="data.players" type="checkbox"
                                                   :value="spot.value" disabled>
                                            <label class="form-check-label">{{ spot.label }}</label>
                                        </div>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </form>
                    </div>

                    <div class="item" id="content-4">
                        <form>
                            <button-one :itens="buttonFour" @click="addWifi"/>
                            <div class="container-clone-div" v-for="(wifi, index) in wifiAdd" :key="index">
                                <button class="trash-button m-2" type="button" @click="removeWifi(index)"
                                        v-if="index > 0"
                                        tooltip="Remover">
                                    <img src="@/assets/icones/trash.svg">
                                </button>
                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome Rede:</label>
                                        <input-component :type-input="'text'" v-model="wifi.name"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Senha:</label>
                                        <input-component :type-input="'text'" v-model="wifi.password"/>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <row-component class="mt-5">
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one style="width: 100%" :itens="buttonOne" @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-5">
                        <form>
                            <button-one :itens="buttonFour" @click="addResponsibles"/>
                            <div class="container-clone-div" v-for="(responsibles, index) in responsiblesAdd"
                                 :key="index">
                                <button class="trash-button m-2" type="button" @click="removeResponsibles(index)"
                                        v-if="index > 0" tooltip="Remover">
                                    <img src="@/assets/icones/trash.svg">
                                </button>
                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome:</label>
                                        <input-component :type-input="'text'" v-model="responsibles.name"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Função:</label>
                                        <input-component :type-input="'text'" v-model="responsibles.function"/>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Email:</label>
                                        <input-component :type-input="'text'" v-model="responsibles.email"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Telefone:</label>
                                        <input-component v-mask="['(##) ####-####', '(##) #####-####']"
                                                         :type-input="'text'"
                                                         v-model="responsibles.phone"/>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <row-component class="mt-5">
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one style="width: 100%" :itens="buttonOne" @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

            </div>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import InputTextArea from '@/components/forms/InputTextArea';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import {mask} from 'vue-the-mask'
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.loadSpot()
        this.$store.commit('PRELOADER', true)
    },

    props: {
        id: {required: true}
    },

    name: 'SpotUpdate',
    components: {
        PainelComponent,
        ModalComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        InputTextArea,
        ButtonOne,
        ButtonAdd,
    },

    directives: {mask},

    data() {
        return {
            buttonAdd: {
                route: '/spot/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonTwo: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar'
            },

            buttonThree: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar Observação',
                tooltip: 'Adicionar Observação'
            },

            buttonFour: {
                icon: 'plus',
                type: 'button',
                name: '',
                tooltip: 'Adicionar'
            },

            buttonFive: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                company_id: '',
                name: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                obsPrivate: [],
                equipments: [],
                passwordswifi: [],
                responsibles: [],
                openingHours: []
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            formDateHour: {
                spot_id: this.id,
                name: '',
                days_week: [],
                players: [],
                type: 2,
                hour: this.timeCurrent(),
                date_close_check: 1,
                date_close: this.startDateClose()
            },

            formDateHourId: {
                spot_id: this.id,
                id: ''
            },

            spot_player: [],

            edit_hour: '',
            modal_add_hour: false,
            visible: false,
            message: '',

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadSpot() {
            this.$store.dispatch('updateNamePage', 'Localização')
            this.loadSpotsPlayers()
            this.$store.dispatch('loadSpot', this.id).then((r) => {
                this.formDate = r;
                this.formDateObs.obs = ''
                this.formDateObs.id = r.id
                this.formDateHour.spot_id = r.id
                this.formDateHourId.spot_id = r.id
                this.checkACL()
                this.nullInputArray()
                this.$store.dispatch('loadSelectCompanyRestrict')
                this.$store.dispatch('loadSelectState')
                this.$store.dispatch('loadSelectCity', this.formDate.state)
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        routeAdd() {
            this.$router.push({name: 'spot-create'})
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('spot-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('spot-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateSpot', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        timeCurrent() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours();
            let currentMinutes = currentTime.getMinutes();
            let currentSegunds = currentTime.getSeconds();

            if (currentHours <= 9)
                currentHours = "0" + currentHours;

            if (currentMinutes <= 9)
                currentMinutes = "0" + currentMinutes

            if (currentSegunds <= 9)
                currentSegunds = "0" + currentSegunds

            return `${currentHours}:${currentMinutes}:${currentSegunds}`
        },

        startDateClose() {
            let date = new Date()
            let day = date.getDate() + 1
            let month = date.getMonth() + 1
            let year = date.getFullYear()

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        clearDaysWeek() {
            this.formDateHour.days_week = ''
        },

        submitObs() {
            this.$store.dispatch('storeSpotObs', this.formDateObs).then(() => {
                this.loadSpot()
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        submitHour() {
            this.$store.dispatch('storeSpotOpeningHour', this.formDateHour).then(() => {
                this.openModalHour()
                this.loadSpot()
            }).catch((r) => {
                this.errors = r.response.data.errors;
                this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
            })
        },

        updateSpotHour() {
            this.$store.dispatch('updateSpotHour', this.formDateHour)
                .then(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    this.openModalHour()
                    this.loadSpot()
                    this.edit_hour = false
                })
                .catch((r) => {
                    this.errors = r.response.data.errors;
                    this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                })
        },

        destroySpotHour() {
            this.visible = !this.visible
            this.$store.dispatch('destroySpotHour', this.formDateHourId)
                .then(() => {
                    this.loadSpot()
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadSpot()
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        loadSelectCity(id) {
            this.$store.dispatch('loadSelectCity', id)
        },

        loadSpotsPlayers() {
            this.$store.dispatch('loadSpotsPlayers', this.id)
                .then((r) => {
                    this.spot_player = r
                })
        },

        openModalHour() {
            this.modal_add_hour = !this.modal_add_hour
            if (this.modal_add_hour != false) {
                this.formDateHour.name = ''
                this.formDateHour.days_week = []
                this.formDateHour.players = []
                this.formDateHour.type = 2
                this.formDateHour.date_close_check = 1
                this.formDateHour.date_close = ''
            }
        },

        closeModalHour() {
            this.modal_add_hour = !this.modal_add_hour
            this.edit_hour = false
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja
            realmente
            deletar ${data.name}`
            this.formDateHourId.id = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        showModalHour(id) {
            this.edit_hour = true
            this.formDateHourId.id = id
            this.$store.dispatch('loadSpotHour', this.formDateHourId).then((r) => {
                this.openModalHour()
                this.formDateHour = r
                this.formDateHour.spot_id = this.id
                this.formDateHour.days_week = r.days_weeks
            })
        },

        resetFormAddresse() {
            this.formDate.district = ''
            this.formDate.address = ''
            this.formDate.complement = ''
        },

        searchApiCEP() {
            this.resetFormAddresse()
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dataCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dataCEP)
                .then((r) => {
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.complement = r.data.complemento
                }).catch(() => {
            })
        },

        nullInputArray() {
            if (this.formDate.equipments.length == 0)
                this.addEquipments()
            if (this.formDate.passwordswifi.length == 0)
                this.addWifi()
            if (this.formDate.responsibles.length == 0)
                this.addResponsibles()
        },

        addEquipments() {
            this.formDate.equipments.push({
                name: ''
            })
        },

        addWifi() {
            this.formDate.passwordswifi.push({
                name: '',
                password: ''
            })
        },

        addResponsibles() {
            this.formDate.responsibles.push({
                name: '',
                function: '',
                email: '',
                phone: ''
            })
        },

        removeEquipments(index) {
            this.formDate.equipments.splice(index, 1)
        },

        removeWifi(index) {
            this.formDate.passwordswifi.splice(index, 1)
        },

        removeResponsibles(index) {
            this.formDate.responsibles.splice(index, 1)
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        equipmentsAdd() {
            return this.formDate.equipments
        },

        wifiAdd() {
            return this.formDate.passwordswifi
        },

        responsiblesAdd() {
            return this.formDate.responsibles
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectCityState() {
            return this.$store.state.var.selectCityState
        },

        selectCity() {
            return this.$store.state.var.selectCity
        },

        selectSpotPoweOn() {
            return this.$store.state.var.selectSpotPoweOn
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}

</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.modal-add-opening-hour {
    justify-content: center;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 100;
    flex-direction: column;
    align-items: center;
}

.div-close-modal-add-hour {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1% 2%;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-add-opening-hour > .container-clone-div {
    min-width: 60%;
}

.row-user-obs span {
    margin: 0 1rem;
    display: flex;
    align-items: center;
}

.row-user-obs span img {
    margin: 0 0.6rem;
}

.add-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

@media (max-width: 1366px) {
    .tabs span{
        font-size: 14px;
    }
}

@media (max-width: 1280px) {
    .tabs > input[type="radio"] + label {
        font-size: 12px;
    }

    .container-clone-div > button.edit-button {
        right: 5%;
    }
}

@media (max-width: 500px) {
    .modal-add-opening-hour > .container-clone-div {
        width: 98%;
    }

    .container-clone-div > button.edit-button {
        right: 15%;
    }

    .myy-carousel {
        margin-top: 2rem;
        margin-bottom: 0;
    }
}
</style>

