import AdminComponent from '@/components/dashboard/AdminComponent'
import ClockAppSearch from '@/views/search/ClockAppSearch'
import ClockAppCreate from '@/views/create/ClockAppCreate'
import ClockAppUpdate from '@/views/update/ClockAppUpdate'
import ClockAppShow from '@/views/show/ClockAppShow'

export default {
    path: '/clock-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'clock-app-search', component: ClockAppSearch },
        { path: 'create', name: 'clock-app-create', component: ClockAppCreate },
        { path: 'edit/:id', name: 'clock-app-edit', component: ClockAppUpdate, props: true },
        { path: 'show/:id', name: 'clock-app-show', component: ClockAppShow, props: true },
    ]
}