import AdminComponent from '@/components/dashboard/AdminComponent'
import UserLocationSearch from "@/views/search/UserLocationSearch";
import UserLocationCreate from "@/views/create/UserLocationCreate";
import UserLocationUpdate from "@/views/update/UserLocationUpdate";
import UserLocationShow from "@/views/show/UserLocationShow";
import UserLocationUpdatePhoto from "@/views/update/UserLocationUpdatePhoto";

export default {
    path: '/user-location',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'user-location-search', component: UserLocationSearch},
        {path: 'create', name: 'user-location-create', component: UserLocationCreate},
        {path: 'edit/:id', name: 'user-location-edit', component: UserLocationUpdate, props: true},
        {path: 'show/:id', name: 'user-location-show', component: UserLocationShow, props: true},
        {path: 'photo/:id', name: 'user-location-photo-edit', component: UserLocationUpdatePhoto, props: true},
    ]
}