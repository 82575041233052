<template>
    <ModalComponent @submit-logout="destroyContentFolder" @close-modal="closeModal" :visible="visible"
                    :message="message"/>
    <div v-if="modalInfo" class="window-modal">
        <ContentShow :id="idInfo" @close-content-show="modalInfo = !modalInfo"/>
    </div>

    <div class="modal-create-folder-background" v-if="modal_folder">
        <div class="modal-create-folder">
            <painel-component :title="criar ? 'Adicionar' : 'Editar'">
                <ErrorComponent :errors="errors" v-if="showError"/>
                <div class="modal-folder-close" @click="modal_folder = !modal_folder">X</div>
                <form>
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.name }">
                            <label>Nome:</label>
                            <input-component :type-input="'text'" v-model="formDateCreate.name"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.company_id }">
                            <label>Empresa:</label>
                            <Multiselect :disabled="criar != true" v-model="formDateCreate.company_id"
                                         :searchable="true"
                                         label="name" :noResultsText="this.$store.state.var.selectNotSearch"
                                         :options="selectCompanyRestrict">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon"/>
                                        {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon"/>
                                    {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4'">
                            <button-one :disabled="sendForm" v-if="criar" :itens="buttonOne"
                                        @click="storeContentFolder"/>
                            <button-one :disabled="sendForm" v-else :itens="buttonTwo" @click="updateContentFolder"/>
                        </row-col-component>
                    </row-component>
                </form>
            </painel-component>
        </div>
    </div>

    <painel-component>
        <div class="header-file-explore">
            <div v-if="buttonCheck.create" @click="openModal" class="block-icon-file">
                <img src="@/assets/icones/folder_add.svg">
                <span class="block-icon-file-text">Nova Pasta</span>
            </div>

            <div class="input-search-container">
                <div class="input-search">
                    <input type="text" @keyup.enter="submit" v-model="formDate.name">
                    <img class="input-icon-search" src="@/assets/icones/search.svg" alt="" @click="submit">
                </div>
                <div class="input-search">
                    <Multiselect @clear="loadContentsFolders(1)" @select="submit" v-model="formDate.company_id"
                                 :searchable="true" label="name" :options="selectCompanyRestrict">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""/>
                                {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""/>
                            {{ option.name }}
                        </template>
                    </Multiselect>
                </div>
            </div>
        </div>

        <row-component class="mt-5 margin-top-file">
            <row-col-component :class-row-col="'col-xxl-1 col-xl-2 col-lg-1 col-md-1 col-sm-1 col-4 margin-x-col'"
                               v-for="(folder, index) in content_folder.data" :key="index">
                <div class="file-content" :data-index="folder.totalFiles">
                    <div class="file-link-div" @click="fileRouter(folder.id)" :tooltip="folder.name">
                        <img src="@/assets/icones/folder_closed.svg">
                        <p>{{ folder.name }}</p>
                    </div>

                    <span class="tooltiptext">
                        <button v-if="buttonCheck.edit" @click="loadContentFolder(folder.id)" tooltip="Editar">
                            <img src="@/assets/icones/create.svg">
                        </button>

                        <button v-if="buttonCheck.delete" @click="openModalFolder(folder)" tooltip="Excluir">
                            <img src="@/assets/icones/trash.svg">
                        </button>

                        <button v-if="buttonCheck.show" tooltip="Informação">
                            <img @click="folderShow(folder.id)" src="@/assets/icones/info_circle.svg">
                        </button>

                        <button @click="fileRouter(folder.id)" tooltip="Visualizar">
                            <img src="@/assets/icones/eye.svg">
                        </button>
                    </span>
                </div>
            </row-col-component>
        </row-component>
        <button-pagination :pagination="content_folder" :offset="3" @paginate="loadContentsFolders"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent'
import ModalComponent from '@/components/modal/ModalComponent'
import ErrorComponent from '@/components/alerts/ErrorComponent'
import RowComponent from '@/components/row/RowComponent'
import RowColComponent from '@/components/row/RowColComponent'
import InputComponent from '@/components/forms/InputComponent'
import Multiselect from '@vueform/multiselect/src/Multiselect'
import ButtonOne from '@/components/buttons/ButtonOne'
import ButtonPagination from '@/components/buttons/ButtonPagination'
import ContentShow from '@/views/show/ContentShow'
import {ACTION_MESSAGES} from '@/configs/messages'

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Conteúdo')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.loadContentsFolders(1)
        this.checkACL()
    },

    name: 'ContentSearch',
    components: {
        PainelComponent,
        ModalComponent,
        ErrorComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ContentShow,
        ButtonPagination
    },

    data() {
        return {
            buttonOne: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar',
            },

            buttonTwo: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar',
            },

            formDate: {
                name: '',
                company_id: ''
            },

            formDateCreate: {
                name: '',
                company_id: ''
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            idInfo: '',
            modalInfo: false,

            modal_folder: false,
            visible: false,
            criar: true,
            message: '',

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            this.loadContentsFolders(1)
            this.searchSubmit = true;
        },

        fileRouter(id) {
            this.$router.push({name: 'content-file-search', params: {id: id}});
        },

        folderShow(id) {
            this.idInfo = id
            this.modalInfo = !this.modalInfo
        },

        loadContentFolder(id) {
            this.$store.dispatch('loadContentFolder', id)
                .then(r => {
                    this.criar = false
                    this.formDateCreate = r
                    this.modal_folder = !this.modal_folder
                })
        },

        loadContentsFolders(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadContentsFolders', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        storeContentFolder() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeContentFolder', this.formDateCreate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.openModal()
                        this.loadContentsFolders(1)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        destroyContentFolder() {
            this.visible = !this.visible
            this.$store.dispatch('destroyContentFolder', this.idModal)
                .then(() => {
                    this.loadContentsFolders(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch((r) => {
                    this.loadContentsFolders(1)
                    if (r.response.data.errors) {
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            message: r.response.data.errors[0][0]
                        })
                    }
                })
        },

        updateContentFolder() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateContentFolder', this.formDateCreate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        openModal() {
            this.criar = true
            this.modal_folder = !this.modal_folder
            this.formDateCreate.name = ''
            this.formDateCreate.company_id = ''
        },

        openModalFolder(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 8)
                .then((r) => {
                    if (r.data.indexOf('content-folder-search') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('content-folder-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('content-folder-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('content-folder-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('content-folder-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        content_folder() {
            return this.$store.state.content_folder.items
        },

        params() {
            return {
                page: this.content_folder.current_page,
                name: this.formDate.name,
                company_id: this.formDate.company_id
            }
        },

        me() {
            return this.$store.state.auth.me
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        }

    }
}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.container-card {
    border: 1px solid #ccc;
}

.header-file-explore {
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

.block-icon-file {
    width: 100px;
    height: 100%;
    display: flex;
    cursor: pointer;
    position: unset;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.block-icon-file:hover {
    border-top-left-radius: 10px;
    background-color: rgb(204, 204, 204, 0.4);
}

.input-search-container {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.input-search {
    height: 42px;
    width: 300px;
    display: flex;
    position: relative;
    align-items: center;
}

.input-search > input {
    height: 42px;
    width: 300px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
}

.input-icon-search {
    right: 10px;
    cursor: pointer;
    position: absolute;
}

.file-content {
    width: 115px;
    height: 145px;
    display: flex;
    margin: 1rem 0;
    font-size: 14px;
    position: relative;
    text-align: center;
    align-items: center;
    word-wrap: break-word;
    flex-direction: column;
    background-color: #fbfbfb;
}

.file-link-div {
    width: 100%;
    height: 100%;
}

.file-link-div:hover {
    cursor: pointer;
    background-color: rgb(192, 192, 192, 0.4);
}

.file-link-div > img {
    height: 80px;
}

.file-link-div p {
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.file-content .tooltiptext {
    top: 100%;
    left: 50%;
    z-index: 1;
    width: 100%;
    color: #fff;
    padding: 5px 0;
    margin-left: -50%;
    text-align: center;
    position: absolute;
}

.tooltiptext {
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
}

.tooltiptext > button {
    border: 0;
    width: 100%;
    height: auto;
}

.modal-create-folder-background {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.5);
}

.modal-create-folder {
    width: 40%;
    height: 55%;
    position: relative;
}

.modal-folder-close {
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 10px 20px;
    position: absolute;
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0, 0.7);
}

.window-modal > div {
    width: 90%;
    position: relative;
    margin: 14px auto;
}

.file-content::before {
    content: attr(data-index);
    top: 0%;
    left: 0;
    z-index: 1;
    width: 18px;
    height: 18px;
    display: grid;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    place-items: center;
    background-color: #d67c1c;
}
</style>

<style scoped>

@media (max-width: 1366px) {
    .input-search-container {
        width: 60%;
    }
}

@media (max-width: 1280px) {
    .modal-create-folder {
        width: 100%;
    }

    .window-modal > div {
        width: 100%;
        margin: 3% 2% 5% 2%;
    }

    .header-file-explore {
        height: 170px;
        flex-wrap: wrap;
        padding: 0 15px;
    }

    .block-icon-file {
        left: 0;
        bottom: 0;
        width: 50px;
        height: auto;
        margin: 0 15px;
        position: absolute;
    }

    .block-icon-file img {
        width: 100%;
        height: auto;
    }

    .block-icon-file-text {
        display: none;
    }

    .input-search-container {
        height: 50%;
        width: 100%;
        flex-wrap: wrap;
    }

    .input-search input,
    .input-search {
        margin: 10px 0;
        width: 100%;
    }

    .margin-top-file {
        margin-top: 10rem !important;
    }

    .margin-x-col {
        margin: 0;
    }
}

@media (max-width: 1024px) {
    .input-search-container {
        width: 100%;
    }

    .margin-top-file {
        margin-top: 3rem;
    }

    .margin-x-col {
        margin-right: 5rem;
    }
}


@media (max-width: 991px) {


    .modal-create-folder {
        width: 100%;
    }

    .window-modal > div {
        width: 100%;
        margin: 3% 2% 5% 2%;
    }

}

@media (max-width: 500px) {
    .header-file-explore {
        height: 170px;
        flex-wrap: wrap;
        padding: 0 15px;
    }

    .block-icon-file {
        left: 0;
        bottom: 0;
        width: 50px;
        height: auto;
        margin: 0 15px;
        position: absolute;
    }

    .block-icon-file img {
        width: 100%;
        height: auto;
    }

    .block-icon-file-text {
        display: none;
    }

    .input-search-container {
        height: 50%;
        width: 100%;
        flex-wrap: wrap;
    }

    .input-search input,
    .input-search {
        margin: 10px 0;
        width: 100%;
    }

    .file-content {
        width: 100px;
    }

    .margin-top-file {
        margin-top: 10rem;
    }

    .margin-x-col {
        margin: 0;
    }
}
</style>