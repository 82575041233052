import actions from '@/store/modules/player-add-campaign/actions'
import getters from '@/store/modules/player-add-campaign/getters'
import mutations from '@/store/modules/player-add-campaign/mutations'
import state from '@/store/modules/player-add-campaign/state'

export default {
    actions,
    getters,
    mutations,
    state
}