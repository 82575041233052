import AdminComponent from '@/components/dashboard/AdminComponent';
import ObituaryAppSearch from "@/views/search/ObituaryAppSearch";
import ObituaryAppCreate from "@/views/create/ObituaryAppCreate";
import ObituaryAppUpdate from "@/views/update/ObituaryAppUpdate";
import ObituaryAppShow from "@/views/show/ObituaryAppShow";

export default {
    path: '/obituary-app',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'obituary-app-search', component: ObituaryAppSearch},
        {path: 'create', name: 'obituary-app-create', component: ObituaryAppCreate},
        {path: 'edit/:id', name: 'obituary-app-edit', component: ObituaryAppUpdate, props: true},
        {path: 'show/:id', name: 'obituary-app-show', component: ObituaryAppShow, props: true},
    ]
}