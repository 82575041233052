<template>
    <painel-component class="pt-1">
        <row-component class="justify-content-end">
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Empresa</label>
                <Multiselect @clear="loadInfosHomes" @select="loadHomeFilter" v-model="company" :searchable="true"
                    label="name" :options="selectCompanyRestrict">
                    <template v-slot:singlelabel="{ value }">
                        <div class="multiselect-single-label">
                            <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                    </template>
                </Multiselect>
            </row-col-component>
        </row-component>

        <row-component class="mt-5">
            <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/play_button.svg">
                    Total de {{ home.totalPlayerHired }} players contratados
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/play_button.svg">
                    Total de {{ home.totalPlayer }} players instalados
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/display.svg">
                    Total de {{ home.totalCampaing }} campanhas criadas
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-6 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/folder_closed.svg">
                    Total de {{ home.totalFiles }} conteúdos enviados
                </div>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <hr class="hr-home">
                <Chart :options="presetsTwo" />
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <hr class="hr-home">
                <Chart :options="presets" />
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <hr class="hr-home">
                <Chart :options="presetsThree" />
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent'
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import Multiselect from '@vueform/multiselect/src/Multiselect';

import { Chart } from 'highcharts-vue'
import highchart from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
highcharts3d(highchart);

import { ACTION_MESSAGES } from '@/configs/messages';
import { CONFIG_CHART } from '@/configs/constants'

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Home')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.loadInfosHomes()
    },

    name: 'HomeView',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        Multiselect,
        Chart
    },

    data() {
        return {

            company: '',

            buttonOne: {
                icon: 'search',
                tooltip: 'Buscar',
                type: 'button'
            },

            presets: {
                chart: CONFIG_CHART.chart,
                accessibility: CONFIG_CHART.accessibility,
                plotOptions: CONFIG_CHART.plotOptions,
                credits: CONFIG_CHART.credits,
                title: { text: 'Status Player' },
                series: []
            },

            presetsTwo: {
                chart: CONFIG_CHART.chart,
                accessibility: CONFIG_CHART.accessibility,
                plotOptions: CONFIG_CHART.plotOptions,
                credits: CONFIG_CHART.credits,
                title: { text: 'Segmento' },
                series: []
            },

            presetsThree: {
                chart: CONFIG_CHART.chart,
                accessibility: CONFIG_CHART.accessibility,
                plotOptions: CONFIG_CHART.plotOptions,
                credits: CONFIG_CHART.credits,
                title: { text: 'Versão do Player' },
                series: []
            }

        }
    },

    methods: {
        loadInfosHomes() {
            this.presets.series = []
            this.presetsTwo.series = []
            this.presetsThree.series = []
            this.$store.dispatch('loadInfosHomes')
                .then((r) => {
                    this.graphicOnileOff(r.graphic_onile_off)
                    this.graphicPlayerSegment(r.graphic_player_segment)
                    this.graphicPlayerVesrsionApp(r.graphic_player_vesrsion_app)
                }).catch((error) => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                    if (error.response.status === 401 || error.response.status === 429) {
                        setTimeout(() => { location.reload() }, 500)
                        this.$router.push({ name: 'login' })
                    }
                })
        },

        loadHomeFilter() {
            if (this.company != 0) {
                this.$store.dispatch('loadHomeFilter', this.company)
                    .then((r) => {
                        this.presets.series = []
                        this.presetsTwo.series = []
                        this.presetsThree.series = []

                        this.graphicOnileOff(r.graphic_onile_off)
                        this.graphicPlayerSegment(r.graphic_player_segment)
                        this.graphicPlayerVesrsionApp(r.graphic_player_vesrsion_app)
                    }).catch((error) => {
                        this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                        if (error.response.status === 401 || error.response.status === 429) {
                            setTimeout(() => { location.reload() }, 500)
                            this.$router.push({ name: 'login' })
                        }
                    })
            } else {
                this.loadInfosHomes()
            }

        },

        graphicOnileOff(dados) {
            this.presets.series.push({
                type: 'pie',
                name: 'Status Player',
                colors: ['#90ed7d', '#434348'],
                data: dados
            })
        },

        graphicPlayerSegment(dados) {
            this.presetsTwo.series.push({
                type: 'pie',
                name: 'Segmento',
                colors: ['#7cb5ec', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1', '#90ed7d', '#90ed7d', '#f7a35c', '#8085e9'],
                data: dados
            })
        },

        graphicPlayerVesrsionApp(dados) {
            this.presetsThree.series.push({
                type: 'pie',
                name: 'Versão do Player',
                colors: ['#7cb5ec', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1', '#90ed7d', '#90ed7d', '#f7a35c', '#8085e9'],
                data: dados
            })
        },

    },

    beforeMount() {
        setTimeout(() => {
            this.selectCompanyRestrict.push({
                "value": 0,
                "name": 'Todas as Empresas',
                "icon": 'https://methoduz.s3.amazonaws.com/system/avatar.png'
            })
        }, 1500)
    },

    computed: {
        home() {
            return this.$store.state.home.items
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        }

    },

}
</script>

<style src="@/assets/css/table.css"></style>

<style scoped>
span {
    text-align: center;
    margin: 20px 0;
}

.highcharts-credits {
    color: red
}

hr.hr-home {
    opacity: unset;
    border: 2px solid #f58634;
    margin-top: 0;
    margin-bottom: 15px;
}
</style>