import axios from "axios";
import { URL_BASE } from "@/configs/constants";
const RESOURCE = 'player-add-campaing';

export default {

    loadPlayersAddCampaigns(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_PLAYER_ADD_CAMPAIGN', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storePlayerAddCampaign(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}player-add-campaing`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyPlayerAddCampaign(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}player-add-campaing`, { params })
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    }

}