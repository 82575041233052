import AdminComponent from '@/components/dashboard/AdminComponent'
import NewsAppSearch from '@/views/search/NewsAppSearch'
import NewsAppCreate from '@/views/create/NewsAppCreate'
import NewsAppUpdate from '@/views/update/NewsAppUpdate'
import NewsAppShow from '@/views/show/NewsAppShow'

export default {
    path: '/news-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'news-app-search', component: NewsAppSearch },
        { path: 'create', name: 'news-app-create', component: NewsAppCreate },
        { path: 'edit/:id', name: 'news-app-edit', component: NewsAppUpdate, props: true },
        { path: 'show/:id', name: 'news-app-show', component: NewsAppShow, props: true },
    ]
}