import AdminComponent from '@/components/dashboard/AdminComponent'
import YoutubeAppSearch from '@/views/search/YoutubeAppSearch'
import YoutubeAppCreate from '@/views/create/YoutubeAppCreate'
import YoutubeAppUpdate from '@/views/update/YoutubeAppUpdate'
import YoutubeAppShow from '@/views/show/YoutubeAppShow'

export default {
    path: '/youtube-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'youtube-app-search', component: YoutubeAppSearch },
        { path: 'create', name: 'youtube-app-create', component: YoutubeAppCreate },
        { path: 'edit/:id', name: 'youtube-app-edit', component: YoutubeAppUpdate, props: true },
        { path: 'show/:id', name: 'youtube-app-show', component: YoutubeAppShow, props: true },
    ]
}