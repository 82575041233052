<template>
    <painel-component :title="'Editar Item'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome da Item:</label>
                    <input-component v-model="formDate.name" :type-input="'text'" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.price }">
                    <label>Preço:</label>
                    <input-component v-money="money" v-model="formDate.price" :type-input="'text'" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add @click="$emit('close-item-update')" style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';
import { MONEY } from '@/configs/constants'
import { VMoney } from 'v-money'

export default {
    created() {
        this.loadMenuTableItem()
    },

    props: {
        params: { 'type': Object }
    },

    emits: ['close-item-update', 'close-item-submit'],

    name: 'MenuTableItemUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonOne,
        ButtonAdd,
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            buttonAdd: {
                route: '',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonAddItem: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar Item'
            },

            formDate: {
                id: '',
                name: '',
                price: '',
                idapp: ''
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadMenuTableItem() {
            this.$store.dispatch('loadMenuTableItem', this.params)
                .then((r) => { this.formDate = r })
                .catch(() => { this.$store.commit('addToast', ACTION_MESSAGES.errorDefault) })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateMenuTableItem', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                        this.$emit('close-item-submit', 1)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

    },

}

</script>

<style scoped>
.content-item {
    width: 100%;
    height: 500px;
    padding: 15px 25px;
    overflow: auto;
    overflow-x: hidden;
}
</style>