<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome do APP:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.url }">
                    <label>URL:</label>
                    <input-component :type-input="'text'" v-model="formDate.url" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.time }">
                    <label>Tempo exibição em segundos:</label>
                    <input-component :type-input="'number'" v-model="formDate.time" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.company_id }">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                        :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'App Web')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
    },

    name: 'WebAppCreate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
    },

    data() {
        return {
            buttonAdd: {
                route: '/web-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                type: 'button',
                icon: 'plus',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                name: '',
                url: '',
                time: '',
                status: '',
                company_id: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeWebApp', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$router.push({ name: 'web-app-edit', params: { id: r.data } })
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 16)
                .then((r) => {
                    if (r.data.indexOf('app-web-url-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>