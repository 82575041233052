<template>
    <div class="toast">
        <div class="sidebar" :class="toast.type"></div>
        <div class="content">
            <div class="header">
                <h3>{{ toast.title }}</h3>
                <button @click="dismissToast">X</button>
            </div>
            <div class="text">
                <p>{{ toast.message }}</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MyToast',
    props: {
        toast: {required: true, type: Object},
    },
    created() {
        setTimeout(() => {
            this.dismissToast();
        }, `${this.toast.time}`);
    },
    methods: {
        dismissToast() {
            this.$store.commit('clearToast', this.toast.title);
        }
    }
}
</script>

<style src="@/assets/css/toasts.css">

</style>