export default {
    icon: 'briefcase',
    name: 'Empresa',
    route: '/company/search',
    pagePermissions: [
        "company-search",
        "company-create",
        "company-edit",
    ],

    pageLinksNavCheck: [
        "company-search",
        "company-create",
        "company-edit",
    ]
}