import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'spot';
export default {

    loadSpots(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_SPOTS', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeSpot(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeSpotObs(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}spot-add-obs`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeSpotOpeningHour(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}spot-add-hour`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadSpot(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    loadSpotHour(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}spot-get-hour/${formData.spot_id}/${formData.id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadSpotsPlayers(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}spot-player/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateSpot(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    updateSpotHour(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}spot-edit-hour`, formData)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    destroySpotHour(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}spot-delete-hour/${formData.spot_id}/${formData.id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}