<template>
    <painel-component :title="'Adicionar Campanha'">
        <ModalComponent @submit-logout="destroyPlayerAddCampaign" @close-modal="closeModal" :visible="visible"
                        :message="message"/>
        <ErrorComponent :errors="errors" v-if="showError"/>
        <div class="btn-close" @click="$emit('close-player-add-campaign')"></div>

        <div class="container-tabs mt-3">
            <div class="card tabs">
                <input id="tab-1" type="radio" :checked="change_tab" class="tab tab-selector" name="tab"/>
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/plus.svg" alt=""></i>
                    <span>Adicionar</span>
                </label>

                <input id="tab-2" type="radio" :checked="change_tab_two" class="tab tab-selector" name="tab"/>
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/play_button.svg" alt=""></i>
                    <span>Campanhas Adicionados</span>
                </label>

                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formDate.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                                    <label>Empresa:</label>
                                    <Multiselect label="name" v-model="formDate.company_id"
                                                 :options="selectCompanyRestrict"
                                                 :searchable="true"
                                                 :noResultsText="this.$store.state.var.selectNotSearch">
                                        <template v-slot:singlelabel="{ value }">
                                            <div class="multiselect-single-label">
                                                <img class="character-label-icon" :src="value.icon" alt="">
                                                {{ value.name }}
                                            </div>
                                        </template>

                                        <template v-slot:option="{ option }">
                                            <img class="character-option-icon" :src="option.icon" alt="">
                                            {{ option.name }}
                                        </template>
                                    </Multiselect>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                                    <button-one :itens="buttonOne" @click="submit"/>
                                </row-col-component>
                            </row-component>
                        </form>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2'"
                                    v-for="(data, index) in campaign_list.data" :key="index">
                                <div class="file-content"
                                     :class="`file-content-border-${data.type}`">
                                    <input
                                            :id="'thumbs' + data.id"
                                            :value="data.id"
                                            type="checkbox"
                                            name="thumbs"
                                            :class="'thumbsClass' + data.id"
                                            v-model="formDateAdd.campaings">
                                    <label :for="'thumbs' + data.id">
                                        <p>
                                            <img src="@/assets/icones/display.svg" alt=""> {{ data.name }} <br>
                                            <img src="@/assets/icones/briefcase.svg" alt=""> {{ data.company_name }}
                                        </p>
                                    </label>
                                </div>
                            </row-col-component>
                        </row-component>

                        <div class="bottom-buttons">
                            <button-one :itens="buttonTwo" @submit="storePlayerAddCampaign"/>
                            <button-pagination :pagination="campaign_list" :offset="3"
                                               @paginate="loadPlayersAddCampaigns"/>
                        </div>
                    </div>

                    <div class="item" id="content-2">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="paramsFilter.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Empresa:</label>
                                <input-component :type-input="'text'" v-model="paramsFilter.company_name"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component v-if="campaign_add.length == 0"
                                               :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <div class="alert alert-secondary icon-message" role="alert">
                                    Não há nada cadastrado
                                </div>
                            </row-col-component>

                            <row-col-component v-else
                                               :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2'"
                                               v-for="(data, index) in filteredList" :key="index">
                                <div class="file-content"
                                     :class="`file-content-border-${data.type}`">
                                    <p>
                                        <img src="@/assets/icones/display.svg" alt=""> {{ data.name }} <br>
                                        <img src="@/assets/icones/briefcase.svg" alt=""> {{ data.company_name }}
                                    </p>
                                    <button-delete @click="openModal(data)"/>
                                </div>
                            </row-col-component>
                        </row-component>
                    </div>

                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination'

import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.loadPlayersAddCampaigns(1)
        this.$store.dispatch('loadSelectSpot')
        this.$store.dispatch('loadSelectCompanyRestrict')
    },

    props: {
        id: {required: true}
    },

    name: 'PlayerAddCampaign',
    components: {
        PainelComponent,
        ModalComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonDelete,
        ButtonPagination
    },

    data() {
        return {
            buttonAdd: {
                route: '/spot/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'search',
                type: 'button',
                tooltip: 'Buscar'
            },

            buttonTwo: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                player_id: this.id,
                name: '',
                company_id: ''
            },


            search: [],
            paramsFilter: {
                name: '',
                company_name: ''
            },

            change_tab: true,
            change_tab_two: false,

            formDateAdd: {
                player_id: this.id,
                campaings: []
            },

            selectItems: [],

            formDateDelete: {
                player_id: this.id,
                campaign_id: '',
            },

            campaign_list: {},
            campaign_add: [],
            type_disabled: '',

            visible: false,
            message: '',

            errors: [],
            showError: false,
            sendForm: false,
            searchSubmit: false
        }
    },

    methods: {

        submit() {
            this.loadPlayersAddCampaigns(1)
            this.searchSubmit = true;
        },

        checkedTabOne() {
            let data = this.campaign_list.data
            if (data.length == 0) {
                this.change_tab = !this.change_tab
                this.change_tab_two = !this.change_tab_two
            }
        },

        loadPlayersAddCampaigns(page) {
            this.showLoder = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadPlayersAddCampaigns', {...this.params, page})
                    .then((r) => {
                        this.type_disabled = r.disbaled
                        this.campaign_list = r.result
                        this.campaign_add = r.campaignAdd
                        this.search = r.campaignAdd
                        this.checkedTabOne()
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.result.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.showLoder = true
                    this.searchSubmit = false
                });
            }
        },

        storePlayerAddCampaign() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storePlayerAddCampaign', this.formDateAdd)
                    .then(() => {
                        this.loadPlayersAddCampaigns(1)
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.change_tab = !this.change_tab
                        this.change_tab_two = !this.change_tab_two
                    })
                    .catch((r) => {
                        this.loadPlayersAddCampaigns(1)
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name}, deseja realmente deletar ${data.name} !`
            this.formDateDelete.campaign_id = data.campaign_id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyPlayerAddCampaign() {
            this.visible = !this.visible
            this.$store.dispatch('destroyPlayerAddCampaign', {...this.formDateDelete})
                .then(() => {
                    this.loadPlayersAddCampaigns(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadPlayersAddCampaigns(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                })
        },

    },

    computed: {

        me() {
            return this.$store.state.auth.me
        },

        params() {
            return {
                page: this.campaign_list.current_page,
                player_id: this.formDate.player_id,
                name: this.formDate.name,
                company_id: this.formDate.company_id
            }
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectSpot() {
            return this.$store.state.var.selectSpot
        },

        filteredList() {
            return this.search.filter((item) => {
                return item.name.toLowerCase().includes(this.paramsFilter.name.toLowerCase()) &&
                    item.company_name.toLowerCase().includes(this.paramsFilter.company_name.toLowerCase())
            })
        }

    }

}

</script>

<style src="@/assets/css/tabs.css"></style>
<style scoped>
.content {
    height: 65vh;
}

.tabs > input[type="radio"] + label {
    width: 50%;
}

#tab-2:checked ~ .glider {
    left: 50%;
}

.glider {
    width: 50%;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.file-content {
    width: 100%;
    height: 80px;
    display: flex;
    color: black;
    padding: 15px 10px;
    position: relative;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.file-content-border-1 {
    border-top: 2px solid #d67c1c;
}

.file-content-border-2 {
    border-top: 2px solid var(--green-color-default);
}

.file-content > img {
    height: auto;
    width: 50px;
    margin: 0 10px 0 5px;
}

.file-content p {
    font-size: 14px;
}

input[type="checkbox"][id^="thumbs"] {
    display: none;
}

.file-content label {
    padding: 0 10px;
    cursor: pointer;
    width: 100%;
}

.file-content label:before {
    background-color: grey;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 4%;
    right: 1%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 22px;
    z-index: 1;
    transition-duration: 0.4s;
    content: "✓";
    transform: scale(1);
}

.file-content input:checked + label:before {
    content: "✓";
    background-color: #2AB934;
    border: 1px solid #2AB934;
    transform: scale(1);
}

.file-content input[type="checkbox"] + label::after {
    display: none;
}

.container-paginationGroup {
    margin: unset
}

.bottom-buttons {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.action-buttons {
    top: 0;
    right: 50px;
    height: 55px;
    display: none;
    position: absolute;
}

.action-buttons > button {
    border: 0;
    width: 55px;
    padding: 5px;
    height: 55px;
    background: #ebebeb;
}

@media (max-width: 425px) {

    .mt-4 {
        margin: 0 !important;
    }
}

@media (max-width: 375px) {
    .bottom-buttons {
        bottom: unset;
    }
}
</style>

