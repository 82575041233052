import AdminComponent from '@/components/dashboard/AdminComponent'
import NoticeAppSearch from '@/views/search/NoticeAppSearch'
import NoticeAppCreate from '@/views/create/NoticeAppCreate'
import NoticeAppUpdate from '@/views/update/NoticeAppUpdate'
import NoticeAppShow from '@/views/show/NoticeAppShow'

export default {
    path: '/notice-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'notice-app-search', component: NoticeAppSearch },
        { path: 'create', name: 'notice-app-create', component: NoticeAppCreate },
        { path: 'edit/:id', name: 'notice-app-edit', component: NoticeAppUpdate, props: true },
        { path: 'edit/:id', name: 'notice-app-show', component: NoticeAppShow, props: true },
    ]
}