<template>
    <ModalComponent @submit-logout="destroyMenuTableItem" @close-modal="closeModal" :visible="visible"
                    :message="message"/>
    <div v-if="window_bg" class="window-modal">
        <MenuTableItemCreate class="item-create" :id="id" v-if="itemCreate" @close-item-create="closeItemCreate"
                             @close-item-submit="closeItemCreate"/>

        <MenuTableItemUpdate class="item-update" :params="paramsItemUpdate" v-if="itemUpdate"
                             @close-item-update="closeItemUpdate" @close-item-submit="closeItemUpdate"/>
    </div>

    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add class="mx-3" :itens="buttonAdd" @click="buttonAddItem"/>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn"><button-one class="mx-1" :itens="buttonOne" @submit="submit"/></div>
                        <div class="mobi-btn"><button-one class="mx-1" :itens="buttonTwo" @submit="submit"/></div>

                        <div v-if="!btnFilter" style="display: inline;">
                            <button-add class="mx-1" :itens="buttonAdd" @click="buttonAddItem"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>
        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Preço</th>
                    <th scope="col" style="width: 5%">Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in menu_table_item" :key="index">
                    <td data-label="Nome"> {{ data.name }}</td>
                    <td data-label="Preço">R$ {{ data.price }}</td>
                    <td data-label="Ações" v-if="data.id">
                        <button-edit @click="buttonUpdateItem(data.id)" :route="''" :id-params="id"/>
                        <button-delete @click="openModal(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="menu_table_item" :offset="3" @paginate="loadMenusTablesItens"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';

import MenuTableItemCreate from '@/views/create/MenuTableItemCreate';
import MenuTableItemUpdate from '@/views/update/MenuTableItemUpdate';

export default {
    created() {
        this.loadMenusTablesItens(1)
        this.$store.dispatch('updateNamePage', 'Tabela Itens')
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    props: {
        id: {required: true}
    },

    name: 'MenuTableItemSearch',
    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonAdd,
        ButtonOne,
        ButtonEdit,
        ButtonDelete,
        ButtonPagination,
        MenuTableItemCreate,
        MenuTableItemUpdate
    },

    data() {
        return {
            buttonAdd: {
                route: '',
                icon: 'plus',
                tooltip: 'Adicionar',
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            formDate: {
                id: this.id,
                name: '',
            },

            filter: true,
            btnFilter: false,

            idModal: {
                id: this.id,
                item_id: ''
            },

            paramsItemUpdate: {
                id: this.id,
                item_id: ''
            },

            window_bg: false,
            itemCreate: false,
            itemUpdate: false,

            message: '',
            visible: false,
            searchSubmit: false

        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadMenusTablesItens(1)
            this.searchSubmit = true;
        },

        loadMenusTablesItens(page) {
            this.showLoder = true
            if (!this.searchSubmit) {
                this.$store.dispatch('loadMenusTablesItens', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.showLoder = true
                    this.searchSubmit = false
                });
            }
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal.item_id = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyMenuTableItem() {
            this.visible = !this.visible
            this.$store.dispatch('destroyMenuTableItem', this.idModal)
                .then(() => {
                    this.loadMenusTablesItens(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadMenusTablesItens(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        buttonAddItem() {
            this.itemCreate = !this.itemCreate
            this.window_bg = !this.window_bg
        },

        buttonUpdateItem(id) {
            this.paramsItemUpdate.item_id = id
            this.itemUpdate = !this.itemUpdate
            this.window_bg = !this.window_bg
        },


        closeItemCreate(n) {
            this.itemCreate = !this.itemCreate
            this.window_bg = !this.window_bg
            if (n == 1)
                this.loadMenusTablesItens(1)
        },

        closeItemUpdate(n) {
            this.itemUpdate = !this.itemUpdate
            this.window_bg = !this.window_bg
            if (n == 1)
                this.loadMenusTablesItens(1)
        }

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        menu_table_item() {
            return this.$store.state.menu_table_item.items
        },

        params() {
            return {
                page: this.menu_table_item.current_page,
                id: this.formDate.id,
                name: this.formDate.name,
            }
        },

    }

}
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>
.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.window-modal > div.item-create {
    width: 50%;
    height: 68%;
}

.window-modal > div.item-update {
    width: 50%;
    height: 30%;
}

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 1366px) {
    .window-modal > div.item-create {
        width: 65%;
        height: auto;
    }
}

@media (max-width: 1280px){
    .window-modal > div.item-update {
        height: 22%;
    }

    .window-modal > div.item-create {
        width: 65%;
        height: 65%;
    }
}

@media (max-width: 991px) {

    .window-modal > div.item-create {
        width: 95%;
        height: auto;
    }

    .window-modal > div.item-update {
        width: 95%;
        height: auto;
    }

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }
}

@media (max-width: 375px) {
    .window-modal > div.item-update {
        height: 50%;
    }
}
</style>