<template>

    <div class="container-paginationGroup">
        <div class="group-pagination">
            <button class="btn-border-default btn-base btn-regular btn-hover btn-left" v-if="pagesList.current_page > 1"
                @click.prevent="changePage(pagesList.current_page - 1)">
                <i class="icon"><img src="@/assets/icones/arrow_left.svg"></i>
            </button>


            <button type="button" class="btn-border-default btn-base btn-regular btn-group-pag btn-hover"
                :class="[{ 'active': page == pagesList.current_page }]" v-for="(page, index) in pagesNumber"
                :key="index" @click.prevent="changePage(page)">
                {{ page }}
            </button>

            <button v-if="pagesList.current_page < pagesList.last_page"
                @click.prevent="changePage(pagesList.current_page + 1)"
                class="btn-border-default btn-base btn-regular btn-hover btn-right">
                <i class=" icon"><img src="@/assets/icones/arrow_right.svg"></i>
            </button>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ButtonPagination",

    props: {
        pagination: {
            type: Object,
            required: true,
            "default": []
        },
        offset: {
            type: Number,
            default: 4
        }
    },
    computed: {
        pagesNumber() {
            // Verifica se tem itens para paginar, se não tiver retorna o Array vazio
            if (!this.pagination.to) {
                return [];
            }
            // Define a próxima página
            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }
            // Define a última opção páginação
            let to = from + this.offset;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }
            // Cria as opções de paginação
            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }
            // Array montado com as opções de paginação (Número de opções de paginação === offset)
            return pagesArray;
        },

        pagesList() {
            return this.pagination
        }
    },

    methods: {
        changePage(page) {
            // Atualiza a pagina atual
            this.pagesList.current_page = page
            // Dispara o evento @paginate do Component Pai
            this.$emit('paginate', page);
        }
    }
}
</script>
  
<style scoped>
:root {
    --default-color: #66615b;
    --primary-color: #51cbce;
    --success-color: #6bd098;
    --warning-color: #fbc658;
    --danger-color: #ef8157;
}

.container-paginationGroup {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
}

.group-pagination {
    display: flex;
}

.btn-border-default {
    border: 1px solid #66615b !important;
    background-color: #fff;
    color: var(--default-color) !important;
}

.btn-base {
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 5px;
}

.btn-regular {
    height: 38px;
    width: 60px;
}

.btn-left {
    border-radius: 5px 0 0 5px;
}

.btn-right {
    border-radius: 0 5px 5px 0;
}

.btn-group-pag {
    border: 0;
    border: 0.5px solid #66615b !important;
    border-radius: 0;
}

.active {
    background-color: #66615b;
    color: white !important;
}

.btn-hover:hover {
    background-color: #484848 !important;
    color: #fff !important;
    transition: all 0.4s ease-in;
}

.btn-hover:hover i.icon img {
    vertical-align: bottom;
    filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(254deg) brightness(110%) contrast(110%)
}
</style>