<template>
    <button class="btnDelete" tooltip="Deletar">
        <img src="@/assets/icones/trash.svg">
    </button>
</template>

<script>
export default {
    name: 'ButtonDelete',
}
</script>

<style scoped>
.btnDelete {
    border: 0;
    outline: none;
    background-color: transparent;
}

.btnDelete:not([disabled]):hover,
.btnDelete:not([disabled]):active {
    background: none !important;
}

button img {
    filter: invert(24%) sepia(92%) saturate(2107%) hue-rotate(349deg) brightness(94%) contrast(85%);
}
</style>