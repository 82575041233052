<template>
    <painel-component :title="'Ver'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <div class="container-tabs">
            <div class="card tabs">
                <input id="tab-1" type="radio" class="tab tab-selector" checked="checked" name="tab" />
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/info_circle.svg"></i>
                    <span>Informações</span>
                </label>
                <input id="tab-2" type="radio" class="tab tab-selector" name="tab" />
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/settings.svg"></i>
                    <span>Equipamento</span>
                </label>
                <input id="tab-3" type="radio" class="tab tab-selector" name="tab" />
                <label for="tab-3" class="tab tab-default">
                    <i><img src="@/assets/icones/clock.svg"></i>
                    <span>Horário de funcionamento</span>
                </label>
                <input id="tab-4" type="radio" class="tab tab-selector" name="tab" />
                <label for="tab-4" class="tab tab-warning">
                    <i><img src="@/assets/icones/wifi.svg"></i>
                    <span>Senhas Wifi</span>
                </label>
                <input id="tab-5" type="radio" class="tab tab-selector" name="tab" />
                <label for="tab-5" class="tab tab-warning">
                    <i><img src="@/assets/icones/users.svg"></i>
                    <span>Responsáveis</span>
                </label>
                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                    <label>Nome:</label>
                                    <input-component disabled :type-input="'text'" v-model="formDate.name" />
                                </row-col-component>

                                <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Empresa:</label>
                                    <multiselect label="name" v-model="formDate.company_id" :searchable="true"
                                        :options="selectCompany" disabled
                                        :noResultsText="this.$store.state.var.selectNotSearch" />
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Estado:</label>
                                    <multiselect v-model="formDate.state" :searchable="true" :options="selectCityState"
                                        disabled @select="loadSelectCity(formDate.state)"
                                        :noResultsText="this.$store.state.var.selectNotSearch" />
                                </row-col-component>

                                <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                    <label>Cidade:</label>
                                    <multiselect v-model="formDate.city" :searchable="true" :options="selectCity"
                                        disabled :noResultsText="this.$store.state.var.selectNotSearch" />
                                </row-col-component>

                                <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>CEP:</label>
                                    <input-component v-mask="'#####-###'" :type-input="'text'" v-model="formDate.cep"
                                        disabled />
                                </row-col-component>

                                <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                    <label>Bairro:</label>
                                    <input-component disabled :type-input="'text'" v-model="formDate.district" />
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Endereço:</label>
                                    <input-component disabled :type-input="'text'" v-model="formDate.address" />
                                </row-col-component>

                                <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5'">
                                    <label>Referência:</label>
                                    <input-text-area :rows="4" disabled v-model="formDate.complement" />
                                </row-col-component>
                            </row-component>

                        </form>
                        <hr>
                        <h1>Observações Internas</h1>
                        <div class="container-obs">
                            <div class="container-obs-user" v-for="(obs, index) in formDate.obsPrivate" :key="index">
                                <div class="row-user-obs">
                                    <img :src="obs.user_photo" v-if="obs.user_photo">
                                    <img src="@/assets/img/avatar.png" v-else>
                                    <p>Criado por: {{ obs.user_name }}</p>
                                    <p><img src="@/assets/icones/clock.svg">{{ obs.hour_create }}</p>
                                    <p><img src="@/assets/icones/calendar_month.svg">{{ obs.date_create }}</p>
                                </div>
                                <p>{{ obs.obs }}</p>
                            </div>
                        </div>
                        <form>
                            <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5'">
                                    <label>Observações Internas:</label>
                                    <input-text-area :rows="5" v-model="formDateObs.obs" />
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <button-one style="width: 100%" :itens="buttonThree" @click="submitObs" />
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-2">
                        <row-component v-if="formDate.equipments.length == 0">
                            <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                                <div class="alert alert-secondary icon-message" role="alert">
                                    <img src="@/assets/icones/clock.svg">
                                    Não há nenhum cadastro
                                </div>
                            </row-col-component>
                        </row-component>
                        <form>
                            <div class="container-clone-div" v-for="(equipments, index) in equipmentsAdd" :key="index">
                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Nome Equipamento:</label>
                                        <input-component disabled :type-input="'text'" v-model="equipments.name" />
                                    </row-col-component>
                                </row-component>
                            </div>
                        </form>
                    </div>

                    <div class="item" id="content-3">
                        <form>
                            <row-component v-if="formDate.openingHours.length == 0">
                                <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                                    <div class="alert alert-secondary icon-message" role="alert">
                                        <img src="@/assets/icones/clock.svg">
                                        Não há nenhum cadastro
                                    </div>
                                </row-col-component>
                            </row-component>

                            <div class="container-clone-div" v-for="(data, index) in formDate.openingHours"
                                :key="index">
                                <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-4'">
                                    <input type="radio" :id="'horario' + index" v-model="data.date_close_check"
                                        value="1">
                                    <label :for="'horario' + index">Horário</label>

                                    <input type="radio" :id="'desligar' + index" v-model="data.date_close_check"
                                        value="2">
                                    <label :for="'desligar' + index">Desligar Feriado</label>

                                </row-col-component>

                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome:</label>
                                        <input-component disabled :type-input="'text'" v-model="data.name" />
                                    </row-col-component>

                                    <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        v-if="data.date_close_check == 1">
                                        <label>Ligar / Desligar:</label>
                                        <multiselect v-model="data.type" :searchable="true" :options="selectSpotPoweOn"
                                            disabled :noResultsText="this.$store.state.var.selectNotSearch" />
                                    </row-col-component>

                                    <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        v-else>
                                        <label>Data:</label>
                                        <input-component v-mask="'##/##/####'" :type-input="'text'" disabled
                                            v-model="data.date_close" />
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        v-if="data.date_close_check == 1">
                                        <label>Dias da Semana:</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="1">
                                            <label class="form-check-label">Segunda-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="2">
                                            <label class="form-check-label">Terça-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="3">
                                            <label class="form-check-label">Quarta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="4">
                                            <label class="form-check-label">Quinta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="5">
                                            <label class="form-check-label">Sexta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="6">
                                            <label class="form-check-label">Sábado</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" v-model="data.days_weeks"
                                                disabled value="7">
                                            <label class="form-check-label">Domingo</label>
                                        </div>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Player:</label>

                                        <div class="form-check">
                                            <input class="form-check-input" v-model="data.players" type="checkbox"
                                                value="1" disabled>
                                            <label class="form-check-label">Player 1</label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="data.players" type="checkbox"
                                                value="2" disabled>
                                            <label class="form-check-label">Player 2</label>
                                        </div>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </form>
                    </div>

                    <div class="item" id="content-4">
                        <row-component v-if="formDate.passwordswifi.length == 0">
                            <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                                <div class="alert alert-secondary icon-message" role="alert">
                                    <img src="@/assets/icones/clock.svg">
                                    Não há nenhum cadastro
                                </div>
                            </row-col-component>
                        </row-component>
                        <form>
                            <div class="container-clone-div" v-for="(wifi, index) in wifiAdd" :key="index">
                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome Rede:</label>
                                        <input-component disabled :type-input="'text'" v-model="wifi.name" />
                                    </row-col-component>

                                    <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Senha:</label>
                                        <input-component disabled :type-input="'text'" v-model="wifi.password" />
                                    </row-col-component>
                                </row-component>
                            </div>
                        </form>
                    </div>

                    <div class="item" id="content-5">
                        <row-component v-if="formDate.responsibles.length == 0">
                            <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                                <div class="alert alert-secondary icon-message" role="alert">
                                    <img src="@/assets/icones/clock.svg">
                                    Não há nenhum cadastro
                                </div>
                            </row-col-component>
                        </row-component>
                        <form>
                            <div class="container-clone-div" v-for="(responsibles, index) in responsiblesAdd"
                                :key="index">
                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome:</label>
                                        <input-component disabled :type-input="'text'" v-model="responsibles.name" />
                                    </row-col-component>

                                    <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Função:</label>
                                        <input-component disabled :type-input="'text'"
                                            v-model="responsibles.function" />
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Email:</label>
                                        <input-component disabled :type-input="'text'" v-model="responsibles.email" />
                                    </row-col-component>

                                    <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Telefone:</label>
                                        <input-component v-mask="['(##) ####-####', '(##) #####-####']" disabled
                                            :type-input="'text'" v-model="responsibles.phone" />
                                    </row-col-component>
                                </row-component>
                            </div>
                        </form>
                    </div>
                </section>

            </div>
        </div>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-add style="width: 100%" :itens="buttonAdd"></button-add>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import InputTextArea from '@/components/forms/InputTextArea';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { mask } from 'vue-the-mask'
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.loadSpot()
        this.$store.commit('PRELOADER', true)
    },

    props: {
        id: { required: true }
    },

    name: 'SpotUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        InputTextArea,
        ButtonOne,
        ButtonAdd,
    },

    directives: { mask },

    data() {
        return {
            buttonAdd: {
                route: '/spot/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonThree: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar Observação',
                tooltip: 'Adicionar Observação'
            },

            buttonFour: {
                icon: 'plus',
                type: 'button',
                name: '',
                tooltip: 'Adicionar'
            },

            formDate: {
                company_id: '',
                name: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                obsPrivate: [],
                equipments: [],
                passwordswifi: [],
                responsibles: [],
                openingHours: [],
            },

            formDateObs: {
                id: this.id,
                obs: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadSpot() {
            this.$store.dispatch('updateNamePage', 'Localização')
            this.$store.dispatch('loadSpot', this.id).then((r) => {
                this.formDate = r;
                this.formDateObs.obs = ''
                this.checkACL()
                this.$store.dispatch('loadSelectCompany')
                this.$store.dispatch('loadSelectState')
                this.$store.dispatch('loadSelectCity', this.formDate.state)
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('spot-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        submitObs() {
            this.$store.dispatch('storeSpotObs', this.formDateObs).then(() => {
                this.loadSpot()
            })
        },

    },

    computed: {
        equipmentsAdd() {
            return this.formDate.equipments
        },

        wifiAdd() {
            return this.formDate.passwordswifi
        },

        responsiblesAdd() {
            return this.formDate.responsibles
        },

        selectCompany() {
            return this.$store.state.var.selectCompany
        },

        selectCityState() {
            return this.$store.state.var.selectCityState
        },

        selectCity() {
            return this.$store.state.var.selectCity
        }
    }

}

</script>

<style src="@/assets/css/tabs.css">

</style>

<style>

</style>