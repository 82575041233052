<template>
    <row-component class="py-3 px-5">
        <row-col-component v-if="newsApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'news-app-search' }">
                <div class="card-apps position-relative">
                    <div class="card-apps-top">
                        Notícias
                        <img src="@/assets/icones/newspaper.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">

                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="weatherApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'weather-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Clima
                        <img src="@/assets/icones/sun.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="lotteryApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'lottery-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Loteria
                        <img src="@/assets/icones/coins.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="clockApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'clock-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Relógio
                        <img src="@/assets/icones/clock.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="menuTableApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'menu-table-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Menu Tabela
                        <img src="@/assets/icones/table.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="youtubeApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'youtube-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Youtube
                        <img src="@/assets/icones/youtube.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="appWeb" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'web-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        App Web
                        <img src="@/assets/icones/globe.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Horizontal" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="appAvisos" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{ name: 'notice-app-search' }">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Avisos
                        <img src="@/assets/icones/megaphone.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Divisão de três telas" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical-two.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <row-col-component v-if="appObituary" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link :to="{name: 'obituary-app-search'}">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Obituário
                        <img src="@/assets/icones/star.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                    <div class="card-apps-bottom">
                        <img title="Vertical" class="img-fluid" src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                    </div>
                </div>
            </router-link>
        </row-col-component>

        <!-- <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link to="#">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Bolsa de Valores
                        <img src="@/assets/icones/graph_increase.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                </div>
            </router-link>
        </row-col-component> -->

        <!-- <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
            <router-link to="#">
                <div class="card-apps">
                    <div class="card-apps-top">
                        Informativo
                        <img src="@/assets/icones/info_circle.svg">
                    </div>
                    <img class="card-apps-arrow" src="@/assets/icones/arrow_right.svg">
                </div>
            </router-link>
        </row-col-component> -->

    </row-component>
</template>

<script>
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Aplicativo')
        this.ACLApps()
    },

    name: 'AppSearch',

    components: {
        RowComponent,
        RowColComponent
    },

    data() {
        return {
            weatherApp: false,
            newsApp: false,
            lotteryApp: false,
            clockApp: false,
            youtubeApp: false,
            menuTableApp: false,
            appWeb: false,
            appAvisos: false,
            appObituary: false
        }
    },

    methods: {
        async ACLApps() {
            this.$store.dispatch('ACLApps')
                .then((r) => {

                    if (r.data.length > 1) {

                        if (r.data.indexOf('app-clima-search') !== -1) {
                            this.weatherApp = true
                        }

                        if (r.data.indexOf('app-news-search') !== -1) {
                            this.newsApp = true
                        }

                        if (r.data.indexOf('app-lottery-search') !== -1) {
                            this.lotteryApp = true
                        }

                        if (r.data.indexOf('app-clock-search') !== -1) {
                            this.clockApp = true
                        }

                        if (r.data.indexOf('app-menu-table-search') !== -1) {
                            this.menuTableApp = true
                        }

                        if (r.data.indexOf('app-youtube-search') !== -1) {
                            this.youtubeApp = true
                        }

                        if (r.data.indexOf('app-web-url-search') !== -1) {
                            this.appWeb = true
                        }

                        if (r.data.indexOf('app-notice-elevator-search') !== -1) {
                            this.appAvisos = true
                        }

                        if (r.data.indexOf('app-obituary-room-search') !== -1) {
                            this.appObituary = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        }

    }
}
</script>

<style scoped>
a .card-apps:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transform: perspective(1px) translateZ(0);
}

.card-apps {
    transition: background-color 1s ease 0s;
    position: relative;
    height: 105px;
    width: 80%;
    border-radius: 10px;
    background-color: #fff;
    color: #000;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition-duration: 0.3s;
    transition-property: box-shadow;
}

.card-apps-top{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 3rem;
    top: 0;
    right: 0;
}

.card-apps-bottom{
    left: 0;
    bottom: 0;
    width: 25%;
    display: flex;
    padding: 10px;
    height: 2.5rem;
    position: absolute;
    justify-content: space-between;
}

.card-apps-arrow {
    position: absolute;
    margin: 0 10px;
    height: 2rem;
    bottom: 0;
    right: 0;
    filter: invert(48%) sepia(74%) saturate(522%) hue-rotate(350deg) brightness(96%) contrast(93%);
}

@media (max-width: 1280px) {
    .card-apps {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .card-apps {
        margin: 0 auto;
    }
}

@media (max-width: 661px) {
    .card-apps {
        width: 100%;
    }
}
</style>

