<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Local sepultamento (endereço):</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.label }">
                    <label>Local sepultamentos (exibição)</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.label"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect disabled v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'App Obituário Local')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
        this.loadObituaryLocationApp()
    },

    name: 'ObituaryLocationAppShow',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            buttonAdd: {
                route: '/obituary-location-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            formDate: {
                name: '',
                label: '',
                status: '',
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadObituaryLocationApp() {
            this.$store.dispatch('loadObituaryLocationApp', this.id)
                .then((r) => {
                    this.formDate = r
                }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('app-obituary-room-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>