import AdminComponent from '@/components/dashboard/AdminComponent'
import CompanySearch from '@/views/search/CompanySearch'
import CompanyCreate from '@/views/create/CompanyCreate'
import CompanyUpdate from '@/views/update/CompanyUpdate'
import CompanyShow from '@/views/show/CompanyShow'
import CompanyUpdatePhoto from '@/views/update/CompanyUpdatePhoto'

export default {
    path: '/company',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'company-search', component: CompanySearch },
        { path: 'create', name: 'company-create', component: CompanyCreate },
        { path: 'edit/:id', name: 'company-edit', component: CompanyUpdate, props: true },
        { path: 'show/:id', name: 'company-show', component: CompanyShow, props: true },
        { path: 'photo/:id', name: 'company-edit-photo', component: CompanyUpdatePhoto, props: true },
    ]
}