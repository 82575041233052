export default {
    LOAD_MENU_TABLE_APP(state, menu_table_app) { state.items = menu_table_app; },

    LOAD_CONTENT_MENU_TABLE_APP(state, item) {
        state.content_menu_table_app = item
    },

    LOAD_FILE_MENU_TABLE_APP(state, item) {
        state.file_menu_table_app = item
    },
}