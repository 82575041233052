import AdminComponent from '@/components/dashboard/AdminComponent'
import SpotSearh from '@/views/search/SpotSearch'
import SpotCreate from '@/views/create/SpotCreate'
import SpotUpdate from '@/views/update/SpotUpdate'
import SpotShow from '@/views/show/SpotShow'

export default {
    path: '/spot',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'spot-search', component: SpotSearh },
        { path: 'create', name: 'spot-create', component: SpotCreate },
        { path: 'edit/:id', name: 'spot-edit', component: SpotUpdate, props: true },
        { path: 'show/:id', name: 'spot-show', component: SpotShow, props: true }
    ]
}

