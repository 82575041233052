<template>
    <ModalComponent @submit-logout="destroyCampaign" @close-modal="closeModal" :visible="visible" :message="message"/>
    <painel-component :title="'Pesquisar'">

        <div class="campaign-add-player" v-if="addPlayer">
            <CampaignAddPlayer class="campaign-add-player-container" :id="idAddPlayer"
                               @close-campaign-add-player="addPlayer = !addPlayer"/>
        </div>

        <div class="modal-window" v-if="modal_share || share_edit">
            <CampaignShareCreate class="camp-share" :id="id_campaign" v-if="modal_share" @close="closeShare"/>
            <CampaignShareUpdate class="camp-share" :id="id_campaign" :id_share="id_share" v-if="share_edit"
                                 @close="closeShare"/>
        </div>

        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"/>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Empresa:</label>
                        <Multiselect v-model="formDate.company_id" :searchable="true" label="name"
                                     :options="selectCompanyRestrict">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img class="character-label-icon" :src="value.icon" alt=""/>
                                    {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""/>
                                {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn">
                            <button-one class="mx-1" :itens="buttonOne" @submit="submit"/>
                        </div>
                        <div class="mobi-btn">
                            <button-one class="mx-1" :itens="buttonTwo" @submit="submit"/>
                        </div>

                        <div v-if="!btnFilter" style="display: inline;">
                            <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonAdd"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 40%">Nome</th>
                    <th scope="col" style="width: 40%">Empresa</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.edit || buttonCheck.delete || buttonCheck.campaing_add_player">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in campaign.data" :key="index">
                    <td data-label="Nome"> {{ data.name }}</td>
                    <td data-label="Empresa">{{ data.company_name }}</td>
                    <td data-label="Ações"
                        v-if="buttonCheck.edit || buttonCheck.delete || buttonCheck.campaing_add_player || buttonCheck.campaing_share">
                        <button :data-player="data.total_player_add" class="btn-add-campaign"><img
                                v-if="buttonCheck.campaing_add_player" @click="addPLayerModal(data.id)"
                                src="@/assets/icones/play_button.svg"></button>

                        <button class="btn-share" @click="modalShare(data)" v-if="buttonCheck.campaing_share">
                            <img :class="data.share ? 'active' : ''" src="@/assets/icones/location.svg" alt="">
                        </button>

                        <button-edit v-if="buttonCheck.edit" :route="'campaign-edit'" :id-params="data.id"/>
                        <button-delete v-if="buttonCheck.delete" @click="openModal(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="campaign" :offset="3" @paginate="loadCampaigns"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination'
import {ACTION_MESSAGES} from '@/configs/messages';
import CampaignAddPlayer from '@/views/create/CampaignAddPlayer'
import CampaignShareCreate from "@/views/create/CampaignShareCreate.vue";
import CampaignShareUpdate from "@/views/update/CampaignShareUpdate.vue";

export default {
    created() {
        this.checkACL()
        this.loadCampaigns(1)
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('updateNamePage', 'Campanha')
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'CampaingSearch',

    components: {
        CampaignShareUpdate,
        CampaignShareCreate,
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        ButtonEdit,
        ButtonDelete,
        ButtonPagination,
        CampaignAddPlayer
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/campaign/create',
                icon: 'plus',
                tooltip: 'Adicionar',
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                campaing_add_player: false,
                campaing_share: false,
            },

            formDate: {
                name: '',
                company_id: ''
            },

            filter: true,
            btnFilter: false,

            addPlayer: false,
            idAddPlayer: '',

            id_campaign: '',
            id_share: '',
            share_edit: false,
            modal_share: false,

            idModal: '',
            message: '',
            visible: false,
            searchSubmit: false

        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadCampaigns(1)
            this.searchSubmit = true;
        },

        addPLayerModal(id) {
            this.idAddPlayer = id
            this.addPlayer = !this.addPlayer
        },

        modalShare(data) {
            this.id_campaign = data.id
            this.id_share = data.shareID

            if (data.share === true)
                this.share_edit = true
            if (data.share === false)
                this.modal_share = true
        },

        closeShare(n) {
            this.modal_share = false
            this.share_edit = false

            if (n === 1) {
                this.loadCampaigns()
                this.modal_share = false
                this.share_edit = false
            }
        },

        loadCampaigns(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadCampaigns', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 7)
                .then((r) => {

                    if (r.data.indexOf('campaing-search') !== -1) {

                        if (r.data.indexOf('campaing-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('campaing-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('campaing-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('campaing-add-player') !== -1) {
                            this.buttonCheck.campaing_add_player = true
                        }

                        if (r.data.indexOf('campaing-share') !== -1) {
                            this.buttonCheck.campaing_share = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyCampaign() {
            this.visible = !this.visible
            this.$store.dispatch('destroyCampaign', this.idModal)
                .then(() => {
                    this.loadCampaigns(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadCampaigns(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        campaign() {
            return this.$store.state.campaign.items
        },

        params() {
            return {
                page: this.campaign.current_page,
                name: this.formDate.name,
                company_id: this.formDate.company_id
            }
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },
    }

}
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>
.btn-add-campaign,
.btn-share {
    border: 0;
    outline: none;
    position: relative;
    margin-right: 15px;
    background-color: transparent;
}

.btn-share img.active {
    filter: invert(56%) sepia(66%) saturate(874%) hue-rotate(77deg) brightness(91%) contrast(82%);
}

.btn-add-campaign:not([disabled]):hover,
.btn-share:not([disabled]):hover,
.btn-add-campaign:not([disabled]):active,
.btn-share:not([disabled]):active {
    background: none !important;
}

.btn-add-campaign::before {
    content: attr(data-player);
    z-index: 1;
    width: 25px;
    height: 25px;
    top: -35%;
    left: -110%;
    color: black;
    font-weight: bold;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    background-color: #d3d3d3;
}

.campaign-add-player {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.modal-window {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.modal-window > .camp-share {
    width: 70%;
}

.campaign-add-player > .campaign-add-player-container {
    width: 80%;
    height: 85%;
    overflow-y: scroll;
}

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .campaign-add-player > .campaign-add-player-container {
        width: 97%;
        height: auto;
        overflow-y: scroll;
    }

    .modal-window > .camp-share {
        width: 97%;
        height: auto;
        overflow-y: scroll;
    }

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }

}

@media (max-width: 425px) {
    .campaign-add-player > .campaign-add-player-container {
        width: 95%;
        height: auto;
        overflow-y: scroll;
    }
}

@media (max-width: 375px) {
    .campaign-add-player > .campaign-add-player-container {
        width: 95%;
        height: auto;
        overflow-y: scroll;
    }
}
</style>