<template>
    <ModalComponent @submit-logout="destroyObituaryApp" @close-modal="closeModal" :visible="visible"
                    :message="message"/>
    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonLocation"/>
            <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonAdd"/>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Empresa:</label>
                        <Multiselect label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                                     :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                            <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                    <img alt="" class="character-label-icon" :src="value.icon"> {{ value.name }}
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img alt="" class="character-option-icon" :src="option.icon"> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <Multiselect :options="selectActiveInactive" v-model="formDate.status"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0'">
                        <div class="desk-btn">
                            <button-one class="mx-1" :itens="buttonOne" @submit="submit"/>
                        </div>
                        <div class="mobi-btn">
                            <button-one class="mx-1" :itens="buttonTwo" @submit="submit"/>
                        </div>

                        <div v-if="!btnFilter" style="display: inline;">
                            <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonAdd"/>
                            <button-add v-if="buttonCheck.create" class="mx-1" :itens="buttonLocation"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Empresa</th>
                    <th scope="col" style="width: 20%">Status</th>
                    <th scope="col" style="width: 20%">Tempo</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
                        Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in obituary_app.data" :key="index">
                    <td data-label="Nome"> {{ data.name }}</td>
                    <td data-label="Empresa">{{ data.company_name }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Status">{{ data.time }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
                        <a v-if="buttonCheck.show" tooltip="Pré Visualizar" target="_blank" class="btnShow me-3"
                           :href="data.urlPreview">
                            <img src="@/assets/icones/airplay.svg">
                        </a>
                        <button-edit v-if="buttonCheck.edit" :route="'obituary-app-edit'" :id-params="data.id"/>
                        <button-delete v-if="buttonCheck.delete" @click="openModal(data)"/>
                        <button-show v-if="buttonCheck.show" class="ms-3" :route="'obituary-app-show'"
                                     :id-params="data.id"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="obituary_app" :offset="3" @paginate="loadObituarysApps"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.checkACL()
        this.loadObituarysApps(1)
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('updateNamePage', 'App Obituário')
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'ObituaryAppSearch',
    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        ButtonEdit,
        ButtonShow,
        ButtonDelete,
        ButtonPagination
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/obituary-app/create',
                icon: 'plus',
                tooltip: 'Adicionar',
            },

            buttonLocation: {
                route: '/obituary-location-app/search',
                icon: 'location',
                tooltip: 'Adicionar',
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false,
                delete: false
            },

            formDate: {
                name: '',
                status: '',
                company_id: ''
            },

            filter: true,
            btnFilter: false,

            idModal: '',
            message: '',
            visible: false,
            searchSubmit: false
        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadObituarysApps(1)
            this.searchSubmit = true;
        },

        loadObituarysApps(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadObituarysApps', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('app-obituary-room-search') !== -1) {

                        if (r.data.indexOf('app-obituary-room-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('app-obituary-room-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('app-obituary-room-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('app-obituary-room-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyObituaryApp() {
            this.visible = !this.visible
            this.$store.dispatch('destroyObituaryApp', this.idModal)
                .then(() => {
                    this.loadObituarysApps(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadObituarysApps(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        statusString(value) {
            if (value == 1)
                return 'Ativo'
            return 'Inativo'
        }

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        obituary_app() {
            return this.$store.state.obituary_app.items
        },

        params() {
            return {
                page: this.obituary_app.current_page,
                name: this.formDate.name,
                status: this.formDate.status,
                company_id: this.formDate.company_id
            }
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }
}

</style>