import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'app/obituary-roow-local-search';
export default {

    loadObituarysLocationsApps(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_OBITUARY_LOCATION_APP', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    storeObituarysLocationApps(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}app/obituary-roow-local-add`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    loadObituaryLocationApp(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/obituary-roow-local-show/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateObituaryLocationApp(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}app/obituary-roow-local-edit/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

}