import axios from "axios";
import {URL_BASE} from "@/configs/constants";

export default {

    loadNoticesAppsContets(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/notice-elevator-list-contents`, {params})
                .then(response => {
                    context.commit('LOAD_NOTICE_APP_CONTENT', response.data)
                    return resolve(response.data)
                }).catch(() => {
                return reject()
            })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadNoticesAppsContetsFiles(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/notice-elevator-files`, {params})
                .then(response => {
                    context.commit('LOAD_NOTICE_APP_CONTENT_FILE', response.data)
                    return resolve(response.data)
                }).catch(() => {
                return reject()
            })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeNoticeAppBackground(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}app/notice-elevator-add-background`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeNoticeAppLogo(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}app/notice-elevator-add-logo`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}