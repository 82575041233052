<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <div class="container-avatar-user">
                <img v-if="me.photo" :src="me.photo">
                <img v-else src="@/assets/img/avatar.png">
                {{ me.fullName }}
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.password }">
                    <label>Senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.password" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.passwordconfirm }">
                    <label>Confirmar a senha:</label>
                    <input-component :type-input="'password'" v-model="formDate.passwordconfirm" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>
  
<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
    },

    name: 'UserUpdatePassword',

    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonAdd,
        ButtonOne
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                type: 'button',
                icon: 'floppy',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            formDate: {
                password: '',
                passwordconfirm: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateUserPassword', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                        setTimeout(() => this.$router.push({ name: 'home' }), 2100);
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

    },

    computed: {

        me() {
            return this.$store.state.auth.me
        }

    },

}
</script>
  
<style scoped>
.container-avatar-user {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    justify-content: flex-start;
    height: 130px;
    width: 100%;
}

.container-avatar-user>img {
    border-radius: 50%;
    margin-right: 15px;
    width: 100px;
}

@media(max-width: 991px) {
    .container-avatar-user {
        margin: 5% 0;
        flex-direction: column;
        align-items: center;
    }
}
</style>
  