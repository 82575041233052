export default {
    icon: 'display',
    name: 'Campanha',
    route: '/campaign/search',
    pagePermissions: [
        'campaing-create',
        'campaing-search',
        'campaing-edit',
        'campaing-delete',
        'campaing-add-player',
        'campaing-add-files',
        'campaing-add-app',
        'campaing-synchronize'
    ],

    pageLinksNavCheck: [
        'campaing-create',
        'campaing-search',
        'campaing-edit',
        'campaing-delete',
        'campaing-add-player',
        'campaing-add-files',
        'campaing-add-app',
        'campaing-synchronize'
    ]
}


