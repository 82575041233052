export default {

    UPDATE_NAME_PAGE(state, item) {
        state.namePage = item
    },

    UPDATE_SELECT_PERMISSIONS(state, item) {
        state.selectPermissions = item
    },

    UPDATE_SELECT_GROUP_USER(state, item) {
        state.selectGroupUser = item
    },

    UPDATE_SELECT_COMPANY(state, item) {
        state.selectCompany = item
    },

    UPDATE_SELECT_COMPANY_RESTRICT(state, item) {
        state.selectCompanyRestrict = item
    },

    UPDATE_SELECT_CITY_STATE(state, item) {
        state.selectCityState = item
    },

    UPDATE_SELECT_CITY(state, item) {
        state.selectCity = item
    },

    UPDATE_SELECT_RESOLUTION_PLAYER(state, item) {
        state.selectResolutionPlayer = item
    },

    UPDATE_SELECT_MEDIA_TYPE_PLAYER(state, item) {
        state.selectTypeMediaPlayer = item
    },

    UPDATE_SELECT_SEGMENT_PLAYER(state, item) {
        state.selectSegmentPlayer = item
    },

    UPDATE_SELECT_SPOT(state, item) {
        state.selectSpot = item
    },

    UPDATE_SELECT_LAYOUT_CAMPAIGN(state, item) {
        state.selectLayoutCampaign = item
    },

    UPDATE_SELECT_CITY_CLIMATE(state, item) {
        state.selectCityClimate = item
    },

    UPDATE_SELECT_NOTICE_LIST_URL(state, item) {
        state.selectNoticeListUrl = item
    },

    UPDATE_SELECT_MENU_FONTS_TABLE(state, item) {
        state.selectFontsMenuTable = item
    },

    UPDATE_SELECT_CONTENT_FOLDER(state, item) {
        state.selectContentFolder = item
    },

    UPDATE_SELECT_NOTICE_APP(state, item) {
        state.selectNoticeApp = item
    },

    UPDATE_SELECT_VERSION_APP(state, item) {
        state.selectVersionApp = item
    },

    UPDATE_SELECT_VERSION_ANDROID(state, item) {
        state.selectVersionAndroid = item
    },

    UPDATE_SELECT_OBITUARY_LIST(state, item) {
        state.selectObituaryList = item
    },

    UPDATE_SELECT_CONTENT_SPOT(state, item) {
        state.selectContentSpot = item
    },
}