<template>
    <div class="modal-component" v-if="visible"></div>
    <div class="modal-content" v-if="visible">
        <div @click="$emit('close-modal')" class="close">X</div>
        <header>
            <h2>Atenção!</h2>
        </header>

        <article class="content">
            <p>{{ message }}</p>
        </article>
        <footer>
            <button class="button success" @click="$emit('submit-logout')">Sim</button>
            <button class="button danger" @click="$emit('close-modal')">Não</button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'ModalComponent',

    props: {
        visible: {required: true, default: false, type: Boolean},
        message: {required: true, default: '', type: String}
    },
    emits: ['submit-logout', 'close-modal'],

    data() {
        return {}
    },
}
</script>

<style scoped>
.modal-component {
    position: fixed;
    background-color: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
}

.modal-content {
    position: fixed;
    top: 40%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 28.5em;
    height: 17em;
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    z-index: 101;
}

.modal-content .close {
    position: absolute;
    right: 1rem;
    cursor: pointer;
    font-size: 18px;
    transition: transform 500ms ease;
    z-index: 11;
}

.modal-content header {
    position: relative;
    display: block;
    border-bottom: 1px solid #eee;
}

.modal-content header h2 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 28px;
}

.modal-content article {
    top: 20%;
    margin: 0 auto;
    position: relative;
    display: block;
    padding: 0;
    font-size: 18px;
    line-height: 1.75;
}

.modal-content footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 10px 0 0;
}

.modal-content footer {
    position: absolute;
    bottom: 1rem;
    left: -1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 0 0;
}

.modal-content footer .button {
    border: 0;
    position: relative;
    padding: 10px 30px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    overflow: hidden;
}

.modal-content footer .button:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 250ms ease;
    z-index: 0;
}

.modal-content footer .button:hover:before {
    width: 100%;
}

.modal-content footer .button.success {
    margin-right: 5px;
    background-color: #FF7700 !important;
}

.modal-content footer .button.danger {
    background-color: #66615b !important;
}

@media (max-width: 991px) {
    .modal-content {
        width: 95%;
    }

    .modal-content article {
        font-size: 16px;
    }
}
</style>


