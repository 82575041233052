<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <div v-if="buttonCheck.campaing_add_player" class="campaign-add-player-button"
             :data-player="formDate.total_player_add">
            <button-one :itens="buttonSix" flow="down" @click="addPlayer = !addPlayer"/>
        </div>

        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd"/>

        <div class="campaign-add-player" v-if="addPlayer">
            <CampaignAddPlayer class="campaign-add-player-container" :id="id"
                               @close-campaign-add-player="closeCampaignAddPlayer"/>
        </div>

        <div class="window-modal" v-if="addContent">
            <div class="modal-option">
                <div @click="addContent = !addContent" class="btn-close"></div>
                <div v-if="buttonCheck.campaing_add_app" @click="closeModalApp" class="button-add-content">
                    <img class="card-apps-arrow" src="@/assets/img/3.png"/>
                    <p>Aplicativo</p>
                </div>
                <div v-if="buttonCheck.campaing_add_files" @click="closeModalContent" class="button-add-content">
                    <img class="card-apps-arrow" src="@/assets/img/1.png"/>
                    <p>Conteúdo</p>
                </div>
            </div>
        </div>

        <div v-if="addContentTwo" class="window-modal-two">
            <CampaignAppSearch class="campaign-app" v-if="addApp" :id="id" @close-modal-app-submit="closeModalApp"
                               @close-modal-app="closeModalApp"/>

            <CampaingContentSearch class="campaign-content" v-if="addFolder" :id="id"
                                   @close-modal-content="closeModalContent"/>

            <FileCampaignInfo class="app-file-info" v-if="InfoFile" :idCampaing="id" :idAppFile="formDateFile.idAppFile"
                              @close-file-info="modalInfoFile"/>
        </div>

        <div v-if="iframePreview" class="window-modal">
            <div @click="iframePreview = !iframePreview" class="btn-close-preview">
                X
            </div>
            <img v-if="objectPreview.type == 1" :src="objectPreview.show_url"/>
            <video v-if="objectPreview.type == 2" :src="objectPreview.show_url" autoplay loop controls></video>
        </div>

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ formError: errors.name }">
                    <label>Nome da Campanha:</label>
                    <input-component v-model="formDate.name" :type-input="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ formError: errors.company_id }">
                    <label>Empresa:</label>
                    <Multiselect v-model="formDate.company_id" :searchable="true" label="name"
                                 :options="selectCompanyRestrict">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"/>
                                {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"/>
                            {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ formError: errors.layout }">
                    <label>Layout:</label>
                    <Multiselect @select="clearNoticeAppField(formDate.layout)" v-model="formDate.layout"
                                 :searchable="true"
                                 label="name" :options="selectLayoutCampaign">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"/>
                                {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"/>
                            {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

        <hr/>
        <div class="total-time-campaign">
            <h1>Adicionar Conteúdo</h1>
            <h1><img class="mb-1" src="@/assets/icones/clock.svg"/> Tempo de campanha {{ formDate.total_time }}</h1>
        </div>


        <button-one v-if="buttonCheck.campaing_add_files || buttonCheck.campaing_add_app" :disabled="sendForm"
                    class="my-3"
                    @click="addContent = !addContent" :itens="buttonFour"/>
        <button-one v-if="buttonCheck.campaing_synchronize" :disabled="sendForm" class="my-3"
                    @click="campaingSynchronize"
                    style="float: right" :itens="buttonFive"/>

        <button :class="{ 'btn-disabled': deleteContent }" class="btn btn-delete m-3" tooltip="Remover"
                @click="deleteContentFunc">
            <img src="@/assets/icones/trash.svg"/>
        </button>
        <draggable v-model="formDate.files_apps" group="people" @start="drag = true" @end="drag = false"
                   :delay="delay ? 300 : 0"
                   item-key="id">
            <template #item="{ element, index }">
                <div class="file-content-container" :data-index="index + 1">
                    <div @click="previewContent(element)" class="file-content" :tooltip="element.name">
                        <img :src="require(`@/assets/img/${element.type}.png`)"/>
                        <p>{{ element.name }}</p>
                    </div>
                    <div class="info-file">
                        <div v-if="!deleteContent" class="mx-1" :tooltip="statusStringDouble(element.double)">
                            <img :class="[{
                  'bg-refresh-ac': element.double == 2,
                  'bg-refresh-dc': element.double == 1,
                },]" src="@/assets/icones/replicate.svg"/>
                        </div>

                        <div v-if="!deleteContent" class="mx-1" :tooltip="statusString(element.synced)">
                            <img :class="[{
                  'bg-duplicate-ac': element.synced == 2,
                  'bg-duplicate-dc': element.synced == 1,
                },]" src="@/assets/icones/refresh.svg"/>
                        </div>

                        <div role='button' @click="deleteStatus(index, element.delete)" v-if="deleteContent"
                             class="ms-4">
                            <img :class="[{
                  'bg-delete-dc': element.delete == 1,
                  'bg-delete-ac': element.delete == 2,
                },]" src="@/assets/icones/trash.svg"/>
                        </div>
                    </div>

                    <div v-if="!deleteContent" class="dropdown">
                        <img role='button' @click="menuDropDown(index)" :id="'dropmenuImg' + index" class="icon-drop"
                             src="@/assets/icones/chevron_down.svg"/>
                        <div :id="'containerMenu' + index" class="action-buttons">
                            <button @click="modalInfoFile(element.id)" tooltip="Informações">
                                <img src="@/assets/icones/info_circle.svg"/>
                            </button>
                            <button :disabled="sendForm" @click="doubleCampaignAppFile(element.id)" tooltip="Duplicar">
                                <img src="@/assets/icones/replicate_alt.svg"/>
                            </button>
                            <button @click="previewContent(element)" tooltip="Visualizar">
                                <img src="@/assets/icones/eye.svg"/>
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowColComponent from "@/components/row/RowColComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect/src/Multiselect";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd";
import draggable from "vuedraggable";
import {ACTION_MESSAGES} from "@/configs/messages";
import FileCampaignInfo from "@/views/show/FileCampaignInfo";
import CampaignAppSearch from "@/views/search/CampaignAppSearch";
import CampaingContentSearch from "@/views/search/CampaignContentSearch";
import CampaignAddPlayer from "@/views/create/CampaignAddPlayer";

export default {
    created() {
        this.$store.dispatch("updateNamePage", "Campanha");
        this.$store.commit("PRELOADER", true);
        this.loadCampaign();
        this.checkACL();

        if (window.innerWidth <= 991) {
            this.delay = true
        }
    },

    props: {
        id: {required: true},
    },

    name: "CampaignCreate",
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
        draggable,
        FileCampaignInfo,
        CampaignAppSearch,
        CampaingContentSearch,
        CampaignAddPlayer,
    },

    data() {
        return {
            buttonAdd: {
                route: "/campaign/search",
                icon: "jump_left",
                name: "Voltar",
                tooltip: "Voltar",
            },

            buttonOne: {
                icon: "floppy",
                type: "button",
                name: "Salvar",
                tooltip: "Salvar",
            },

            buttonTwo: {
                icon: "plus",
                name: "",
                tooltip: "Adicionar",
            },

            buttonFour: {
                icon: "component_add",
                type: "button",
                name: "",
                tooltip: "Adicionar Conteúdo",
            },

            buttonFive: {
                icon: "refresh",
                type: "button",
                name: "",
                tooltip: "Sincronizar",
            },

            buttonSix: {
                icon: "play_button",
                type: "button",
                name: "",
                tooltip: "Adicionar Player",
            },

            formDate: {
                name: "",
                company_id: "",
                layout: "",
                total_time: "",
                total_player_add: "",
                files_apps: [],
            },

            formDateFile: {
                id: this.id,
                idAppFile: "",
            },

            buttonCheck: {
                campaing_add_player: false,
                campaing_add_files: false,
                campaing_add_app: false,
                campaing_synchronize: false,
                create: false
            },

            delay: false,

            iframePreview: false,
            objectPreview: "",

            deleteContent: false,
            deleteContentBtn: 1,

            dropdownMenu: false,

            addContent: false,
            addContentTwo: false,
            addApp: false,
            addFolder: false,
            InfoFile: false,
            addPlayer: false,

            errors: [],
            showError: false,
            sendForm: false,
        };
    },

    methods: {
        routeAppSearch() {
            this.$router.push({
                name: "campaign-app-search",
                params: {id: this.id},
            });
        },

        routeAdd() {
            this.$router.push({name: "campaign-create"});
        },

        clearNoticeAppField(id) {
            if (id != 3)
                this.formDate.app_id = ""
        },

        previewContent(element) {
            this.iframePreview = !this.iframePreview;
            this.objectPreview = element;

            if (element.type >= 3) {
                this.iframePreview = false;
                window.open(element.show_url, "_blank");
            }
        },

        deleteStatus(index, status) {
            let file_app = this.formDate.files_apps[index]
            if (status == 1) {
                return file_app.delete = 2
            } else {
                return file_app.delete = 1
            }
        },

        menuDropDown(index) {
            let drop = document.querySelector('#dropmenuImg' + index)
            let action_buttons = document.querySelector('#containerMenu' + index)
            drop.classList.toggle('icon-drop-rotate')
            if (action_buttons.style.display == 'flex') {
                action_buttons.style.display = 'none'
            } else {
                action_buttons.style.display = 'flex'
            }
        },

        deleteContentFunc() {
            this.deleteContent = !this.deleteContent
            this.loadCampaign()
        },

        statusStringDouble(value) {
            if (value == 1) return "";

            if (value == 2) return "Clonado";
        },

        statusString(value) {
            if (value == 1) return "";

            if (value == 2) return "Sincronizado";
        },

        closeModalApp(n) {
            this.addApp = !this.addApp;
            this.addContent = false;
            this.addContentTwo = !this.addContentTwo;
            if (n == 1) this.loadCampaign();
        },

        closeModalContent(n) {
            this.addFolder = !this.addFolder;
            this.addContent = false;
            this.addContentTwo = !this.addContentTwo;
            if (n == 1) this.loadCampaign();
        },

        modalInfoFile(id) {
            this.addContentTwo = !this.addContentTwo;
            this.formDateFile.idAppFile = id;
            this.InfoFile = !this.InfoFile;
        },

        closeCampaignAddPlayer() {
            this.addPlayer = !this.addPlayer;
            this.loadCampaign();
        },

        loadCampaign() {
            this.$store
                .dispatch("loadCampaign", this.id)
                .then((r) => {
                    this.$store.dispatch("loadSelectCompanyRestrict");
                    this.$store.dispatch("loadSelectLayout");
                    this.formDate = r;
                })
                .catch(() => {
                    this.$store.commit("addToast", ACTION_MESSAGES.errorDefault);
                });
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store
                    .dispatch("updateCampaign", this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false;
                        this.errors = [];
                        this.$store.commit("addToast", ACTION_MESSAGES.updateDefault);
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false;
                        this.errors = r.response.data.errors;
                        this.$store.commit("addToast", ACTION_MESSAGES.errorFormDefault);
                    });
            }
        },

        async checkACL() {
            this.$store
                .dispatch("ACLitens", 7)
                .then((r) => {
                    if (r.data.indexOf("campaing-edit") !== -1) {
                        this.$store.commit("PRELOADER", false);

                        if (r.data.indexOf("campaing-add-player") !== -1) {
                            this.buttonCheck.campaing_add_player = true;
                        }

                        if (r.data.indexOf("campaing-add-files") !== -1) {
                            this.buttonCheck.campaing_add_files = true;
                        }

                        if (r.data.indexOf("campaing-add-app") !== -1) {
                            this.buttonCheck.campaing_add_app = true;
                        }

                        if (r.data.indexOf("campaing-synchronize") !== -1) {
                            this.buttonCheck.campaing_synchronize = true;
                        }

                        if (r.data.indexOf("campaing-create") !== -1) {
                            this.buttonCheck.create = true;
                        }
                    } else {
                        this.$router.push({name: "home"});
                    }
                })
                .catch(() => {
                    this.$router.push({name: "home"});
                });
        },

        campaingSynchronize() {
            let file_app = this.formDate.files_apps
            let files_apps_synchronize = []
            let params = []

            file_app.forEach(element => {
                files_apps_synchronize.push({
                    id: element.id,
                    delete: element.delete
                })
            })

            params.push({
                campaign_id: this.id,
                files_apps_synchronize
            })

            this.deleteContent = false

            this.$store
                .dispatch("campaingSynchronize", params[0])
                .then(() => {
                    this.showError = false;
                    this.sendForm = false;
                    this.errors = [];
                    this.loadCampaign();
                    this.$store.commit("addToast", {
                        title: "Sucesso!",
                        type: "success",
                        message: "Campanha sincronizada com sucesso",
                    });
                })
                .catch((r) => {
                    this.showError = true;
                    this.sendForm = false;
                    this.errors = r.response.data.errors;
                    this.loadCampaign();
                    this.$store.commit("addToast", ACTION_MESSAGES.errorDefault);
                    this.$store.commit("addToast", {
                        title: "Oops!",
                        type: "error",
                        message: "Erro ao sincronizar a campanha",
                    });
                });
        },

        doubleCampaignAppFile(idFile) {
            this.formDateFile.idAppFile = idFile;
            this.$store
                .dispatch("doubleCampaignAppFile", this.formDateFile)
                .then(() => {
                    this.showError = false;
                    this.sendForm = false;
                    this.errors = [];
                    this.loadCampaign();
                    this.$store.commit("addToast", {
                        title: "Sucesso!",
                        type: "success",
                        message: "Arquivo duplicado com sucesso",
                    });
                })
                .catch((r) => {
                    this.showError = true;
                    this.sendForm = false;
                    this.errors = r.response.data.errors;
                    this.loadCampaign();
                    this.$store.commit("addToast", {
                        title: "Oops!",
                        type: "error",
                        message: "Erro ao duplicar arquivo",
                    });
                });
        },
    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict;
        },

        selectLayoutCampaign() {
            return this.$store.state.var.selectLayoutCampaign;
        },
    },
};
</script>

<style scoped>
.handle {
    float: left;
    padding-top: 8px;
    padding-bottom: 8px;
    display: none;
}

@media (max-width: 428px) {
    .handle {
        display: block;
    }
}

.info-file {
    width: 13%;
    height: 55px;
    display: flex;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.total-time-campaign {
    display: flex;
    font-size: 1rem;
    flex-wrap: wrap;
    margin-right: 2%;
    justify-content: space-between;
}

.file-content-container {
    margin: 0;
    width: 50%;
    padding: 0 5px;
    position: relative;
    align-items: center;
    display: inline-flex;
    user-select: none;
}

.file-content-container::before {
    content: attr(data-index);
    top: -10%;
    left: 0;
    z-index: 1;
    width: 15px;
    height: 15px;
    display: grid;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    place-items: center;
    background-color: #d67c1c;
}

.file-content {
    width: 100%;
    height: 55px;
    display: flex;
    position: relative;
    align-items: center;
    background: #ebebeb;
    word-wrap: break-word;
}

.file-content > img {
    height: auto;
    width: 50px;
    margin-right: 5px;
}

.file-content p {
    font-size: 14px;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-content:hover {
    cursor: pointer;
    background-color: rgb(192, 192, 192, 0.4);
}

.window-modal,
.window-modal-two {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.modal-option {
    width: 27%;
    height: 30%;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 10px;
    background-color: #f4f3ef;
    justify-content: space-evenly;
}

.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    cursor: pointer;
    position: absolute;
}

.btn-close-preview {
    top: 0;
    right: 5%;
    margin: 2%;
    color: white;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
}

.window-modal-two .campaign-app,
.window-modal-two .campaign-content {
    width: 70%;
    height: 75%;
}

.window-modal-two .app-file-info {
    width: 50%;
    height: 45%;
}

.button-add-content {
    width: 36%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    text-transform: uppercase;
    background-color: #fff;
    color: #000;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    transition-property: box-shadow;
    cursor: pointer;
}

.button-add-content p {
    bottom: 0;
    position: absolute;
    margin-bottom: 0.5rem;
}

.button-add-content img {
    height: auto;
    width: 50%;
}

.dropdown {
    width: 10%;
    height: 55px;
    display: grid;
    position: relative;
    place-items: center;
    background: #ebebeb;
}

.dropdown img {
    width: 60%;
    height: auto;
}

.icon-drop {
    transition: all 0.5s;
}

.icon-drop-rotate {
    rotate: 90deg;
    transition: all 0.5s;
}

.action-buttons {
    top: 0;
    right: 50px;
    height: 55px;
    display: none;
    position: absolute;
}

.action-buttons > button {
    border: 0;
    width: 55px;
    padding: 5px;
    height: 55px;
    background: #ebebeb;
}

.bg-delete-ac {
    filter: invert(25%) sepia(52%) saturate(2251%) hue-rotate(323deg) brightness(103%) contrast(113%);
}

.bg-duplicate-ac {
    filter: invert(47%) sepia(97%) saturate(470%) hue-rotate(75deg) brightness(102%) contrast(84%);
}

.bg-refresh-ac {
    filter: invert(23%) sepia(100%) saturate(2321%) hue-rotate(213deg) brightness(106%) contrast(98%);
}

.bg-delete-dc,
.bg-refresh-dc,
.bg-duplicate-dc {
    filter: invert(94%) sepia(2%) saturate(46%) hue-rotate(323deg) brightness(88%) contrast(90%);
}

.window-modal > img,
.window-modal > video {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.campaign-add-player-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.campaign-add-player-button::before {
    content: attr(data-player);
    z-index: 1;
    width: 25px;
    height: 25px;
    top: -35%;
    right: -20%;
    color: black;
    padding: 0rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    background-color: #d3d3d3;
}

.campaign-add-player {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.campaign-add-player > .campaign-add-player-container {
    width: 80%;
    height: 85%;
    overflow-y: scroll;
}

.add-button {
    top: 0;
    right: 20px;
    margin: 2% 5%;
    position: absolute;
}

.btn-delete:not([disabled]):not(.disabled).active,
.btn-delete:not([disabled]):not(.disabled):active,
.btn-delete:not([disabled]):not(.disabled).hover,
.btn-delete:not([disabled]):not(.disabled):hover {
    color: #fff !important;
    border-color: none !important;
    background-color: rgb(135 135 135 / 50%) !important;
}

.btn-disabled {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-disabled.btn-delete:not([disabled]):not(.disabled).active,
.btn-disabled.btn-delete:not([disabled]):not(.disabled):active,
.btn-disabled.btn-delete:not([disabled]):not(.disabled).hover,
.btn-disabled.btn-delete:not([disabled]):not(.disabled):hover {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: none !important;
}

.btn-delete {
    float: right;
    height: 42px;
    min-width: 42px;
    line-height: 20px;
    border-color: none !important;
    background-color: rgb(135 135 135 / 50%);
}

.btn-delete img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(102%) contrast(101%);
}
</style>

<style scoped>

@media (max-width: 1366px) {
    .window-modal-two .campaign-app,
    .window-modal-two .campaign-content {
        width: 85%;
        height: 85%;
        overflow: auto;
    }
}

@media (max-width: 1280px) {
    .add-button {
        right: 25px;
        margin: 2% 6%;
    }

    .window-modal-two .campaign-app,
    .window-modal-two .campaign-content {
        width: 75%;
        height: 65%;
    }

    .modal-option {
        width: 35%;
        height: 30%;
    }

}

@media (max-width: 991px) {
    .file-content-container {
        padding: 0;
        width: 100%;
        margin: 10px 0;
    }

    .file-content {
        width: 100%;
    }

    .campaign-add-player > .campaign-add-player-container {
        width: 97%;
        height: auto;
        overflow-y: scroll;
    }

    .modal-option {
        width: 50%;
        height: 40%;
    }

    .window-modal-two .campaign-app,
    .window-modal-two .campaign-content {
        width: 95%;
        height: 95%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .window-modal-two .app-file-info {
        width: 95%;
        height: auto;
        overflow-y: scroll;
    }

    .add-button {
        right: 35px;
        margin: 2% 6%;
    }

    .action-buttons {
        right: 35px;
    }

}

@media (max-width: 500px) {
    .campaign-add-player > .campaign-add-player-container {
        width: 95%;
        height: auto;
        overflow-y: scroll;
    }

    .modal-option {
        width: 95%;
        height: 40%;
    }

    .info-file {
        width: 27%;
    }

    .action-buttons {
        right: 30px;
    }

    .dropdown {
        width: 15%;
    }
}

@media (max-width: 375px) {
    .campaign-add-player > .campaign-add-player-container {
        width: 95%;
        height: auto;
        overflow-y: scroll;
    }
}
</style>
