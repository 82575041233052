import actions from '@/store/modules/user-location/actions'
import getters from '@/store/modules/user-location/getters'
import mutations from '@/store/modules/user-location/mutations'
import state from '@/store/modules/user-location/state'

export default {
    actions,
    getters,
    mutations,
    state
}