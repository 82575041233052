<template>
    <painel-component>
        <div @click="$emit('close')" class="btn-close"></div>

        <div class="__table">
            <table>
                <thead>
                    <tr>
                        <th scope="col" style="width: 40%">Nome</th>
                        <th scope="col" style="width: 40%">Dia Da Semana | Dia Fechamento</th>
                        <th scope="col" style="width: 10%">Liga / Desliga</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in opening_hours" :key="index">
                        <td data-label="Nome">{{ data.name }}</td>
                        <td v-if="data.date_close_check == '2'" data-label="Dia Da Semana | Dia Fechamento">{{
                            data.date_close }}
                        </td>
                        <td v-else data-label="Dia Da Semana | Dia Fechamento">{{ data.days_week }}</td>
                        <td data-label="Liga / Desliga">
                            <label class="switches position-relative"
                                :class="[{ 'switches_on': data.type == 1 }, { 'switches_off': data.type == 2 }]">
                                <input disabled type="checkbox" :checked="data.type == 2">
                                <span class="slider"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.playerOpeningHours()
    },

    props: {
        id: { required: true }
    },

    name: 'NewsAppSearch',
    components: {
        PainelComponent,
    },

    data() {
        return {
            opening_hours: []
        }
    },

    methods: {

        playerOpeningHours() {
            this.$store.dispatch('playerOpeningHours', this.id)
                .then((r) => {
                    this.opening_hours = r
                })
                .catch(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                })
        }

    },

    computed: {

    }

}
</script>

<style src="@/assets/css/card.css"></style>

<style scoped>
.btn-close {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}
</style>