<template>
    <ModalComponent @submit-logout="destroyContentFile" @close-modal="closeModal" :visible="visible"
                    :message="message"/>
    <div v-if="modalInfo" class="window-modal">
        <ContentFileShow :id="idModal" @close-content-file="modalInfo = !modalInfo"/>
    </div>

    <div v-if="modalPreview" class="window-modal-preview">
        <span class="btn-close-two" @click="modalPreview = !modalPreview">X</span>
        <div class="container-modal-preview">
            <img v-if="objectPreview.type == 1" :src="objectPreview.url">
            <video v-if="objectPreview.type == 2" :src="objectPreview.url" autoplay loop controls></video>
        </div>
    </div>

    <div class="modal-create-folder-background" v-if="abrir">
        <div class="modal-create-folder">
            <painel-component :title="'Arquivos Selecionados'">
                <div class="modal-folder-close" @click="closeUploadFileModal">X</div>
                <div class="container">
                    <form>
                        <div>
                            <label @click="cleanUploadFile" role="button" class="uploadButton"
                                   for="upload"
                                   tooltip="Adicionar Arquivos">
                                <input type="file" ref="uploadfiles" @change="selectFiles"
                                       id="upload" multiple
                                       data-max-file-size="30MB" accept="image/*,video/*">
                                <img src="@/assets/icones/plus.svg">
                            </label>
                        </div>
                        <div class="files">
                            <ul>
                                <li v-for="(data, index) in files_computed" :key="index"
                                    class="d-flex align-items-center justify-content-between">

                                    <div class="w-50 d-flex">
                                        <div class="text-truncate w-75">
                                            {{ data.file.name }}
                                        </div>
                                        <span class="file-size">{{ convertBytes(data.file.size) }}</span>
                                    </div>

                                    <div class="me-5 align-items-center justify-content-around"
                                         style="width: 30%; display: flex" :id="'progress-bar' + index">
                                        <progress :value="data.progress" max="100"></progress>
                                        {{ data.progress }}%
                                    </div>

                                    <button class="remove-file" type="button" @click="remove(index)">
                                        <img src="@/assets/icones/trash.svg" alt="">
                                    </button>

                                    <div class="file-success" :id="'file-success' + index">
                                        Sucesso ao enviar
                                        <img src="@/assets/icones/check.svg" alt="">
                                    </div>

                                    <div class="file-erro" :id="'file-erro' + index">
                                        Erro ao enviar
                                        <img src="@/assets/icones/cross_circle.svg" alt="">
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <button-one :disabled="files_computed >= 0 || sendForm" :itens="buttonOne"
                                    @click="uploadFiles"/>
                    </form>
                </div>
            </painel-component>
        </div>
    </div>

    <div class="modal-create-folder-background" v-if="modal_folder">
        <div class="modal-create-folder">
            <painel-component :title="'Editar'">
                <ErrorComponent :errors="errors" v-if="showError"/>
                <div class="modal-folder-close" @click="modal_folder = !modal_folder">X</div>
                <form>
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.name }">
                            <label>Nome:</label>
                            <input-component :type-input="'text'" v-model="formDateEdit.name"/>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4'">
                            <button-one :itens="buttonTwo" @click="updateContentFile"/>
                        </row-col-component>
                    </row-component>
                </form>
            </painel-component>
        </div>
    </div>

    <painel-component>
        <div class="header-file-explore">
            <div @click="folderRouter" class="block-icon-file">
                <img src="@/assets/icones/jump_left.svg">
                <span class="block-icon-file-text">Voltar</span>
            </div>

            <div class="input-search-container">
                <div class="input-search" @keyup.enter="submit">
                    <input type="text" v-model="formDate.name">
                    <img class="input-icon-search" src="@/assets/icones/search.svg" @click="submit">
                </div>

                <label class="button-adicionar-file" @click="abrir = !abrir" v-if="buttonCheck.create" for="upload"
                       tooltip="Enviar Arquivos">
                    <img src="@/assets/icones/upload_alt.svg">
                </label>
            </div>
        </div>
        <row-component class="mt-5 margin-top-file">
            <row-col-component :class-row-col="'col-xxl-1 col-xl-2 col-lg-3 col-md-2 col-sm-4 col-4 margin-x-col'"
                               v-for="(file, index) in content_file.data" :key="index">
                <div class="content-file-explorer">
                    <div @click="previewFile(file.urlShow, file.type)" class="file-content" :tooltip="file.name">
                        <img :src="require(`@/assets/img/${file.type}.png`)">
                        <p>{{ file.name_original }}</p>
                    </div>
                    <span class="tooltiptext">
                        <button v-if="buttonCheck.edit" @click="openModalEdit(file)" tooltip="Editar"><img
                                src="@/assets/icones/create.svg"></button>
                        <button v-if="buttonCheck.delete" @click="openModalFolder(file)" tooltip="Excluir"><img
                                src="@/assets/icones/trash.svg"></button>
                        <button v-if="buttonCheck.show" @click="fileShow(file.id)" tooltip="Informação">
                            <img src="@/assets/icones/info_circle.svg">
                        </button>
                        <button v-if="buttonCheck.show" tooltip="Visualizar"
                                @click="previewFile(file.urlShow, file.type)">
                            <img src="@/assets/icones/eye.svg">
                        </button>
                    </span>
                </div>
            </row-col-component>
        </row-component>
        <button-pagination :pagination="content_file" :offset="3" @paginate="loadContentsFiles"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent'
import ModalComponent from '@/components/modal/ModalComponent'
import ErrorComponent from '@/components/alerts/ErrorComponent'
import RowComponent from '@/components/row/RowComponent'
import RowColComponent from '@/components/row/RowColComponent'
import InputComponent from '@/components/forms/InputComponent'
import ButtonOne from '@/components/buttons/ButtonOne'
import ButtonPagination from '@/components/buttons/ButtonPagination'
import ContentFileShow from '@/views/show/ContentFileShow'
import {ACTION_MESSAGES} from '@/configs/messages'
import axios from "axios";

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Conteúdo')
        this.$store.dispatch('loadSelectCompany')
        this.checkACL()
        this.loadContentsFiles(1)
    },

    props: {
        id: {required: true}
    },

    name: 'ContentFileSearch',
    components: {
        PainelComponent,
        ModalComponent,
        ErrorComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonOne,
        ButtonPagination,
        ContentFileShow
    },

    data() {
        return {
            buttonOne: {
                icon: 'upload_alt',
                name: 'Enviar',
                type: 'button',
                tooltip: 'Enviar',
            },

            buttonTwo: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar',
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            formDate: {
                name: '',
                content_folder_id: this.id
            },

            formDateEdit: {
                id: '',
                name: ''
            },

            files: [],

            formDateUpdaloadFile: {
                file: '',
                content_folder_id: this.id
            },

            idModal: '',
            modalInfo: false,
            modalPreview: false,

            contUpload: 0,

            modal_folder: false,

            objectPreview: {},

            abrir: false,
            visible: false,
            message: '',

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            this.loadContentsFiles(1)
            this.searchSubmit = true;
        },

        loadContentsFiles(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadContentsFiles', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        cleanUploadFile() {
            this.files = []
            if (this.files_computed >= 0) {
                this.sendForm = false
            }
        },

        closeUploadFileModal() {
            this.files = []
            this.abrir = !this.abrir
            this.loadContentsFiles(1)
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                content_folder_id: this.id,
            }));
        },

        uploadFiles() {
            this.sendForm = true
            let removeFileClass = document.getElementsByClassName("remove-file");
            removeFileClass.forEach((element) => {
                element.style.display = 'none'
            });

            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    content_folder_id: this.id
                }

                axios.post("api/v1/system/content-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.loadContentsFiles(1)
                        this.sendForm = false
                    }
                }).catch((error) => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        let err = error.response.data.errors[0].toString()
                        this.loadContentsFiles(1)
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            message: err
                        })
                        this.sendForm = false
                    }
                });
            });
        },

        destroyContentFile() {
            this.visible = !this.visible
            this.$store.dispatch('destroyContentFile', this.idModal)
                .then(() => {
                    this.loadContentsFiles(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadContentsFiles(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        updateContentFile() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateContentFile', this.formDateEdit)
                    .then(() => {
                        this.modal_folder = !this.modal_folder
                        this.loadContentsFiles(1)
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.modal_folder = !this.modal_folder
                        this.loadContentsFiles(1)
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        openModalFile() {
            this.files = []
            this.abrir = !this.abrir
        },

        openModalFolder(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name_original}`
            this.idModal = data.id
        },

        openModalEdit(data) {
            this.modal_folder = !this.modal_folder
            this.formDateEdit.id = data.id
            this.formDateEdit.name = data.name_original
        },

        closeModal() {
            this.visible = !this.visible
        },

        folderRouter() {
            this.$router.push({name: 'content-search'});
        },

        fileShow(id) {
            this.idModal = id
            this.modalInfo = !this.modalInfo
        },

        previewFile(url, type) {
            this.objectPreview = {
                url: url,
                type: type
            }
            this.modalPreview = !this.modalPreview
        },

        remove(index) {
            this.files = Array.from(this.files)
            this.files.splice(index, 1)
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size + ' KB'
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size + " MB"
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 9)
                .then((r) => {
                    if (r.data.indexOf('content-file-search') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('content-file-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('content-file-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('content-file-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('content-file-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
            // "content-file-create",
            // "content-file-edit",
            // "content-file-search",
            // "content-file-delete",
            // "content-file-campaigns-run",
            // "content-file-schedule-removal"
        },

    },

    computed: {
        content_file() {
            return this.$store.state.content_file.items
        },

        params() {
            return {
                page: this.content_file.current_page,
                name: this.formDate.name,
                content_folder_id: this.formDate.content_folder_id
            }
        },

        files_computed() {
            return this.files
        },

        me() {
            return this.$store.state.auth.me
        },

        selectCompany() {
            return this.$store.state.var.selectCompany
        }

    },

}

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.header-file-explore {
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}

.block-icon-file {
    height: 100%;
    width: 100px;
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.block-icon-file:hover {
    background-color: rgb(204, 204, 204, 0.4);
}

.block-icon-file:hover {
    border-top-left-radius: 10px;
}

.input-search-container {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.input-search {
    height: 42px;
    width: 300px;
    display: flex;
    margin: 0 15px;
    position: relative;
    align-items: center;
}

.input-search > input {
    height: 42px;
    width: 300px;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #b3b3b3;
}

.input-icon-search {
    right: 10px;
    cursor: pointer;
    position: absolute;
}

.button-adicionar-file {
    padding: 0;
    width: 42px;
    height: 42px;
    display: grid;
    cursor: pointer;
    border-radius: 5px;
    place-items: center;
    background-color: #FF7700;
}

.button-adicionar-file img {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

.content-file-explorer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-content: flex-start;
}

.file-content {
    width: 100%;
    height: 145px;
    display: flex;
    margin-top: 1.5rem;
    font-size: 14px;
    position: relative;
    text-align: center;
    align-items: center;
    word-wrap: break-word;
    flex-direction: column;
    background-color: #fbfbfb;
}

.file-content > img {
    height: 80px;
}

.file-content p {
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.file-content:hover {
    cursor: pointer;
    background-color: rgb(192, 192, 192, 0.4);
}

.tooltiptext {
    top: 100%;
    left: 50%;
    z-index: 1;
    width: 100%;
    color: #fff;
    padding-bottom: 5px;
    margin-left: -50%;
    text-align: center;
    position: absolute;
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
}

.tooltiptext > button {
    border: 0;
    width: 100%;
    height: auto;
}

.modal-create-folder-background {
    z-index: 104;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.5);
}

.modal-create-folder {
    position: relative;
    height: 55%;
    width: 40%;
}

.modal-folder-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
    margin: 10px 20px;
    cursor: pointer;
}

input[type=file] {
    position: absolute;
    right: -9999px;
    visibility: hidden;
    opacity: 0;
}

input[type=button] {
    position: relative;
    padding: 1rem 3rem;
    background: #FF7700;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    border: 0;
    color: #fff;
}

.uploadButton {
    line-height: 20px;
    padding: 0.6rem;
    height: 42px;
    background: #FF7700;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out
}

label > img {
    vertical-align: bottom;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

div.files {
    padding: 1rem 0;
    margin: 1rem 0;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow: auto;
}

div.files ul li {
    width: 99%;
    background: #eee;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
}

div.files ul li button {
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
}

div.container {
    max-width: 100%;
    padding: 0;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: rgb(0, 0, 0, 0.7);
}

.window-modal-preview {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .7);
}

.window-modal-preview .container-modal-preview img,
.window-modal-preview .container-modal-preview video {
    background-color: rgba(0, 0, 0, .7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, .7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, .7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.window-modal > div {
    width: 95%;
    margin: 14px auto;
}

.btn-close-two {
    top: 0%;
    right: 0%;
    margin: 20px;
    z-index: 15;
    color: #000;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    display: grid;
    place-items: center;
    border-radius: 50%;
    position: absolute;
    background-color: #eee;
}

@keyframes Rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.file-success > img {
    vertical-align: bottom;
    filter: invert(50%) sepia(99%) saturate(426%) hue-rotate(74deg) brightness(96%) contrast(87%);
}

.file-erro > img {
    vertical-align: bottom;
    filter: invert(23%) sepia(80%) saturate(2715%) hue-rotate(352deg) brightness(95%) contrast(81%)
}

.file-success,
.file-erro {
    display: none;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
}

.spinner {
    display: none;
    margin: 0.5rem;
    width: 25px;
    height: 25px;
    border: 4px solid lightgray;
    border-top-color: gray;
    animation: Rotate 1.2s linear 0s infinite forwards;
    position: absolute;
    top: 0;
    right: 0;
}
</style>

<style scoped>
@media (max-width: 1366px) {
    .input-search-container{
        width: 40%;
    }
}

@media (max-width: 1280px) {

    .input-search-container {
        width: 40%;
    }

    .margin-top-file {
        margin-top: 3rem !important;
    }

}

@media (max-width: 1024px) {

    .input-search-container {
        width: 60%;
    }

    .margin-top-file {
        margin-top: 3rem !important;
    }

}

@media (max-width: 991px) {
    .modal-create-folder {
        width: 70%;
    }
}

@media (max-width: 500px) {
    .header-file-explore {
        height: 120px;
        flex-wrap: wrap;
        padding: 0 15px;
        justify-content: flex-end;
    }

    .block-icon-file {
        top: 0;
        left: 0;
        width: 50px;
        height: auto;
        margin: 5px 0;
        position: absolute;
    }

    .block-icon-file img {
        width: 100%;
        height: auto;
    }

    .block-icon-file-text {
        display: none;
    }

    .input-search-container {
        width: 90%;
        height: 50%;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .input-search input,
    .input-search {
        margin: 10px 0;
        width: 100%;
    }

    .block-icon-file:hover {
        background-color: transparent;
    }

    .margin-top-file {
        margin-top: 10rem !important;
    }

    .margin-x-col {
        margin: 0;
    }

    .modal-create-folder {
        width: 100%;
    }

}

progress {
    border-radius: 0;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #FF7700;
}
</style>