<template>
    <painel-component :title="'Editar'">
        <form>
            <div class="container-avatar-user">
                <img v-if="formDate.photo" :src="this.imagePreview">
                <img v-else src="@/assets/img/avatar.png">
                {{ formDate.nameClient }}
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Foto:</label>
                    <label for="input-photo" class="input-select-photo-label-views">
                        <p>{{ this.namePhoto }}</p>
                        <span class="input-photo-icone"><img src="@/assets/icones/camera.svg"></span>
                        <input @change="onFileChange" id="input-photo" class="input-select-photo" type="file">
                    </label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add @click="routeCompanyUpdate" style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';

import ButtonAdd from '@/components/buttons/ButtonAdd';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Empresa')
        this.loadCompany()
    },

    props: {
        id: {required: true}
    },

    name: 'CompanyUpdatePhoto',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        ButtonAdd,
    },

    data() {
        return {
            buttonAdd: {
                route: '',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            formDate: {
                photo: '',
                nameClient: '',
                id: this.id,
            },

            imagePreview: '',
            namePhoto: ''
        }
    },

    methods: {
        routeCompanyUpdate() {
            this.$router.push({name: 'company-edit', params: {id: this.id}})
        },

        loadCompany() {
            this.$store.dispatch('updateNamePage', 'Empresa')
            this.$store.dispatch('loadCompany', this.id).then((r) => {
                this.formDate.photo = r.photo;
                this.formDate.nameClient = r.name
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        submit() {
            this.$store.dispatch('updateCompanyPhoto', this.formDate)
                .then(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                })
                .catch(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                })
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.formDate.photo = files[0]
            this.namePhoto = this.formDate.photo.name
            if (files[0].type.indexOf('image') == 0)
                this.previewImage(files[0])
            this.submit()
        },

        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result
            }
            reader.readAsDataURL(file)
        },

        photoPreviewTimer() {
            this.imagePreview = this.formDate.photo
        }

    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },

}
</script>

<style scoped>
.container-avatar-user {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    justify-content: flex-start;
    height: 130px;
    width: 100%;
}

.container-avatar-user > img {
    border-radius: 50%;
    margin-right: 15px;
    width: 100px;
    min-height: 100px;
}

.input-select-photo-label-views {
    width: 100%;
    height: 42px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #b3b3b3;
}

.input-photo-icone {
    top: -1px;
    right: -1px;
    bottom: unset;
}

@media (max-width: 991px) {
    .container-avatar-user {
        margin: 5% 0;
        flex-direction: column;
        align-items: center;
    }
}
</style>
  