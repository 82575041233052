<template>
    <painel-component :title="'Ver'">
        <ErrorComponent :errors="errors" v-if="showError" />
        <button-one class="btn-preview" :itens="buttonTwo" @click="routePreview(formDate.urlPreview)" />

        <form>
            <row-component class="mb-3">
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <Carousel :model-value="parseInt(formDate.layout)" :settings="settings" :items-to-show="3"
                        :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(thumbs, index) in thumbsnail" :key="index">
                            <div class="carousel__item">
                                <input disabled :value="index" v-model="formDate.layout" type="radio" name="thumbs"
                                    :id="'thumbs' + index">
                                <label :for="'thumbs' + index"><img :src="thumbs" /></label>
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome do APP:</label>
                    <input-component disabled :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect disabled v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.time }">
                    <label>Tempo exibição em segundos:</label>
                    <input-component disabled :type-input="'number'" v-model="formDate.time" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.company_id }">
                    <label>Empresa:</label>
                    <Multiselect disabled label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                        :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne'
import { ACTION_MESSAGES } from '@/configs/messages';
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
    created() {
        this.$store.commit('PRELOADER', true)
        this.loadLotteryApp()
        this.checkACL()
    },

    name: 'LotteryAppShow',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        Carousel,
        Slide,
        Navigation,
    },

    props: {
        id: { required: true }
    },

    data() {
        return {
            buttonAdd: {
                route: '/lottery-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonTwo: {
                icon: 'airplay',
                type: 'button',
                tooltip: 'Pré visualizar'
            },

            thumbsnail: [
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/dupla_sena.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/loteria_sorte.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/loto_facil.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/loto_mania.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/quina.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/super_sete.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/time_mania.jpg',
                'https://methoduz.s3.amazonaws.com/system/app/loteria/thumbsnail/mega_sena.jpg'
            ],

            breakpoints: {
                100: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },

            settings: {
                itemsToShow: 0,
                modelValue: 3,
                snapAlign: 'center',
            },

            formDate: {
                id: '',
                layout: '',
                name: '',
                time: '',
                status: '',
                company_id: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        routePreview(url) {
            window.open(url, '_blank', 'noreferrer');
        },

        loadLotteryApp() {
            this.$store.dispatch('updateNamePage', 'App Loteria')
            this.$store.dispatch('loadSelectCompanyRestrict')
            this.$store.dispatch('loadLotteryApp', this.id)
                .then((r) => {
                    this.formDate = r
                    this.formDate.id = this.id
                })
                .catch(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('app-lottery-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },
    }

}
</script>

<style scoped>
.btn-preview {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="radio"][id^="cb"] {
    display: none;
}

.carousel__item label {
    padding: 0 10px;
    cursor: pointer;
    width: 100%;
}

.carousel__item label img {
    height: auto;
    width: 100%;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.carousel__item label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 0%;
    left: 5%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 22px;
    z-index: 1;
    transition-duration: 0.4s;
    transform: scale(0);
}

.carousel__item input:checked+label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
}

.carousel__item input[type="radio"]+label::after {
    display: none;
}

.carousel__item input:checked+label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

[tooltip]::after {
    font-size: 12px;
}
</style>
