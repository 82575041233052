import menuCampanha from "@/configs/linkMenus/modules/menuCampanha";
import menuLocal from "@/configs/linkMenus/modules/menuLocal";
import menuPlayer from "@/configs/linkMenus/modules/menuPlayer";
import menuCadastro from "@/configs/linkMenus/modules/menuCadastro";
import menuAplicativo from "@/configs/linkMenus/modules/menuAplicativo";
import menuConteudo from "@/configs/linkMenus/modules/menuConteudo";
import menuEmpresa from "@/configs/linkMenus/modules/menuEmpresa";
import menuTutorial from "@/configs/linkMenus/modules/menuTutorial";

export default {
    menuCampanha,
    menuLocal,
    menuPlayer,
    menuCadastro,
    menuAplicativo,
    menuConteudo,
    menuEmpresa,
    menuTutorial
}