<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>
        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonThree" @click="routeAdd"/>

        <div class="modal-add-items" v-if="modalAddItems">
            <NoticeAppItemsAdd :id="id" :size="formDate.itens.length" @close="closeModalAddItems"/>
        </div>

        <div class="modal-add-items" v-if="modalUpdateItems">
            <NoticeAppItemsUpdate :id="idItemsHTML" :idapp="id" :size="formDate.itens.length"
                                  @close="closeModalUpdatetems"/>
        </div>

        <div class="modal-folder" v-if="modalContent">
            <NoticeAppContentSearch @close-folder="closeFolderModal" :id="id" :logo_background="logo_background"/>
        </div>

        <div v-if="iframePreview" class="window-modal">
            <div @click="iframePreview = !iframePreview" class="btn-close-preview">X</div>
            <img v-if="objectPreview.type == 2" :src="objectPreview.show_url"/>
            <video v-if="objectPreview.type == 3" :src="objectPreview.show_url" autoplay loop controls></video>
        </div>

        <div v-if="previewBGLogo" class="window-modal">
            <div @click="previewBGLogo = !previewBGLogo" class="btn-close-preview">X</div>
            <img :src="imgBGLogo"/>
        </div>

        <div class="modal-create-folder-background" v-if="abrir">
            <div class="modal-create-folder">
                <painel-component :title="'Arquivos Selecionados'">
                    <div class="modal-folder-close" @click="closeUploadFileModal">X</div>
                    <div class="container">
                        <form>
                            <div>
                                <label @click="cleanUploadFile" class="uploadButton" for="upload"
                                       tooltip="Adicionar Arquivos">
                                    <input type="file" ref="upload-files" @change="selectFiles" id="upload" multiple
                                           data-max-file-size="30MB" accept="image/*,video/*">
                                    <img src="@/assets/icones/plus.svg" alt="">
                                </label>
                            </div>
                            <div class="files">
                                <ul>
                                    <li v-for="(data, index) in files_computed" :key="index"
                                        class="d-flex align-items-center justify-content-between">

                                        <div class="w-50 d-flex">
                                            <div class="text-truncate w-75">
                                                {{ data.file.name }}
                                            </div>
                                            <span class="file-size">{{ convertBytes(data.file.size) }}</span>
                                        </div>

                                        <div class="me-5 align-items-center justify-content-around"
                                             style="width: 30%; display: flex" :id="'progress-bar' + index">
                                            <progress :value="data.progress" max="100"></progress>
                                            {{ data.progress }}%
                                        </div>

                                        <button class="remove-file" type="button" @click="remove(index)">
                                            <img src="@/assets/icones/trash.svg" alt="">
                                        </button>

                                        <div class="file-success" :id="'file-success' + index">
                                            Sucesso ao enviar
                                            <img src="@/assets/icones/check.svg" alt="">
                                        </div>

                                        <div class="file-erro" :id="'file-erro' + index">
                                            Erro ao enviar
                                            <img src="@/assets/icones/cross_circle.svg" alt="">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <button-one :disabled="files_computed >= 0 || sendForm" :itens="buttonFile"
                                        @click="uploadFiles"/>
                        </form>
                    </div>
                </painel-component>
            </div>
        </div>

        <div class="container-tabs">
            <div class="card tabs">
                <input id="tab-1" type="radio" class="tab tab-selector" name="tab" checked="checked"/>
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/info_circle.svg"></i>
                    <span>Informações</span>
                </label>
                <input id="tab-2" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/settings.svg"></i>
                    <span>Conteúdo</span>
                </label>
                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-10 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.name }">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formDate.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.status }">
                                    <label>Status:</label>
                                    <multiselect v-model="formDate.status" :searchable="true"
                                                 :options="selectActiveInactive"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-10 col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.company_id }">
                                    <label>Empresa:</label>
                                    <Multiselect label="name" v-model="formDate.company_id"
                                                 :options="selectCompanyRestrict"
                                                 :searchable="true"
                                                 :noResultsText="this.$store.state.var.selectNotSearch">
                                        <template v-slot:singlelabel="{ value }">
                                            <div class="multiselect-single-label">
                                                <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                                            </div>
                                        </template>

                                        <template v-slot:option="{ option }">
                                            <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                                        </template>
                                    </Multiselect>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-4 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.time }">
                                    <label>Tempo exibição em segundos:</label>
                                    <input-component :type-input="'number'" v-model="formDate.time"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.spot_id }">
                                    <label>Localização:</label>
                                    <multiselect label="name" v-model="formDate.spot_id" :searchable="true"
                                                 :options="selectSpot"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.content_folder_id }">
                                    <label>Pasta Conteúdo:</label>
                                    <multiselect disabled v-model="formDate.content_folder_id" :searchable="true"
                                                 :options="selectContentFolder"
                                                 :noResultsText="'Nenhuma pasta encontrada'"
                                                 :noOptionsText="'Nenhuma pasta encontrada'"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.city_id }">
                                    <label>Cidade Clima:</label>
                                    <multiselect v-model="formDate.city_id" :searchable="true"
                                                 :options="selectCityClimate"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne"
                                                @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>
                        </form>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <h1>Background</h1>
                                <hr>
                                <row-component>
                                    <row-col-component v-if="!formDate.background_one"
                                                       :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                        <div class="alert alert-secondary" role="alert">
                                            Nenhum background encontrado
                                        </div>
                                    </row-col-component>

                                    <row-col-component v-else
                                                       :class-row-col="'col-xxl-6 col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12'">
                                        <div class="file-content">
                                            <label @click="previewContentBGLogo(formDate.background_url)">
                                                <p>
                                                    <img src="@/assets/img/1.png" alt="Image">
                                                    {{ formDate.background_name }}
                                                </p>
                                            </label>
                                            <div class="action-buttons">
                                                <button @click="previewContentBGLogo(formDate.background_url)"
                                                        tooltip="Visualizar">
                                                    <img src="@/assets/icones/eye.svg" alt="Visualizar"/>
                                                </button>
                                                <button @click="addBackgroundLogo(1)"
                                                        tooltip="Editar">
                                                    <img src="@/assets/icones/create.svg" alt="Editar"/>
                                                </button>
                                            </div>
                                        </div>
                                    </row-col-component>

                                    <row-col-component v-if="!formDate.background_one"
                                                       :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-2'">
                                        <button-one :itens="buttonFour" @click="addBackgroundLogo(1)"/>
                                    </row-col-component>
                                </row-component>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <h1>Logo</h1>
                                <hr>

                                <row-component>
                                    <row-col-component v-if="!formDate.logo_one"
                                                       :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                                        <div class="alert alert-secondary" role="alert">
                                            Nenhum logo encontrado
                                        </div>
                                    </row-col-component>

                                    <row-col-component v-else
                                                       :class-row-col="'col-xxl-6 col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12'">
                                        <div class="file-content">
                                            <label @click="previewContentBGLogo(formDate.logo_one_url)">
                                                <p>
                                                    <img src="@/assets/img/1.png" alt="Image">
                                                    {{ formDate.logo_one_name }}
                                                </p>
                                            </label>
                                            <div class="action-buttons">
                                                <button @click="previewContentBGLogo(formDate.logo_one_url)"
                                                        tooltip="Visualizar">
                                                    <img src="@/assets/icones/eye.svg" alt="Visualizar"/>
                                                </button>
                                                <button @click="addBackgroundLogo(2)"
                                                        tooltip="Editar">
                                                    <img src="@/assets/icones/create.svg" alt="Editar"/>
                                                </button>
                                            </div>
                                        </div>
                                    </row-col-component>

                                    <row-col-component v-if="!formDate.logo_one"
                                                       :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-2'">
                                        <button-one :itens="buttonFour" @click="addBackgroundLogo(2)"/>
                                    </row-col-component>
                                </row-component>
                            </row-col-component>
                        </row-component>

                    </div>

                    <div class="item" id="content-2">
                        <div class="actions-button-notice">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button-one :itens="buttonGroup.buttonBackground"
                                            @click="modalAddItems = !modalAddItems"/>
                                <button-one :itens="buttonGroup.buttonItens" @click="abrir = !abrir"/>
                                <button-one :itens="buttonGroup.buttonSynchronize" @click="submitSynchronize"/>
                                <button-one :itens="buttonGroup.buttonRemove"
                                            :disabled="notice_itens_delete.itens_ids.length >= 1"
                                            @click="notice_itens_delete_btn = !notice_itens_delete_btn"/>
                            </div>
                        </div>

                        <row-component class="mt-5">
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12 mt-2'"
                                    v-for="(item, index) in formDate.itens" :key="index">
                                <div class="file-content" :class="'border-top-type-'+item.type"
                                     v-if="notice_itens_delete_btn">
                                    <input :disabled="!notice_itens_delete_btn || item.type == 1" :value="item.id"
                                           v-model="notice_itens_delete.itens_ids" type="checkbox"
                                           :id="'thumbs' + index">
                                    <label :for="'thumbs' + index">
                                        <p><img :src="require(`@/assets/icon-avisos/${item.type}.png`)"> {{ item.name }}
                                        </p>
                                    </label>
                                </div>

                                <div class="file-content" :class="'border-top-type-'+item.type" v-else>
                                    <label :for="'thumbs' + index">
                                        <p><img :src="require(`@/assets/icon-avisos/${item.type}.png`)"> {{ item.name }}
                                        </p>
                                    </label>

                                    <div class="action-buttons">
                                        <div>
                                            <img :class="[{
                                                 'bg-duplicate-ac': item.synced == 2,
                                                 'bg-duplicate-dc': item.synced == 1,
                                                  },]" src="@/assets/icones/refresh.svg"/>
                                        </div>
                                        <button @click="previewContent(item)" tooltip="Visualizar">
                                            <img src="@/assets/icones/eye.svg" alt=""/>
                                        </button>
                                        <button @click="closeModalUpdatetems(item.id)" tooltip="Editar"
                                                v-if="item.type == 4">
                                            <img src="@/assets/icones/create.svg" alt=""
                                            />
                                        </button>
                                    </div>
                                </div>

                            </row-col-component>
                        </row-component>

                        <div v-if="notice_itens_delete_btn" class="button-delete-div">
                            <button :disabled="notice_itens_delete.itens_ids == 0" @click="storeNoticeAppDelete"
                                    :class="{ 'btn-disabled': notice_itens_delete.itens_ids.length >= 1 }"
                                    class="btn btn-delete" tooltip="Remover">
                                <img src="@/assets/icones/trash.svg"/> Deletar
                            </button>
                        </div>
                    </div>

                </section>

            </div>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import {ACTION_MESSAGES} from '@/configs/messages';
import NoticeAppItemsAdd from '@/views/create/NoticeAppItemsAdd';
import NoticeAppItemsUpdate from "@/views/update/NoticeAppItemsUpdate";
import NoticeAppContentSearch from "@/views/search/NoticeAppContentSearch";
import axios from "axios";

export default {
    created() {
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('updateNamePage', 'Avisos')
        this.$store.dispatch('loadSelectCityClimate')
        this.$store.dispatch('loadSelectSpot')
        this.$store.commit('PRELOADER', true)
        this.loadNoticeApp()
        this.checkACL()
    },

    props: {
        id: {required: true}
    },

    name: 'NoticeAppUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        NoticeAppItemsAdd,
        NoticeAppContentSearch,
        NoticeAppItemsUpdate
    },

    data() {
        return {
            buttonAdd: {
                route: '/notice-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                type: 'button',
                icon: 'floppy',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonFile: {
                type: 'button',
                icon: 'upload_alt',
                name: 'Enviar',
                tooltip: 'Enviar'
            },

            buttonThree: {
                type: 'button',
                icon: 'plus',
                tooltip: 'Adicionar'
            },

            buttonFour: {
                type: 'button',
                icon: 'picture',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            abrir: false,
            contUpload: 0,
            modalAddItems: false,
            modalUpdateItems: false,
            idItemsHTML: '',

            modalContent: false,
            logo_background: '',

            buttonGroup: {
                buttonBackground: {
                    icon: 'list_add',
                    type: 'button',
                    tooltip: 'Avisos'
                },

                buttonItens: {
                    icon: 'picture',
                    type: 'button',
                    tooltip: 'Adicionar Itens'
                },

                buttonSynchronize: {
                    icon: 'refresh',
                    type: 'button',
                    tooltip: 'Sincronizar'
                },

                buttonRemove: {
                    icon: 'trash',
                    type: 'button',
                    tooltip: 'Remover'
                },
            },

            formDate: {
                id: this.id,
                name: '',
                time: '',
                status: '',
                company_id: '',
                spot_id: '',
                city_id: '',
                content_folder_id: '',
                itens: []
            },

            synchronize: {
                app_id: this.id
            },

            notice_itens_delete: {
                app_id: this.id,
                itens_ids: []
            },

            files: [],

            notice_itens_delete_btn: false,

            formDateFile: [],

            formDateUpdaloadFile: {
                file: '',
                app_id: this.id
            },

            iframePreview: false,
            objectPreview: "",

            previewBGLogo: false,
            imgBGLogo: '',

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateNoticeApp', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        routeAdd() {
            this.$router.push({name: 'notice-app-create'})
        },

        addBackgroundLogo(n) {
            this.logo_background = n
            this.modalContent = true
        },

        previewContent(element) {
            this.iframePreview = !this.iframePreview;
            this.objectPreview = element;

            if (this.objectPreview.type == 4 || this.objectPreview.type == 1) {
                this.iframePreview = false;
                window.open(this.objectPreview.show_url, "_blank");
            }
        },

        previewContentBGLogo(element) {
            this.previewBGLogo = !this.previewBGLogo;
            this.imgBGLogo = element
        },

        closeModalAddItems() {
            this.modalAddItems = false
            this.loadNoticeApp()
        },

        closeModalUpdatetems(id) {
            this.idItemsHTML = id
            this.modalUpdateItems = !this.modalUpdateItems
            this.loadNoticeApp()
        },

        closeFolderModal(n) {
            this.modalContent = !this.modalContent
            if (n == 1) {
                this.modalContent = false
                this.loadNoticeApp()
            }
        },

        loadSelectContentFolder(id) {
            this.formDate.content_folder_id = ''
            this.$store.dispatch('loadSelectContentFolder', id)
            this.content_input = false
        },

        loadSelectContentFolderClear() {
            this.formDate.content_folder_id = ''
            this.content_input = true
        },

        loadNoticeApp() {
            this.$store.dispatch("loadNoticeApp", this.id)
                .then((r) => {
                    this.formDate = r;
                    this.formDate.id = this.id
                    this.$store.dispatch('loadSelectContentFolder', r.company_id)
                })
                .catch(() => {
                    this.$store.commit("addToast", ACTION_MESSAGES.errorDefault);
                });
        },

        remove(index) {
            this.files = Array.from(this.files)
            this.files.splice(index, 1)
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size + ' KB'
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size + " MB"
            }
        },

        cleanUploadFile() {
            this.files = []
            if (this.files_computed >= 0) {
                this.sendForm = false
            }
        },

        closeUploadFileModal() {
            this.files = []
            this.abrir = !this.abrir
            this.loadNoticeApp()
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({
                file,
                app_id: this.id,
            }));
        },

        uploadFiles() {
            this.sendForm = true
            let removeFileClass = document.getElementsByClassName("remove-file");
            removeFileClass.forEach((element) => {
                element.style.display = 'none'
            });

            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    app_id: this.id
                }

                axios.post("api/v1/system/app/notice-elevator-add-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.loadNoticeApp(1)
                        this.sendForm = false
                    }
                }).catch(() => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.loadNoticeApp(1)
                        this.sendForm = false
                    }
                });
            });
        },

        submitSynchronize() {
            this.$store.dispatch('storeNoticeAppSynchronize', this.synchronize)
                .then(() => {
                    this.showError = false;
                    this.sendForm = false
                    this.errors = [];
                    this.$store.commit('addToast', {
                        title: 'Sucesso!',
                        type: 'success',
                        message: 'Avisos Sincronizado com sucesso'
                    })
                    this.loadNoticeApp()
                })
                .catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.$store.commit('addToast', {
                        title: 'Oops!',
                        type: 'error',
                        message: 'Erro ao sincronizar!'
                    })
                    this.loadNoticeApp()
                })
        },

        storeNoticeAppDelete() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeNoticeAppDelete', this.notice_itens_delete)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', {
                            title: 'Sucesso!',
                            type: 'success',
                            message: 'Avisos deletado com sucesso'
                        })
                        this.loadNoticeApp()
                        this.notice_itens_delete.itens_ids = []
                        this.notice_itens_delete_btn = !this.notice_itens_delete_btn
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            message: 'Erro ao deletar!'
                        })
                        this.loadNoticeApp()
                        this.notice_itens_delete.itens_ids = []
                        this.notice_itens_delete_btn = !this.notice_itens_delete_btn
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 17)
                .then((r) => {
                    if (r.data.indexOf('app-notice-elevator-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('app-notice-elevator-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectCityClimate() {
            return this.$store.state.var.selectCityClimate
        },

        selectSpot() {
            return this.$store.state.var.selectSpot
        },

        selectContentFolder() {
            return this.$store.state.var.selectContentFolder
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        files_computed() {
            return this.files
        },
    }

}
</script>

<style scoped>

.border-top-type-1 {
    border-top: 2px solid black;
}

.border-top-type-2 {
    border-top: 2px solid #d67c1c;
}

.border-top-type-3 {
    border-top: 2px solid var(--green-color-default);
}

.add-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.tabs > input[type="radio"] + label {
    width: 50%;
}

.glider {
    width: 50%;
}

#tab-2:checked ~ .glider {
    left: 50%;
}

.actions-button-notice {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.file-content {
    width: 100%;
    height: 60px;
    display: flex;
    color: black;
    padding: 15px 0px;
    position: relative;
    align-items: center;
    background: #ebebeb;
    justify-content: space-between;
}

.file-content p {
    font-size: 1rem;
}

.modal-add-items,
.modal-create-folder-background,
.modal-folder {
    z-index: 90;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgb(0, 0, 0, 0.5);
}

.modal-add-items > div {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60%;
    width: 60%;
}

.modal-create-folder {
    position: relative;
    height: 55%;
    width: 40%;
}

.modal-folder-close {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.5rem;
    margin: 10px 20px;
    cursor: pointer;
}

.modal-folder > div {
    width: 65%;
    height: 75%;
}

input[type=file] {
    position: absolute;
    right: -9999px;
    visibility: hidden;
    opacity: 0;
}

input[type=button] {
    position: relative;
    padding: 1rem 3rem;
    background: #FF7700;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-radius: 5px;
    border: 0;
    color: #fff;
}

.uploadButton {
    line-height: 20px;
    padding: 0.6rem;
    height: 42px;
    background: #FF7700;
    border-radius: 5px;
    border: 0;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.5s ease-out
}

label > img {
    vertical-align: bottom;
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

div.files {
    padding: 1rem 0;
    margin: 1rem 0;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow: auto;
}

div.files ul li {
    width: 99%;
    background: #eee;
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
}

div.files ul li button {
    border: 0;
    outline: none;
    position: absolute;
    right: 0;
    margin-right: 0.5rem;
}

div.container {
    max-width: 100%;
    padding: 0;
}

span.file-size {
    color: #999;
    padding-left: 0.5rem;
}

@keyframes Rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.file-success > img {
    vertical-align: bottom;
    filter: invert(50%) sepia(99%) saturate(426%) hue-rotate(74deg) brightness(96%) contrast(87%);
}

.file-erro > img {
    vertical-align: bottom;
    filter: invert(23%) sepia(80%) saturate(2715%) hue-rotate(352deg) brightness(95%) contrast(81%)
}

.file-success,
.file-erro {
    display: none;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
}

.spinner {
    display: none;
    margin: 0.5rem;
    width: 25px;
    height: 25px;
    border: 4px solid lightgray;
    border-top-color: gray;
    animation: Rotate 1.2s linear 0s infinite forwards;
    position: absolute;
    top: 0;
    right: 0;
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.window-modal > img,
.window-modal > video {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    display: block;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.btn-close-preview {
    top: 0;
    right: 5%;
    margin: 2%;
    color: white;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
}

/* *************************** */

input[type="checkbox"][id^="thumbs"] {
    display: none;
}

.file-content label {
    cursor: pointer;
    width: 100%;
}

.file-content label p img {
    height: auto;
    width: 50px;
    margin: 0 10px 0 5px;
}

.file-content label p {
    white-space: nowrap;
    width: 85%;
    overflow: hidden; /* "overflow" value must be different from "visible" */
    text-overflow: ellipsis;
}

.file-content label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 17px;
    z-index: 1;
    transition-duration: 0.4s;
    transform: scale(0);
}

.file-content input:checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
}

.file-content input[type="radio"] + label::after {
    display: none;
}

.btn-delete.btn:disabled,
.btn-delete:not([disabled]):not(.disabled).active,
.btn-delete:not([disabled]):not(.disabled):active,
.btn-delete:not([disabled]):not(.disabled).hover,
.btn-delete:not([disabled]):not(.disabled):hover {
    opacity: none;
    color: #fff !important;
    border-color: none !important;
    background-color: rgb(135 135 135 / 50%) !important;
}

.btn-disabled {
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-disabled.btn-delete:not([disabled]):not(.disabled).active,
.btn-disabled.btn-delete:not([disabled]):not(.disabled):active,
.btn-disabled.btn-delete:not([disabled]):not(.disabled).hover,
.btn-disabled.btn-delete:not([disabled]):not(.disabled):hover {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: none !important;
}

.btn-delete {
    color: white;
    float: right;
    height: 42px;
    min-width: 42px;
    line-height: 20px;
    border-color: none !important;
    background-color: rgb(135 135 135 / 50%);
}

.btn-delete img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(102%) contrast(101%);
}

.button-delete-div {
    right: 0;
    bottom: 0;
    position: absolute;
}

.action-buttons {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
}

.action-buttons > button {
    border: 0;
    width: 55px;
    padding: 5px;
    height: 100%;
    background: #ebebeb;
}

.action-buttons > div {
    border: 0;
    width: 30px;
    padding: 5px;
    display: grid;
    place-items: center;
    background: #ebebeb;
}

.bg-duplicate-ac {
    filter: invert(47%) sepia(97%) saturate(470%) hue-rotate(75deg) brightness(102%) contrast(84%);
}

.bg-duplicate-dc {
    filter: invert(94%) sepia(2%) saturate(46%) hue-rotate(323deg) brightness(88%) contrast(90%);
}
</style>

<style scoped>

@media (max-width: 1366px) {
    .modal-folder > div {
        width: 85%;
        height: 85%;
    }
}

@media (max-width: 1280px) {
    .file-content label p {
        width: 75%;
    }
}

@media (max-width: 991px) {
    .file-content p {
        text-align: start;
        -webkit-line-clamp: 2;
    }

    .modal-add-items > div {
        height: auto;
        width: 98%;
    }

    .container-tabs {
        margin-top: 10%;
    }

    label {
        padding-right: 0;
    }

    .modal-folder > div {
        width: 95%;
        height: 95%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal-create-folder {
        width: 99%;
    }
}

@media (max-width: 425px) {
    .file-content {
        text-align: center;
        align-items: center;
        width: 100%;
        height: 60px;
    }

    .file-content img {
        width: 60%;
    }

    .button-add-bottom {
        bottom: unset;
    }

    .content-file-explorer {
        justify-content: space-between;
    }
}

progress {
    border-radius: 0;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #FF7700;
}
</style>
