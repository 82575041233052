import AdminComponent from '@/components/dashboard/AdminComponent'
import MenuTableSearch from '@/views/search/MenuTableSearch'
import MenuTableCreate from '@/views/create/MenuTableCreate'
import MenuTableUpdate from '@/views/update/MenuTableUpdate'
import MenuTableShow from '@/views/show/MenuTableShow'

export default {
    path: '/menu-table-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'menu-table-app-search', component: MenuTableSearch },
        { path: 'create', name: 'menu-table-app-create', component: MenuTableCreate },
        { path: 'edit/:id', name: 'menu-table-app-edit', component: MenuTableUpdate, props: true },
        { path: 'show/:id', name: 'menu-table-app-show', component: MenuTableShow, props: true },
    ]
}