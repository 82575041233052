import AdminComponent from '@/components/dashboard/AdminComponent'
import CampaignSearch from '@/views/search/CampaignSearch'
import CampaignCreate from '@/views/create/CampaignCreate'
import CampaignUpdate from '@/views/update/CampaignUpdate'

export default {
    path: '/campaign',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'campaign-search', component: CampaignSearch },
        { path: 'create', name: 'campaign-create', component: CampaignCreate },
        { path: 'edit/:id', name: 'campaign-edit', component: CampaignUpdate, props: true }
    ]
}