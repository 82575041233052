<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd"/>

        <form>
            <div class="container-avatar-user">
                <img v-if="formDate.photo" :src="formDate.photo">
                <img v-else src="@/assets/img/avatar.png">
                {{ formDate.name }}
                <router-link class="link-update-photo" :to="{ name: 'company-edit-photo', params: { id: this.id } }">
                    <img src="@/assets/icones/camera.svg">
                </router-link>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                                 :options="selectActiveInactive" v-model="formDate.status"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" :type-input="'text'" v-model="formDate.cep"
                                     @change="searchApiCEP"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.city }">
                    <label>Cidade:</label>
                    <input-component :type-input="'text'" v-model="formDate.city"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.state }">
                    <label>Estado:</label>
                    <input-component :type-input="'text'" v-model="formDate.state"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>País:</label>
                    <input-component :type-input="'text'" v-model="formDate.country"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Refêrencia:</label>
                    <input-text-area :rows="4" v-model="formDate.complement"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import InputTextArea from '@/components/forms/InputTextArea';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import {mask} from 'vue-the-mask'
import {ACTION_MESSAGES} from '@/configs/messages'
import {ESTADOS} from '@/configs/constants'

export default {
    created() {
        this.loadCompany()
        this.$store.commit('PRELOADER', true)
    },

    props: {id: {required: true}},

    name: 'CompanyUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        InputTextArea,
        Multiselect,
        ButtonOne,
        ButtonAdd,
    },

    directives: {mask},

    data() {
        return {
            buttonAdd: {
                route: '/company/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonTwo: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                name: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        loadCompany() {
            this.$store.dispatch('updateNamePage', 'Empresa')
            this.$store.dispatch('loadCompany', this.id).then((r) => {
                this.formDate = r;
                this.checkACL()
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        routeAdd() {
            this.$router.push({name: 'company-create'})
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 4)
                .then((r) => {
                    if (r.data.indexOf('company-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('company-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateCompany', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        resetFormAddresse() {
            this.formDate.city = ''
            this.formDate.state = ''
            this.formDate.district = ''
            this.formDate.address = ''
            this.formDate.complement = ''
            this.formDate.country = ''
        },

        searchApiCEP() {
            this.resetFormAddresse()
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dataCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dataCEP)
                .then((r) => {
                    this.formDate.city = r.data.localidade
                    this.formDate.state = ESTADOS[r.data.uf]
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.country = 'Brasil'
                    this.formDate.complement = r.data.complemento
                }).catch(() => {
            })
        },
    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}

</script>

<style scoped>
.container-avatar-user {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    justify-content: flex-start;
    height: 130px;
    width: 100%;
}

.link-update-photo {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 6%;
}

.container-avatar-user > img {
    border-radius: 50%;
    margin-right: 15px;
    width: 100px;
}

.add-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

@media (max-width: 991px) {
    .container-avatar-user {
        margin: 5% 0;
        flex-direction: column;
        align-items: center;
    }

    .link-update-photo {
        top: 75px;
        right: -10%;
    }

}

@media (max-width: 425px) {

    .link-update-photo {
        top: 55%;
        left: 15%;
    }

}
</style>