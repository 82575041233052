import AdminComponent from '@/components/dashboard/AdminComponent';
import ObituaryLocationAppSearch from "@/views/search/ObituaryLocationAppSearch.vue";
import ObituaryLocationAppCreate from "@/views/create/ObituaryLocationAppCreate";
import ObituaryLocationAppUpdate from "@/views/update/ObituaryLocationAppUpdate";
import ObituaryLocationAppShow from "@/views/show/ObituaryLocationAppShow";

export default {
    path: '/obituary-location-app',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'obituary-location-app-search', component: ObituaryLocationAppSearch},
        {path: 'create', name: 'obituary-location-app-create', component: ObituaryLocationAppCreate},
        {path: 'edit/:id', name: 'obituary-location-app-edit', component: ObituaryLocationAppUpdate, props: true},
        {path: 'show/:id', name: 'obituary-location-app-show', component: ObituaryLocationAppShow, props: true},
    ]
}