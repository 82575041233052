<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                :class="{ 'formError': errors.company_id }">
                    <label>Empresa:</label>
                    <multiselect label="name" v-model="formDate.company_id" :searchable="true" :options="selectCompanyRestrict"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.state }">
                    <label>Estado:</label>
                    <multiselect v-model="formDate.state" :searchable="true" :options="selectCityState"
                        @select="loadSelectCity(formDate.state)" :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.city }">
                    <label>Cidade:</label>
                    <multiselect v-model="formDate.city" :searchable="true" :options="selectCity"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                :class="{ 'formError': errors.cep }">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" :type-input="'text'" v-model="formDate.cep"
                        @change="searchApiCEP" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5'">
                    <label>Referência:</label>
                    <input-text-area :rows="4" v-model="formDate.complement" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import InputTextArea from '@/components/forms/InputTextArea';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { mask } from 'vue-the-mask'
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Localização')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('loadSelectState')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
    },

    name: 'SpotCreate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        InputTextArea,
        ButtonOne,
        ButtonAdd,
    },

    directives: { mask },

    data() {
        return {
            buttonAdd: {
                route: '/spot/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                company_id: '',
                name: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadSelectCity(id) {
            this.$store.dispatch('loadSelectCity', id)
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeSpot', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$router.push({ name: 'spot-edit', params: { id: r.data } })
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('spot-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        resetFormAddresse() {
            this.formDate.district = ''
            this.formDate.address = ''
            this.formDate.complement = ''
        },

        searchApiCEP() {
            this.resetFormAddresse()
            let cep = this.formDate.cep.replace(/\D/g, '');
            const dataCEP = { 'cep': cep }
            this.$store.dispatch('searchApiCEP', dataCEP)
                .then((r) => {
                    this.formDate.district = r.data.bairro
                    this.formDate.address = r.data.logradouro
                    this.formDate.complement = r.data.complemento
                }).catch(() => { })
        },

    },

    computed: {
        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectCityState() {
            return this.$store.state.var.selectCityState
        },

        selectCity() {
            return this.$store.state.var.selectCity
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}

</script>
