export default {
    namePage: 'Home',

    selectActiveInactive: [
        { value: '1', label: 'Ativo' },
        { value: '2', label: 'Inativo' },
    ],

    selectSpotPoweOn: [
        { value: '1', label: 'Desligar' },
        { value: '2', label: 'Ligar' },
    ],

    selectStatusPlayerOnOff: [
        { value: '1', label: 'Offline' },
        { value: '2', label: 'Online' },
    ],

    selectNoYes: [
        { value: '1', label: 'Não' },
        { value: '2', label: 'Sim' },
    ],

    selectAlingMenuTable: [
        { value: '1', label: 'Texto alinhado a esquerda' },
        { value: '2', label: 'Texto centralizado' },
        { value: '3', label: 'Texto alinhado a direita' },
        { value: '4', label: 'Texto justificado' }
    ],

    selectContentSpot: [],
    selectObituaryList: [],
    selectVersionAndroid: [],
    selectVersionApp: [],
    selectNoticeApp: [],
    selectContentFolder: [],
    selectFontsMenuTable: [],
    selectNoticeListUrl: [],
    selectCityClimate: [],
    selectPermissions: [],
    selectGroupUser: [],
    selectCompany: [],
    selectCompanyRestrict: [],
    selectCityState: [],
    selectCity: [],
    selectResolutionPlayer: [],
    selectTypeMediaPlayer: [],
    selectSegmentPlayer: [],
    selectSpot: [],
    selectLayoutCampaign: [],
    selectNotSearch: 'Nada foi encontrado com os parâmetros informados!',
}