<template>
    <painel-component :title="'Ver'">

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch"
                        :options="selectActiveInactive" v-model="formDate.status" disabled />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>CEP:</label>
                    <input-component :type-input="'text'" v-model="formDate.cep" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Cidade:</label>
                    <input-component :type-input="'text'" v-model="formDate.city" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Estado:</label>
                    <input-component :type-input="'text'" v-model="formDate.state" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Bairro:</label>
                    <input-component :type-input="'text'" v-model="formDate.district" disabled />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12'">
                    <label>Endereço:</label>
                    <input-component :type-input="'text'" v-model="formDate.address" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>País:</label>
                    <input-component :type-input="'text'" v-model="formDate.country" disabled />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Refêrencia:</label>
                    <input-text-area :rows="4" v-model="formDate.complement" disabled />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import InputTextArea from '@/components/forms/InputTextArea';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import { ACTION_MESSAGES } from '@/configs/messages'

export default {
    created() {
        this.loadCompany()
        this.$store.commit('PRELOADER', true)
    },

    name: 'CompanyShow',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        InputTextArea,
        Multiselect,
        ButtonAdd,
    },

    props: { id: { required: true } },

    data() {
        return {
            buttonAdd: {
                route: '/company/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: ''
            },

        }
    },

    methods: {

        loadCompany() {
            this.$store.dispatch('updateNamePage', 'Empresa')
            this.$store.dispatch('loadCompany', this.id).then((r) => {
                this.formDate = r;
                this.checkACL()
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 4)
                .then((r) => {
                    if (r.data.indexOf('company-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}

</script>
