<template>
    <painel-component :title="'Ver'">

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Nome do Grupo:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" disabled />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" disabled />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    v-for="(permission, index) in selectPermissions" :key="index">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input" type="checkbox" :value="index" disabled
                            :id="'checkBoxMaster' + index" :name="index">
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>
                    <div class="form-check" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input type="checkbox" :value="option.value" :id="'checkOne' + option.value" disabled
                            :class="'form-check-input checkBoxAll' + index">
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd" />
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.commit('PRELOADER', true)
        this.loadGroupUser()
    },

    name: 'GroupUserUpdate',
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            buttonAdd: {
                route: '/group-user/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                permissions: []
            },

        }
    },

    methods: {

        loadGroupUser() {
            this.$store.dispatch('loadSelectPermissions', 'page');
            this.$store.dispatch('loadGroupUser', this.id).then((r) => {
                this.formDate = r;
                this.checkACL()
                setTimeout(() => {
                    this.checkBoxCheckedStart()
                }, 1100)
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('group-user-search') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },


        checkBoxCheckedStart() {
            let totalpage = 0;
            let cont = 0;
            let i = 0;
            let permissionsList = this.formDate.permissions;
            this.selectPermissions.forEach(function (element) {
                cont = 0;
                totalpage = element.options.length;
                element.options.forEach(function (item) {
                    if (permissionsList.includes(item.value)) {
                        document.getElementById("checkOne" + item.value).checked = true
                        cont = cont + 1;
                    }
                });
                if (cont >= totalpage) {
                    document.getElementById("checkBoxMaster" + i).checked = true
                }
                i = i + 1;
            });
        },
    },

    computed: {
        selectPermissions() {
            return this.$store.state.var.selectPermissions
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }
}

</script>

<style scoped>
hr {
    margin-top: 0;
}

input:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}

.form-check-input:checked {
    background-color: #d67c1c;
    border-color: #d67c1c;
}

.form-check-input:not([disabled]):active {
    color: #fff;
    background-color: #d67c1c !important;
    background-color: #d67c1c;

}

.form-check-input:active {
    color: #fff;
    background-color: #d67c1c !important;
    background-color: #d67c1c;

}
</style>