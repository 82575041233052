import axios from "axios"

export default {
    actions: {
        searchApiCEP(context, formData) {
            context.commit('PRELOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`)
                    .then((r) => { resolve(r) })
                    .catch(errors => {
                        reject(errors)
                        context.commit('PRELOADER', false)
                    })
                    .finally(() => context.commit('PRELOADER', false))
            })
        }
    }
}





