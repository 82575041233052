<template>
    <ModalComponent @submit-logout="destroyPlayer" @close-modal="closeModal" :visible="visible" :message="message"/>
    <ModalComponent @submit-logout="playerResetDevice" @close-modal="closeModalTwo" :visible="visibleTwo"
                    :message="message"/>

    <div class="player-add-campaign" v-if="addCampaign">
        <PlayerAddCampaign class="player-add-campaign-container" :id="idPlayer"
                           @close-player-add-campaign="addCampaignModal"/>
    </div>

    <div v-if="modalInfo" class="window-modal">
        <PlayerInfoShow class="player-info" @close="closeModalInfo" :id="idInfo"/>
    </div>

    <div v-if="modalHours" class="window-modal">
        <PlayerOpeningHours class="player-opening-hours" :id="idPlayer" @close="modalHours = !modalHours"/>
    </div>

    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <div v-if="filter">
                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Token:</label>
                            <input-component :type-input="'text'" v-model="formDate.token"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Nome do Player:</label>
                            <input-component :type-input="'text'" v-model="formDate.name"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Empresa:</label>
                            <Multiselect label="name" v-model="formDate.company_id" :options="selectCompanyRestrict"
                                         :searchable="true" :noResultsText="this.$store.state.var.selectNotSearch">
                                <template v-slot:singlelabel="{ value }">
                                    <div class="multiselect-single-label">
                                        <img class="character-label-icon" :src="value.icon"> {{ value.name }}
                                    </div>
                                </template>

                                <template v-slot:option="{ option }">
                                    <img class="character-option-icon" :src="option.icon"> {{ option.name }}
                                </template>
                            </Multiselect>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Status:</label>
                            <Multiselect :noResultsText="this.$store.state.var.selectNotSearch"
                                         :options="selectStatusPlayerOnOff" :searchable="true"
                                         v-model="formDate.online"/>
                        </row-col-component>
                    </row-component>

                    <row-component>
                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                            <label>Localização:</label>
                            <Multiselect label="name" :searchable="true"
                                         :noResultsText="this.$store.state.var.selectNotSearch" :options="selectSpot"
                                         v-model="formDate.spot_id"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Tipo de Mídia:</label>
                            <Multiselect :options="selectTypeMediaPlayer"
                                         :noResultsText="this.$store.state.var.selectNotSearch" :searchable="true"
                                         v-model="formDate.type_media_id"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Segmento:</label>
                            <Multiselect :options="selectSegmentPlayer"
                                         :noResultsText="this.$store.state.var.selectNotSearch" :searchable="true"
                                         v-model="formDate.segmento_id"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Versão do Aplicativo:</label>
                            <Multiselect :options="selectVersionApp"
                                         :noResultsText="this.$store.state.var.selectNotSearch" :searchable="true"
                                         v-model="formDate.version_app"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <label>Android:</label>
                            <Multiselect :options="selectVersionAndroid"
                                         :noResultsText="this.$store.state.var.selectNotSearch" :searchable="true"
                                         v-model="formDate.version_android"/>
                        </row-col-component>

                        <row-col-component
                                :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                            <div class="desk-btn">
                                <button-one class="mx-1" :itens="buttonOne" @submit="submit"/>
                            </div>
                            <div class="mobi-btn">
                                <button-one class="mx-1" :itens="buttonTwo" @submit="submit"/>
                            </div>
                            <div v-if="!btnFilter" style="display: inline;">
                                <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create"/>
                            </div>
                        </row-col-component>
                    </row-component>
                </div>
            </transition>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/play_button.svg">
                    Total de {{ player.totalPlayer }} {{ pluralSingOne(player.totalPlayer) }}
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="alert alert-secondary icon-message" role="alert">
                    <img src="@/assets/icones/play_button.svg">
                    Limite de {{ player.totalPlayerHired }} {{ pluralSingTwo(player.totalPlayerHired) }}
                </div>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                               v-for="(data, index) in object" :key="index">
                <div class="card_body">
                    <div class="card_title"
                         :class="[{ 'player_bg_online': data.online == 2 }, { 'player_bg_offline': data.online == 1 }]">
                        {{ data.name }}
                    </div>

                    <div class="content">
                        <span class="c_left">Última Comunicação<br>{{ data.ultima_comunicacao }}</span>
                        <span class="c_right">Token<br>{{ data.token }}</span>
                    </div>

                    <div class="content hh-40">
                        <span class="c_left text-truncate">
                            <img src="@/assets/icones/location.svg">
                            {{ data.spot_name }}
                        </span>

                        <span class="c_right text-truncate">
                            <img src="@/assets/icones/briefcase.svg">
                            {{ data.company_name }}
                        </span>
                    </div>

                    <div style="height: 40px" class="content">
                        <span class="c_left">
                            <img src="@/assets/icones/phone_portrait.svg">
                            Versão do App {{ data.versao_aplicativo }}
                        </span>
                        <span class="c_right">
                            <img src="@/assets/icones/android-icon.svg">
                            Android {{ data.versao_android }}
                        </span>
                    </div>

                    <div class="content">
                        <span class="c_left">
                            <div class="container_campaign">
                                <button @click="addCampaignModal(data.id)" class="btn"><img
                                        src="@/assets/icones/display.svg">
                                    Campanhas</button>
                                <div class="campaign">{{ data.total_campaing_add }}</div>
                            </div>
                        </span>
                        <span class="c_right">
                            <label :for="'default' + index" class="switches"
                                   :class="[{ 'switches_on': data.tela == 2 }, { 'switches_off': data.tela == 1 }]">
                                <input @click="playerOnOffDevice(data.id, data.tela)" type="checkbox"
                                       :checked="data.tela == 1" :id="'default' + index">
                                <span class="slider"></span>
                            </label>
                        </span>
                    </div>

                    <div class="footer_icon">
                        <button @click="editPlayer(data.id)" v-if="buttonCheck.edit" tooltip="Editar player"><img
                                src="@/assets/icones/create.svg"></button>

                        <button @click="infoPlayer(data.id)" v-if="buttonCheck.info" tooltip="Informações do player">
                            <img
                                    src="@/assets/icones/info_circle.svg"></button>

                        <button @click="openModal(data)" v-if="buttonCheck.delete" tooltip="Excluir player"><img
                                src="@/assets/icones/trash.svg"></button>

                        <button @click="playerSynchronize(data.id)" v-if="buttonCheck.synchronize"
                                tooltip="Sincronizar player com campanha"><img
                                src="@/assets/icones/cloud_upload_alt.svg"></button>

                        <button @click="PlayerRestartApp(data.id)" v-if="buttonCheck.restart_app"
                                tooltip="Reiniciar aplicativo do player"><img src="@/assets/icones/refresh_alt.svg">
                        </button>

                        <button @click="openModalTwo(data)" v-if="buttonCheck.reset_device" tooltip="Reset player"><img
                                src="@/assets/icones/reset_temporary.svg"></button>

                        <button @click="hoursPlayer(data.id)" v-if="buttonCheck.opening_hours"
                                tooltip="Horário de funcionamento"><img src="@/assets/icones/calendar_month.svg">
                        </button>

                        <button @click="playerRestartDevice(data.id)" v-if="buttonCheck.restart_device"
                                tooltip="Reiniciar player"><img src="@/assets/icones/power.svg"></button>
                    </div>

                </div>
            </row-col-component>
        </row-component>

        <button-pagination :pagination="player.itens" :offset="3" @paginate="loadPlayers"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent'
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import PlayerInfoShow from '@/views/show/PlayerInfoShow';
import PlayerAddCampaign from '@/views/create/PlayerAddCampaign';
import PlayerOpeningHours from '@/views/search/PlayerOpeningHours'
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Player')
        this.$store.dispatch('loadSelectTypeMediaPlayer')
        this.$store.dispatch('loadSelectSegmentPlayer')
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('loadSelectSpot')
        this.$store.dispatch('loadSelectVersionApp')
        this.$store.dispatch('loadSelectVersionAndroid')
        this.$store.commit('PRELOADER', true)
        this.loadPlayers(1)
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'PlayerSearch',
    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne,
        ButtonPagination,
        PlayerInfoShow,
        PlayerAddCampaign,
        PlayerOpeningHours
    },

    data() {
        return {
            buttonAdd: {
                route: '/player/create',
                icon: 'plus',
                tooltip: 'Adicionar'
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                info: false,
                synchronize: false,
                restart_app: false,
                restart_device: false,
                opening_hours: false,
                add_campaign: false,
                reset_device: false
            },

            formDate: {
                name: '',
                online: '',
                type_media_id: '',
                segmento_id: '',
                spot_id: '',
                company_id: '',
                token: '',
                version_app: '',
                version_android: ''
            },

            idInfo: '',
            modalInfo: false,

            addCampaign: false,
            idPlayer: '',

            modalHours: false,

            idModal: '',
            idModalTwo: '',
            message: '',
            visible: false,
            visibleTwo: false,

            object: '',

            filter: true,
            btnFilter: false,

            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadPlayers(1)
            this.searchSubmit = true;
        },

        loadPlayers(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadPlayers', {...this.params, page})
                    .then((r) => {
                        this.object = r.itens.data
                        this.checkACL()
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.itens.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        filterWindow() {
            this.filter = !this.filter
        },

        pluralSingOne(value) {
            if (value > 1)
                return "Player cadastrados"
            return "Players cadastrado"
        },

        pluralSingTwo(value) {
            if (value > 1)
                return "Players contratados"
            return "Player contratado"
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.idModal = data.id
        },

        openModalTwo(data) {
            this.visibleTwo = !this.visibleTwo
            this.message = `Deseja realmente resetar o app ${data.name} ${this.me.name}!,
            isso ira deletar todas as campanhas vinculadas do app `
            this.idModalTwo = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        closeModalTwo() {
            this.visibleTwo = !this.visibleTwo
        },

        closeModalInfo() {
            this.modalInfo = !this.modalInfo
            this.loadPlayers(1)
        },

        destroyPlayer() {
            this.visible = !this.visible
            this.$store.dispatch('destroyPlayer', this.idModal)
                .then(() => {
                    this.loadPlayers(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadPlayers(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        editPlayer(id) {
            this.$router.push({name: 'player-edit', params: {id: id}})
        },

        infoPlayer(id) {
            this.idInfo = id
            this.modalInfo = !this.modalInfo
        },

        hoursPlayer(id) {
            this.idPlayer = id
            this.modalHours = !this.modalHours
        },

        playerSynchronize(id) {
            this.$store.dispatch('playerSynchronize', id).then(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.playerSynchronize)
            }).catch(() => {
                this.$store.commit('addToast', {
                    title: 'Oops!',
                    type: 'error',
                    message: 'Erro ao sincronizar player!'
                },)
            })
        },

        PlayerRestartApp(id) {
            this.$store.dispatch('PlayerRestartApp', id).then(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.playerRestartApp)
            }).catch(() => {
                this.$store.commit('addToast', {
                    title: 'Oops!',
                    type: 'error',
                    message: 'Erro ao reiniciar aplicativo!'
                },)
            })
        },

        playerRestartDevice(id) {
            this.$store.dispatch('playerRestartDevice', id).then(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.playerRestartDevice)
            }).catch(() => {
                this.$store.commit('addToast', {
                    title: 'Oops!',
                    type: 'error',
                    message: 'Erro ao reiniciar player!'
                },)
            })
        },

        playerOnOffDevice(id, tela) {
            let n;

            if (tela == 1) {
                n = 2
            } else {
                n = 1
            }

            let params = {
                id: id,
                tela: n
            }

            this.$store.dispatch('playerOnOffDevice', params)
                .then((r) => {
                    setTimeout(() => {
                        this.$store.commit('addToast', {
                            title: 'Sucesso!',
                            type: 'success',
                            message: r
                        })
                        this.loadPlayers(1)
                        this.$store.commit('PRELOADER', false)
                    }, 5000)
                })
                .catch(() => {
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                    this.$store.commit('PRELOADER', false)
                })
        },

        playerResetDevice() {
            this.visibleTwo = !this.visibleTwo
            this.$store.dispatch('playerResetDevice', this.idModalTwo)
                .then(() => {
                    this.loadPlayers(1)
                    this.$store.commit('addToast', {
                        title: 'Sucesso!',
                        type: 'success',
                        message: 'Player foi resetado com sucesso'
                    })
                })
                .catch(() => {
                    this.loadPlayers(1)
                    this.$store.commit('addToast', {
                        title: 'Oops!',
                        type: 'error',
                        message: 'Erro ao resetar o player!'
                    })
                })
        },

        addCampaignModal(id) {
            if (this.buttonCheck.add_campaign) {
                this.addCampaign = !this.addCampaign
                this.idPlayer = id
                if (this.addCampaign == false) {
                    this.loadPlayers(1)
                }
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {

                    if (r.data.indexOf('player-search') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('player-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                        if (r.data.indexOf('player-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                        if (r.data.indexOf('player-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                        if (r.data.indexOf('player-info') !== -1) {
                            this.buttonCheck.info = true
                        }
                        if (r.data.indexOf('player-synchronize') !== -1) {
                            this.buttonCheck.synchronize = true
                        }
                        if (r.data.indexOf('player-restart-app') !== -1) {
                            this.buttonCheck.restart_app = true
                        }
                        if (r.data.indexOf('player-restart-device') !== -1) {
                            this.buttonCheck.restart_device = true
                        }
                        if (r.data.indexOf('player-opening-hours') !== -1) {
                            this.buttonCheck.opening_hours = true
                        }
                        if (r.data.indexOf('player-add-campaign') !== -1) {
                            this.buttonCheck.add_campaign = true
                        }
                        if (r.data.indexOf('player-reset-app') !== -1) {
                            this.buttonCheck.reset_device = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            })

        },
    },

    computed: {

        me() {
            return this.$store.state.auth.me
        },

        player() {
            return this.$store.state.player.items
        },

        params() {
            return {
                page: this.player.current_page,
                name: this.formDate.name,
                online: this.formDate.online,
                type_media_id: this.formDate.type_media_id,
                segmento_id: this.formDate.segmento_id,
                spot_id: this.formDate.spot_id,
                company_id: this.formDate.company_id,
                token: this.formDate.token,
                version_app: this.formDate.version_app,
                version_android: this.formDate.version_android,
            }
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectStatusPlayerOnOff() {
            return this.$store.state.var.selectStatusPlayerOnOff
        },

        selectTypeMediaPlayer() {
            return this.$store.state.var.selectTypeMediaPlayer
        },

        selectSegmentPlayer() {
            return this.$store.state.var.selectSegmentPlayer
        },

        selectVersionAndroid() {
            return this.$store.state.var.selectVersionAndroid
        },

        selectSpot() {
            return this.$store.state.var.selectSpot
        },

        selectVersionApp() {
            return this.$store.state.var.selectVersionApp
        },
    }

}
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style src="@/assets/css/card.css"></style>

<style scoped>
.icon-message > img {
    height: 36px;
    margin-right: 10px;
    filter: invert(38%) sepia(6%) saturate(550%) hue-rotate(353deg) brightness(95%) contrast(88%);
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: grid;
    place-items: center;
    background: rgb(0, 0, 0, 0.7);
}

.window-modal > div {
    width: 90%;
    height: 90%;
    margin: 14px auto;
}

.player-add-campaign {
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background-color: rgb(0, 0, 0, 0.7);
}

.player-add-campaign > .player-add-campaign-container {
    width: 80%;
    height: 85%;
    overflow-y: scroll;
}

.hh-40 {
    flex-direction: column;
}

.window-modal .player-opening-hours {
    width: 60%;
    height: 550px;
    margin: 0 auto;
}

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .player-add-campaign > .player-add-campaign-container {
        width: 97%;
        height: 95%;
        overflow-y: scroll;
    }

    .window-modal .player-opening-hours {
        width: 90%;
        height: 550px;
        margin: 0 auto;
    }

    .window-modal .player-info {
        width: 95%;
        height: 95%;
        overflow: auto;
    }

    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }

}

@media (max-width: 425px) {
    .player-add-campaign > .player-add-campaign-container {
        width: 97%;
        height: 95%;
        overflow: auto;
        overflow-y: scroll;
    }

    .window-modal .player-opening-hours {
        width: 97%;
        height: 85%;
        padding-top: 10%;
    }
}
</style>

