import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'user-location';
export default {

    loadUsersLocations(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_USERS_LOCATIONS', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    storeUserLocation(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    loadUserLocation(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateUserLocation(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    destroyUserLocation(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateUserLocationPhoto(context, formData) {
        context.commit('PRELOADER', true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}user-location-photo`, formData, config)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadUsersLocationsAddLocation(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}user-location-add-location`, {params})
                .then(response => {
                    context.commit('LOAD_USERS_LOCATIONS_ADD_LOCATION', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    storeUserLocationAddLocation(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}user-location-add-location-save`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('PRELOADER', false)
                })
        })
    },

    destroyUserLocationAddLocation(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}user-location-add-location-delete`, formData)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}