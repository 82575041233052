<template>
    <textarea class="form-control" :cols="cols" :rows="rows" :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>

<script>
export default {
    name: 'InputTextArea',
    props: {
        cols: {
            "type": Number
        },
        rows: {
            "type": Number
        },

        modelValue: {
            type: String,
            default: '',
            required: false
        },
    }
}

</script>

<style scoped>
textarea {
    resize: none;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
}

textarea:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}
</style>