export default {
    icon: 'user_male',
    name: 'Usuário',
    subLinks: [
        {
            name: 'Grupo de Usuário',
            route: '/group-user/search',
            pagePermissions: [
                "group-user-search",
                "group-user-create",
                "group-user-edit",
                "group-user-delete",
            ]
        },

        {
            name: 'Adicionar',
            route: '/user/search',
            pagePermissions: [
                'user-search',
                'user-create',
                'user-edit',
                'user-delete',
            ],
        },
    ],

    pageLinksNavCheck: [
        "group-user-search",
        "group-user-create",
        "group-user-edit",
        "group-user-delete",
        'user-search',
        'user-create',
        'user-edit',
        'user-delete',
    ]
}