import actions from '@/store/modules/menu-table-app/actions'
import getters from '@/store/modules/menu-table-app/getters'
import mutations from '@/store/modules/menu-table-app/mutations'
import state from '@/store/modules/menu-table-app/state'

export default {
    actions,
    getters,
    mutations,
    state
}