export default {
    icon: 'location',
    name: 'Localização',
    subLinks: [
        {
            name: 'Local',
            route: '/spot/search',
            pagePermissions: [
                "spot-search",
                "spot-create",
                "spot-edit",
                "spot-delete"
            ],
        },

        {
            name: 'Usuário',
            route: '/user-location/search',
            pagePermissions: [
                "user-location-search",
                "user-location-create",
                "user-location-edit",
                "user-location-delete",
                "user-location-add-location",
                "user-location-add-location-save",
                "user-location-add-location-delete"
            ],
        }
    ],

    pageLinksNavCheck: [
        "spot-search",
        "spot-create",
        "spot-edit",
        "spot-delete",
        "user-location-search",
        "user-location-create",
        "user-location-edit",
        "user-location-delete",
        "user-location-add-location",
        "user-location-add-location-save",
        "user-location-add-location-delete"
    ]
}