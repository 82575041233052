<template>
    <ModalComponent @submit-logout="destroyGroupUser" @close-modal="closeModal" :visible="visible" :message="message"/>
    <painel-component :title="'Pesquisar'">
        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 filter-position'"
                           v-if="btnFilter">
            <button-add v-if="buttonCheck.create" class="mx-3" :itens="buttonAdd"></button-add>
            <button class="btn-filter" @click="filterWindow">
                <img src="@/assets/icones/funnel.svg" alt="">
            </button>
        </row-col-component>

        <form class="mb-4 mt-5">
            <transition name="fade-in-top">
                <row-component v-if="filter">
                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Nome do Grupo:</label>
                        <input-component :type-input="'text'" v-model="formDate.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                        <label>Status:</label>
                        <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                        <div class="desk-btn"><button-one class="mx-1" :itens="buttonOne" @submit="submit"/></div>
                        <div class="mobi-btn"><button-one class="mx-1" :itens="buttonTwo" @submit="submit"/></div>

                        <div v-if="!btnFilter" style="display: inline;">
                            <button-add class="mx-1" :itens="buttonAdd" v-if="buttonCheck.create"/>
                        </div>
                    </row-col-component>
                </row-component>
            </transition>
        </form>

        <div class="__table">
            <table>
                <thead>
                <tr>
                    <th scope="col" style="width: 40%">Nome</th>
                    <th scope="col" style="width: 40%">Status</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">Ações
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in groups.data" :key="index">
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
                        <button-show v-if="buttonCheck.show" :route="'group-user-show'" :id-params="data.id"/>
                        <button-edit v-if="buttonCheck.edit" :route="'group-user-edit'" :id-params="data.id"/>
                        <button-delete v-if="buttonCheck.delete" @click="openModal(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <button-pagination :pagination="groups" :offset="3" @paginate="loadGroups"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import ButtonDelete from '@/components/buttons/ButtonDelete';
import ButtonPagination from '@/components/buttons/ButtonPagination';
import {ACTION_MESSAGES} from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.commit('PRELOADER', true)
        this.loadGroups(1)
        this.checkACL()
        if (window.innerWidth <= 991) {
            this.filter = false
            this.btnFilter = true
        }
    },

    name: 'GroupUserSearch',

    components: {
        PainelComponent,
        ModalComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
        ButtonEdit,
        ButtonShow,
        ButtonDelete,
        ButtonPagination,
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/group-user/create',
                icon: 'plus',
                tooltip: 'Adicionar'
            },

            buttonOne: {
                icon: '',
                tooltip: 'Buscar',
                name: 'Buscar',
                type: 'button'
            },

            buttonTwo: {
                icon: 'search',
                tooltip: '',
                name: '',
                type: 'button'
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false,
            },

            formDate: {
                name: '',
                status: ''
            },

            filter: true,
            btnFilter: false,

            id: '',
            message: '',
            visible: false,
            searchSubmit: false
        }
    },

    methods: {
        filterWindow() {
            this.filter = !this.filter
        },

        submit() {
            this.loadGroups(1)
            this.searchSubmit = true;
        },

        loadGroups(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadGroups', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data == '') {
                                this.$store.commit('addToast', ACTION_MESSAGES.searchAlert)
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('group-user-search') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('group-user-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('group-user-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('group-user-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('group-user-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }

                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModal(data) {
            if (data.id == this.me.group_id) {
                this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorMeGroup)
                return false
            }
            this.visible = !this.visible
            this.message = `${this.me.name}, deseja realmente deletar ${data.name} !`
            this.id = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

        destroyGroupUser() {
            this.visible = !this.visible
            this.$store.dispatch('destroyGroupUser', this.id)
                .then(() => {
                    this.loadGroups(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadGroups(1)
                    this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
                })
        },

        statusString(value) {
            if (value == 1)
                return "Ativo"
            if (value == 2) {
                return "Inativo"
            }
        }
    },

    computed: {
        groups() {
            return this.$store.state.group.items
        },

        me() {
            return this.$store.state.auth.me
        },

        params() {
            return {
                page: this.groups.current_page,
                name: this.formDate.name,
                status: this.formDate.status
            }
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }

}
</script>

<style src="@/assets/css/filterSearch.css"></style>

<style scoped>

.desk-btn {
    display: none;
}

.mobi-btn {
    display: inline;
}

@media (max-width: 991px) {
    .desk-btn {
        display: inline;
    }

    .mobi-btn {
        display: none;
    }
}

</style>