<template>
    <div class="main">
        <MyToasts/>

        <div class="login-page">
            <div class="imgLogin">
                <img src="@/assets/img/logo.png" alt="">
            </div>
            <div class="form">
                <form autocomplete="off" @submit.prevent="resetPassword && resetPasswordEmail">
                    <input type="email" v-model="this.emailComputed" disabled>
                    <input placeholder="Senha" maxlength="6" v-model="formDate.password">
                    <input placeholder="Confirmar Senha" maxlength="6" v-model="formDate.passwordconfirm">
                    <input placeholder="Código" type="text" v-model="formDate.code">
                    <button type="submit" :class="{ 'preloderSend': sendForm }" :disabled="sendForm"
                            @click="resetPassword">
                        Enviar
                    </button>

                    <button :disabled="sendCode" class="my-3 btn" type="submit" :class="{ 'preloderSend': sendCode }"
                            @click="resetPasswordEmail">
                        Reenviar Código
                        <div id="counter"></div>
                    </button>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import MyToasts from "@/components/toast/MyToasts.vue";

export default {
    name: 'RecoverPassword',
    components: {MyToasts},

    data() {
        return {

            formDate: {
                email: '',
                password: '',
                passwordconfirm: '',
                code: ''
            },

            formDateEmail: {
                email: '',
            },

            sendForm: false,
            sendCode: true

        }
    },

    created() {
        setTimeout(() => {
            this.counterEffect()
        }, 100)
    },

    methods: {
        resetPassword() {
            if (!this.sendForm) {
                this.formDate.email = this.emailComputed
                this.sendForm = true
                this.$store.dispatch('resetPassword', this.formDate)
                    .then(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', {
                            title: 'Sucesso!',
                            type: 'success',
                            time: 2500,
                            message: 'Parabéns, a sua senha foi alterada!'
                        });
                        setTimeout(() => {
                            this.$router.replace({name: 'login'})
                        }, 4000)
                    })
                    .catch(() => {
                        this.sendForm = false;
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            time: 2500,
                            message: 'Ocorreu um erro ao alterar sua senha!'
                        });
                    })
            }
        },

        resetPasswordEmail() {
            this.formDateEmail.email = this.emailComputed
            if (!this.sendCode) {
                this.sendCode = true
                this.$store.dispatch('resetPasswordEmail', this.formDateEmail)
                    .then(() => {
                        this.$store.commit('addToast', {
                            title: 'Sucesso!',
                            type: 'success',
                            time: 2500,
                            message: 'Reenviamos o código no e-mail informado!'
                        });
                        this.counterEffect()
                    })
                    .catch(() => {
                        this.$store.commit('addToast', {
                            title: 'Oops!',
                            type: 'error',
                            time: 2500,
                            message: 'Ocorreu um erro ao reenviar o código!'
                        });
                    })
            }
        },

        counterEffect() {
            let counterDiv = document.getElementById('counter')
            counterDiv.style.display = 'contents'
            counterDiv.innerHTML = '60'

            let counter = 60;
            const interval = setInterval(() => {
                counterDiv.innerHTML = counter
                counter--;
                this.sendCode = true
                if (counter < 0) {
                    clearInterval(interval);
                    this.sendCode = false
                    counterDiv.style.display = 'none'
                }
            }, 1000);
        }
    },

    computed: {
        emailComputed() {
            return this.$store.state.auth.email
        }
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main {
    width: 100vw;
    height: 100vh;
    background: url(@/assets/img/background-pass.jpg) no-repeat center;
    background-size: cover;
}

.login-page {
    width: 450px;
    padding: 4% 0 0;
    margin: auto;
}

.form {
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form p {
    margin: 0;
    text-align: left;
    padding: 0 0 0 5%;
}

.form input {
    outline: 0;
    background: rgba(255, 255, 255, 0.6);
    width: 100%;
    border: 1px solid black;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.form button {
    text-transform: uppercase;
    outline: 0;
    background: #f07c04;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
    background-color: #d67c1c !important;
    color: white;
    border-color: #d67c1c;
    box-shadow: none !important;
}

.form button.btn {
    color: white;
    border-radius: 0;
    background: #1d1d1b !important;
}

.login-page .imgLogin img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
}

.login-page .imgLogin {
    background-color: rgba(255, 255, 255, 0.6);
    padding: 5% 0;
}

.form a {
    display: inline-block;
    text-decoration: none;
    color: #606060;
    text-transform: uppercase;
    width: 49%;
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    padding: 10px;
    font-size: 12px;
}

.login-page p {
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 0;
}

.checkboxForm p {
    text-transform: uppercase;
}

.checkboxForm li {
    display: inline-block;
    width: 32%;
    font-size: 14px;
}

.checkboxForm li input {
    width: 20px;
}

.loginAlert p {
    margin-bottom: 0;
}

.preloderSend {
    padding-right: 40px;
}

.preloderSend:after {
    content: "";
    position: absolute;
    animation: load 3s infinite;
    margin-left: 4px;
}

@keyframes load {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    99% {
        content: "...";
    }
}

@media (max-width: 767px) {

    .main {
        display: flex;
        align-items: center;
    }

    .login-page {
        width: 96%;

    }

    .login-page img {
        max-width: 200px;
    }

    .form {
        padding: 20px;
    }
}
</style>