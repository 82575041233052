import AdminComponent from '@/components/dashboard/AdminComponent'
import LotteryAppSearch from '@/views/search/LotteryAppSearch'
import LotteryAppCreate from '@/views/create/LotteryAppCreate'
import LotteryAppUpdate from '@/views/update/LotteryAppUpdate'
import LotteryAppShow from '@/views/show/LotteryAppShow'

export default {
    path: '/lottery-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'lottery-app-search', component: LotteryAppSearch },
        { path: 'create', name: 'lottery-app-create', component: LotteryAppCreate },
        { path: 'edit/:id', name: 'lottery-app-edit', component: LotteryAppUpdate, props: true },
        { path: 'show/:id', name: 'lottery-app-show', component: LotteryAppShow, props: true },
    ]
}