<template>
    <Transition name="show">
        <div class="toasts-wrapper" v-if="toasts.length">
            <MyToast v-for="(toast, index) of toasts" :key="index" :toast="toast"></MyToast>
        </div>
    </Transition>
</template>
  
<script>
import MyToast from '@/components/toast/MyToast'
export default {
    name: 'MyToasts',
    components: { MyToast },
    computed: {
        toasts() {
            return this.$store.state.toasts.toasts;
        }
    }
}
</script>
  
<style scoped>
.toasts-wrapper {
    width: 400px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    right: 0px;
    top: 1rem;
    transition: 1s;
}

.show-enter-active,
.show-leave-enter {
    transform: translateX(100%);
    /* transform: translateX(0); */
    transition: all 0.2s linear;
}

.show-enter,
.show-leave-to {
    /* transform: translateX(0); */
    transform: translateX(100%);
    transition: all 1s linear;
}
</style>