<template>
    <div class="bg-overlay" v-show="preloader">
        <div class="gooey">
            <span class="dot"></span>
            <div class="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreLoader',
    computed: {
        preloader() {
            return this.$store.state.preloader.loading
        }
    }
}
</script>

<style scoped>
.bg-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 103;
    background-color: rgb(255, 255, 255, 0.5);
    height: 100vh;
    width: 100vw;
}

.gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    filter: contrast(20);
}

.gooey .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    /* filter: blur(3px); */
    background: #000;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
}

.gooey .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    animation: dots 2.8s infinite;
}

.gooey .dots span {
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    /* filter: blur(3px); */
    background: #000;
    border-radius: 50%;
}

@-moz-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-webkit-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-o-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-moz-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@-webkit-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@-o-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}
</style>