import AdminComponent from '@/components/dashboard/AdminComponent'
import LoginView from '@/views/LoginView'
import RecoverPassword from "@/views/RecoverPassword";
import HomeView from '@/views/HomeView'

import App from '@/router/modules/app'
import Campaign from '@/router/modules/campaign'
import ClockApp from '@/router/modules/clock-app'
import Company from '@/router/modules/company'
import Content from '@/router/modules/content'
import GroupUser from '@/router/modules/group-user'
import LotteryApp from '@/router/modules/lottery-app'
import MenuTableApp from '@/router/modules/menu-table-app'
import MenuTableItem from '@/router/modules/menu-table-item'
import NewsApp from '@/router/modules/news-app'
import NoticeApp from '@/router/modules/notice-app'
import ObituaryApp from "@/router/modules/obituary-app";
import ObituaryLocationApp from "@/router/modules/obituary-location-app";
import Player from '@/router/modules/player'
import Spot from '@/router/modules/spot'
import Tutorial from "@/router/modules/tutorial";
import User from '@/router/modules/user'
import UserLocation from '@/router/modules/user-location'
import WeatherApp from '@/router/modules/weather-app'
import WebApp from '@/router/modules/web-app'
import YoutubeApp from '@/router/modules/youtube-app'

const routes = [
    {
        path: '/',
        component: AdminComponent,
        children: [
            {path: 'login', name: 'login', component: LoginView},
            {path: 'recover-password', name: 'recover-password', component: RecoverPassword, props: true},
            {path: '/', meta: {auth: true}, name: 'home', component: HomeView}
        ]
    },
    App,
    Campaign,
    ClockApp,
    Company,
    Content,
    GroupUser,
    LotteryApp,
    MenuTableApp,
    MenuTableItem,
    NewsApp,
    NoticeApp,
    ObituaryApp,
    ObituaryLocationApp,
    Player,
    Spot,
    Tutorial,
    User,
    UserLocation,
    WeatherApp,
    WebApp,
    YoutubeApp,
]

export default routes