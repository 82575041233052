import axios from "axios";
import { URL_BASE } from "@/configs/constants";
const RESOURCE = 'campaing-files';

export default {

    loadCampaingsFiles(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_CAMPAIGN_FILE', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    storeCampaingsFile(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}campaing-add-file`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

}