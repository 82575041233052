export const URL_BASE = 'api/v1/system/'
export const NAME_TOKEN = 'TOKEN_AUTH'

let estados = [];
estados['AC'] = 'Acre';
estados['AL'] = 'Alagoas';
estados['AP'] = 'Amapá';
estados['AM'] = 'Amazonas';
estados['BA'] = 'BahiaBahia';
estados['CE'] = 'Ceará';
estados['DF'] = 'Distrito Federal';
estados['ES'] = 'Espírito Santo';
estados['GO'] = 'Goiás';
estados['MA'] = 'Maranhão';
estados['MT'] = 'Mato Grosso';
estados['MS'] = 'Mato Grosso do Sul';
estados['MG'] = 'Minas Gerais';
estados['PA'] = 'Pará';
estados['PB'] = 'Paraíba';
estados['PR'] = 'Paraná';
estados['PE'] = 'Pernambuco';
estados['PI'] = 'Piauí';
estados['RJ'] = 'Rio de Janeiro';
estados['RN'] = 'Rio Grande do Norte';
estados['RS'] = 'Rio Grande do Sul';
estados['RO'] = 'Rondônia';
estados['RR'] = 'Roraima';
estados['SC'] = 'Santa Catarina';
estados['SP'] = 'São Paulo';
estados['SE'] = 'Sergipe';
estados['TO'] = 'Tocantins';

let configChart = {
    chart: {
        type: 'pie',
        options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
        }
    },

    accessibility: {
        enabled: false
    },

    credits: {
        enabled: false
    },

    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
}

let money = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
}

export let ESTADOS = estados
export let CONFIG_CHART = configChart
export let MONEY = money