import AdminComponent from '@/components/dashboard/AdminComponent'
import AppSearch from '@/views/search/AppSearch'
import WeatherAppCreate from '@/views/create/WeatherAppCreate'

export default {
    path: '/app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'app-search', component: AppSearch },
        { path: 'create', name: 'weather-app-create', component: WeatherAppCreate },
    ]
}