export default {
    icon: 'play_button',
    name: 'Player',
    route: '/player/search',
    pagePermissions: [
        "player-search",
        "player-create",
        "player-edit",
        "player-delete",
        "player-info",
        "player-synchronize",
        "player-restart-app",
        "player-restart-device",
        "player-opening-hours",
        "player-add-campaign"
    ],

    pageLinksNavCheck: [
        "player-search",
        "player-create",
        "player-edit",
        "player-delete",
        "player-info",
        "player-synchronize",
        "player-restart-app",
        "player-restart-device",
        "player-opening-hours",
        "player-add-campaign"
    ]
}