import axios from 'axios';
import {NAME_TOKEN} from '@/configs/constants';
import {URL_BASE} from '@/configs/constants';

const RESOURCE = 'auth'

export default {
    login(context, params) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, params)
                .then(r => {
                    context.commit('AUTH_USER_OK', r.data.user)
                    const token = r.data.user.token
                    localStorage.setItem(NAME_TOKEN, token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    return resolve(r)
                }).catch(errors => {
                return reject(errors)
            })
                .finally(() => {
                })
        });
    },

    logout(context) {
        const token = localStorage.getItem(NAME_TOKEN)
        context.commit('AUTH_USER_LOGOF')
        if (token != null) {
            const configHeader = {headers: {Authorization: `Bearer ${token}`}};
            axios.delete(`${URL_BASE}${RESOURCE}/logout`, configHeader).then(() => {
                localStorage.removeItem(NAME_TOKEN)
            }).catch(() => {
                localStorage.removeItem(NAME_TOKEN)
            })
        } else {
            localStorage.removeItem(NAME_TOKEN)
        }
    },

    loginCheck(context) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem(NAME_TOKEN)
            const configHeader = {headers: {Authorization: `Bearer ${token}`}};
            if (!token) {
                context.state.authenticate = false;
                return reject()
            }
            context.state.authenticate = true;
            axios.get(`${URL_BASE}${RESOURCE}/me`, configHeader)
                .then(r => {
                    context.commit('AUTH_USER_OK', r.data.user)
                    resolve()
                }).catch(e => {
                return reject(e)
            })
        })
    },

    resetPasswordEmail(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}/reset-password-email`, formData)
                .then(r => {
                    return resolve(r)
                }).catch(errors => {
                return reject(errors)
            })

        });
    },

    resetPassword(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}/reset-password`, formData)
                .then(r => {
                    return resolve(r)
                }).catch(errors => {
                return reject(errors)
            })

        });
    },

    ACLitens(context, type) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/permissions?type=${type}`,)
                .then(r => {
                    resolve(r)
                })
                .catch(e => {
                    return reject(e)
                })
        })
    },

    ACLitensnav() {
        // context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/permissionsnav`,)
                .then(r => {
                    resolve(r)
                })
                .catch(e => {
                    return reject(e)
                })
        })
    },

    ACLApps() {
        // context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/permissions-app`,)
                .then(r => {
                    resolve(r)
                })
                .catch(e => {
                    return reject(e)
                })
        })
    },

    UpdateLinkPhoto(context, photo) {
        context.commit('UPDATE_LINK_PHOTO', photo)
    }

}