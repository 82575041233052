import AdminComponent from '@/components/dashboard/AdminComponent'
import PlayerSearch from '@/views/search/PlayerSearch'
import PlayerCreate from '@/views/create/PlayerCreate'
import PlayerUpdate from '@/views/update/PlayerUpdate'

export default {
    path: '/player',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'player-search', component: PlayerSearch },
        { path: 'create', name: 'player-create', component: PlayerCreate },
        { path: 'edit/:id', name: 'player-edit', component: PlayerUpdate, props: true },
    ]
}