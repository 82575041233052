import AdminComponent from '@/components/dashboard/AdminComponent'
import WebAppSearch from '@/views/search/WebAppSearch'
import WebAppCreate from '@/views/create/WebAppCreate'
import WebAppUpdate from '@/views/update/WebAppUpdate'
import WebAppShow from '@/views/show/WebAppShow'

export default {
    path: '/web-app',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'web-app-search', component: WebAppSearch },
        { path: 'create', name: 'web-app-create', component: WebAppCreate },
        { path: 'edit/:id', name: 'web-app-edit', component: WebAppUpdate, props: true },
        { path: 'show/:id', name: 'web-app-show', component: WebAppShow, props: true },
    ]
}