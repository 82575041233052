<template>
    <ModalComponent @submit-logout="destroyObituaryAppsRoom" @close-modal="closeModal" :visible="visible"
                    :message="message"/>

    <div class="modal-add-obituary-room" v-if="modal_add_room">
        <painel-component :title="'Adicionar Sala'" class="container-obituary-room">
            <ErrorComponent :errors="errors" v-if="showErrorRoom"/>
            <div @click="modalAddRoom" class="btn-close"></div>
            <form>
                <row-component>
                    <row-col-component
                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.name }">
                        <label>Nome:</label>
                        <input-component :type-input="'text'" v-model="formDataRoom.name"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.status }">
                        <label>Local:</label>
                        <multiselect v-model="formDataRoom.cemetery" :searchable="true" :options="selectObituaryList"
                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.birth_date }">
                        <label>Data nascimento:</label>
                        <input-component v-mask="['##/##/####']" :type-input="'text'"
                                         v-model="formDataRoom.birth_date"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.death_date }">
                        <label>Data falecimento:</label>
                        <input-component v-mask="['##/##/####']" :type-input="'text'"
                                         v-model="formDataRoom.death_date"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.funeral_date }">
                        <label>Data velório:</label>
                        <input-component v-mask="['##/##/####']" :type-input="'text'"
                                         v-model="formDataRoom.funeral_date"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.funeral_hour }">
                        <label>Hora velório:</label>
                        <input-component v-mask="['##:##']" :type-input="'text'"
                                         v-model="formDataRoom.funeral_hour"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.burial_date }">
                        <label>Data sepultamento:</label>
                        <input-component v-mask="['##/##/####']" :type-input="'text'"
                                         v-model="formDataRoom.burial_date"/>
                    </row-col-component>

                    <row-col-component
                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                            :class="{ 'formError': errors.burial_hour }">
                        <label>Hora sepultamento:</label>
                        <input-component v-mask="['##:##']" :type-input="'text'"
                                         v-model="formDataRoom.burial_hour"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component v-if="btn_add_room"
                                       :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <button-one :disabled="sendRoom" style="width: 100%" :itens="buttonThree"
                                    @click="submitAddRoom"/>
                    </row-col-component>

                    <row-col-component v-if="btn_edit_room"
                                       :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <button-one :disabled="sendRoom" style="width: 100%" :itens="buttonOne"
                                    @click="submitEditRoom(formDataRoom.id)"/>
                    </row-col-component>
                </row-component>

            </form>
        </painel-component>
    </div>

    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError"/>
        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd"/>

        <div class="container-tabs mt-5">
            <div class="card tabs">

                <input checked id="tab-1" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-1" class="tab tab-primary">
                    <i><img src="@/assets/icones/info_circle.svg" alt=""></i>
                    <span>Informações</span>
                </label>

                <input id="tab-2" type="radio" class="tab tab-selector" name="tab"/>
                <label for="tab-2" class="tab tab-success">
                    <i><img src="@/assets/icones/star.svg" alt=""></i>
                    <span>Obituário</span>
                </label>

                <div class="tabsShadow"></div>
                <div class="glider"></div>
                <section class="content">

                    <div class="item" id="content-1">
                        <form>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.name }">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formDate.name"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.status }">
                                    <label>Status:</label>
                                    <multiselect v-model="formDate.status" :searchable="true"
                                                 :options="selectActiveInactive"
                                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.company_id }">
                                    <label>Empresa:</label>
                                    <Multiselect label="name" v-model="formDate.company_id"
                                                 :options="selectCompanyRestrict"
                                                 :searchable="true"
                                                 :noResultsText="this.$store.state.var.selectNotSearch">
                                        <template v-slot:singlelabel="{ value }">
                                            <div class="multiselect-single-label">
                                                <img alt="" class="character-label-icon" :src="value.icon">
                                                {{ value.name }}
                                            </div>
                                        </template>

                                        <template v-slot:option="{ option }">
                                            <img alt="" class="character-option-icon" :src="option.icon"> {{
                                                option.name
                                            }}
                                        </template>
                                    </Multiselect>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.time }">
                                    <label>Tempo exibição em segundos:</label>
                                    <input-component :type-input="'number'" v-model="formDate.time"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.address }">
                                    <label>Endereço:</label>
                                    <input-component :type-input="'text'" v-model="formDate.address"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.title_one }">
                                    <label>Nome telefone:</label>
                                    <input-component :type-input="'text'" v-model="formDate.title_one"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                        :class="{ 'formError': errors.phone_one }">
                                    <label>Telefone:</label>
                                    <input-component v-mask="['(##) ####-####', '(##) #####-####']" :type-input="'text'"
                                                     v-model="formDate.phone_one"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne"
                                                @click="submit"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>

                    <div class="item" id="content-2">
                        <button-one v-if="buttonCheck.room_add" style="margin: 1%" class="add-button" :itens="buttonTwo"
                                    @click="modalAddRoom"/>

                        <div class="container-obituary mt-5" v-for="(data, index) in this.formDate.obituary_rom_itens"
                             :key="index">
                            <form>
                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                        <label>Nome:</label>
                                        <input-component disabled :type-input="'text'" v-model="data.name"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                            :class="{ 'formError': errors.status }">
                                        <label>Local:</label>
                                        <multiselect disabled v-model="data.cemetery" :searchable="true"
                                                     :options="selectObituaryList"
                                                     :noResultsText="this.$store.state.var.selectNotSearch"/>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Data nascimento:</label>
                                        <input-component disabled v-mask="['##/##/####']" :type-input="'text'"
                                                         v-model="data.birth_date"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Data falecimento:</label>
                                        <input-component disabled v-mask="['##/##/####']" :type-input="'text'"
                                                         v-model="data.death_date"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Data velório:</label>
                                        <input-component disabled v-mask="['##/##/####']" :type-input="'text'"
                                                         v-model="data.funeral_date"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Hora velório:</label>
                                        <input-component disabled v-mask="['##:##']" :type-input="'text'"
                                                         v-model="data.funeral_hour"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Data sepultamento:</label>
                                        <input-component disabled v-mask="['##/##/####']" :type-input="'text'"
                                                         v-model="data.burial_date"/>
                                    </row-col-component>

                                    <row-col-component
                                            :class-row-col="'col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <label>Hora sepultamento:</label>
                                        <input-component disabled v-mask="['##:##']" :type-input="'text'"
                                                         v-model="data.burial_hour"/>
                                    </row-col-component>
                                </row-component>

                                <row-component class="justify-content-end">
                                    <row-col-component
                                            :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                        <div class="btn-group float-lg-end" role="group">
                                            <button-one v-if="buttonCheck.room_edit" @click="modalAddRoom(1, index)"
                                                        class="me-2" :itens="buttonFour"/>

                                            <button v-if="buttonCheck.room_delete" @click="openModal(data)"
                                                    type="button" class="btn btn-delete">
                                                <img src="@/assets/icones/trash.svg" alt="">
                                            </button>
                                        </div>
                                    </row-col-component>
                                </row-component>
                            </form>
                        </div>
                    </div>
                </section>

            </div>
        </div>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ModalComponent from '@/components/modal/ModalComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';

import {mask} from 'vue-the-mask'
import {ACTION_MESSAGES} from '@/configs/messages';
import data from "bootstrap/js/src/dom/data";

export default {
    created() {
        this.loadObituaryApp()
        this.$store.commit('PRELOADER', true)
        this.$store.dispatch('loadSelectCompanyRestrict')
        this.$store.dispatch('loadSelectObituaryLocalList')
        this.$store.dispatch('updateNamePage', 'App Obituário')
    },

    props: {
        id: {required: true}
    },

    name: 'ObituaryAppUpdate',
    components: {
        PainelComponent,
        ModalComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
    },

    directives: {mask},

    data() {
        return {
            buttonAdd: {
                route: '/obituary-app/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonTwo: {
                icon: 'plus',
                type: 'button',
                name: '',
                tooltip: 'Adicionar'
            },

            buttonThree: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            buttonFour: {
                icon: 'create',
                type: 'button',
                name: '',
                tooltip: ''
            },

            buttonCheck: {
                create: false,
                room_add: false,
                room_edit: false,
                room_delete: false,
            },

            formDate: {
                company_id: '',
                name: '',
                time: '',
                type: '',
                status: '',
                code_url: '',
                address: '',
                title_one: '',
                phone_one: '',
                urlPreview: '',
                cemetery: '',
                obituary_rom_itens: []
            },

            formDataRoom: {
                id: this.id,
                name: '',
                birth_date: '',
                death_date: '',
                funeral_hour: '',
                funeral_date: '',
                burial_hour: '',
                burial_date: '',
                cemetery: ''
            },

            edit: {
                id: this.id,
                idobituary: '',
                name: '',
                birth_date: '',
                death_date: '',
                funeral_hour: '',
                funeral_date: '',
                burial_hour: '',
                burial_date: '',
                cemetery: '',
            },

            delete: {
                id: this.id,
                idobituary: ''
            },

            sendRoom: false,
            btn_add_room: true,
            btn_edit_room: false,
            showErrorRoom: false,
            modal_add_room: false,

            visible: false,
            message: '',

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        routeAdd() {
            this.$router.push({name: 'obituary-app-create'})
        },

        loadObituaryApp() {
            this.$store.dispatch('loadObituaryApp', this.id)
                .then((r) => {
                    this.formDate = r
                    this.checkACL()
                }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })

        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('app-obituary-room-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('app-obituary-room-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('app-obituary-room-add') !== -1) {
                            this.buttonCheck.room_add = true
                        }

                        if (r.data.indexOf('app-obituary-room-add-edit') !== -1) {
                            this.buttonCheck.room_edit = true
                        }

                        if (r.data.indexOf('app-obituary-room-add-delete') !== -1) {
                            this.buttonCheck.room_delete = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateObituaryApp', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        modalAddRoom(params, index) {
            this.modal_add_room = !this.modal_add_room
            this.formDataRoom = {}
            this.formDataRoom.id = this.id
            this.btn_add_room = true
            this.btn_edit_room = false
            if (params === 1) {
                this.btn_edit_room = true
                this.btn_add_room = false
                this.formDataRoom = this.formDate.obituary_rom_itens[index]
            }
        },

        submitAddRoom() {
            if (!this.sendRoom) {
                this.sendRoom = true
                this.$store.dispatch('storeObituaryAppsRoom', this.formDataRoom)
                    .then(() => {
                        this.showErrorRoom = false;
                        this.sendRoom = false
                        this.errors = [];
                        this.loadObituaryApp()
                        this.modal_add_room = !this.modal_add_room
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                    })
                    .catch((r) => {
                        this.showErrorRoom = true;
                        this.sendRoom = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        attrEdit(id) {
            this.edit.idobituary = id
            this.edit.name = this.formDataRoom.name
            this.edit.birth_date = this.formDataRoom.birth_date
            this.edit.burial_date = this.formDataRoom.burial_date
            this.edit.death_date = this.formDataRoom.death_date
            this.edit.burial_hour = this.formDataRoom.burial_hour
            this.edit.funeral_date = this.formDataRoom.funeral_date
            this.edit.funeral_hour = this.formDataRoom.funeral_hour
            this.edit.cemetery = this.formDataRoom.cemetery
        },

        submitEditRoom(id) {
            this.attrEdit(id)

            if (!this.sendRoom) {
                this.sendRoom = true
                this.$store.dispatch('updateObituaryAppsRoom', this.edit)
                    .then(() => {
                        this.showErrorRoom = false;
                        this.sendRoom = false
                        this.errors = [];
                        this.loadObituaryApp()
                        this.modal_add_room = !this.modal_add_room
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showErrorRoom = true;
                        this.sendRoom = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        destroyObituaryAppsRoom() {
            this.visible = !this.visible
            this.$store.dispatch('destroyObituaryAppsRoom', this.delete)
                .then(() => {
                    this.loadObituaryApp()
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteDefault)
                })
                .catch(() => {
                    this.loadObituaryApp()
                    this.$store.commit('addToast', ACTION_MESSAGES.deleteErrorDefault)
                })
        },

        openModal(data) {
            this.visible = !this.visible
            this.message = `${this.me.name} , deseja realmente deletar ${data.name}`
            this.delete.idobituary = data.id
        },

        closeModal() {
            this.visible = !this.visible
        },

    },

    computed: {
        data() {
            return data
        },
        me() {
            return this.$store.state.auth.me
        },

        selectCompanyRestrict() {
            return this.$store.state.var.selectCompanyRestrict
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        selectObituaryList() {
            return this.$store.state.var.selectObituaryList
        }
    }

}

</script>

<style src="@/assets/css/tabs.css"></style>

<style scoped>
.glider,
.tabs > input[type="radio"] + label {
    width: 50%;
}

#tab-2:checked ~ .glider {
    left: 50%;
}

.add-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}

.container-obituary {
    padding: 15px;
    margin: 15px 0;
    background: #e7e7e7;
}

.modal-add-obituary-room {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    position: fixed;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.5);
}

.container-obituary-room {
    width: 80%;
    height: auto;
    position: relative;
}

.container-obituary-room .btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > :not(.btn-check) + .btn {
    border-radius: 0.375rem;
}

.btn-delete:not([disabled]):not(.disabled).active,
.btn-delete:not([disabled]):not(.disabled):active,
.btn-delete:not([disabled]):not(.disabled).hover,
.btn-delete:not([disabled]):not(.disabled):hover {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: transparent !important;
}

.btn-delete {
    color: white;
    float: right;
    height: 42px;
    min-width: 42px;
    line-height: 20px;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-delete img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(54deg) brightness(102%) contrast(101%);
}

@media (max-width: 428px) {
    .container-obituary-room {
        width: 95%;
    }

    .container-obituary-room .btn-close {
        margin: 3%;
    }

    .add-button {
        top: 10px;
    }
}

</style>
