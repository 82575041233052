import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'user';
export default {

    loadUsers(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_USERS', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeUser(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadUser(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },


    updateUser(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    updateUserPassword(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}userpassword`, formData)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    destroyUser(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    updateUserPhoto(context, formData) {
        context.commit('PRELOADER', true)
        let config = { headers: { 'Content-Type': 'multipart/form-data' } }
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}user-photo`, formData, config)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    }
}