import axios from "axios";
import { URL_BASE } from "@/configs/constants";

const RESOURCE = 'app/menu-table';
export default {

    loadMenusTablesApps(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, { params })
                .then(response => {
                    context.commit('LOAD_MENU_TABLE_APP', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                    return reject(errors)
                })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeMenuTableApp(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadMenuTableApp(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch((e) => reject(e))
        })
    },

    updateMenuTableApp(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((r) => { resolve(r) })
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    destroyMenuTableApp(context, id) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then(response => resolve(response.data))
                .catch(errors => reject(errors))
                .finally(() => context.commit('PRELOADER', false))
        })
    },

    loadContentsMenusTablesApps(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/menu-table-contents`, { params })
                .then(response => {
                    context.commit('LOAD_CONTENT_MENU_TABLE_APP', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    loadFilesMenusTablesApps(context, params) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}app/menu-table-files`, { params })
                .then(response => {
                    context.commit('LOAD_FILE_MENU_TABLE_APP', response.data)
                    return resolve(response.data)
                }).catch(() => { return reject() })
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

    storeFilesMenuTableApp(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}app/menu-table-add-file`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => { context.commit('PRELOADER', false) })
        })
    },

}