import AdminComponent from '@/components/dashboard/AdminComponent'
import MenuTableItemSearch from '@/views/search/MenuTableItemSearch'

export default {
    path: '/menu-table-item',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search/:id', name: 'menu-table-item-search', component: MenuTableItemSearch, props: true },
    ]
}