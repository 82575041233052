<template>
    <painel-component :title="'Adicionar Itens'">
        <ErrorComponent :errors="errors" v-if="showError" />
        <button-one class="button-add-item" :itens="buttonAddItem" @click="addItens" />

        <form>
            <div class="content-item">
                <row-component class="content-item-container" v-for="(itens, index) in itensAdd" :key="index">
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                        :class="{ 'formError': errors.name }">
                        <label>Nome da Item:</label>
                        <input-component v-model="itens.name" :type-input="'text'" />
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                        :class="{ 'formError': errors.price }">
                        <label>Preço:</label>
                        <input-component v-money="money" v-model="itens.price" :type-input="'text'" />
                    </row-col-component>

                    <row-col-component v-if="itensAdd.length > 1"
                        :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-4'">
                        <button tooltip="Remover Item" @click="removeItens(index)" type="button" class="button-delete-item">
                            <img src="@/assets/icones/trash.svg">
                        </button>
                    </row-col-component>
                </row-component>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add @click="$emit('close-item-create')" style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';
import { MONEY } from '@/configs/constants'
import { VMoney } from 'v-money'

export default {
    created() {
        this.addItens()
    },

    props: {
        id: { required: true }
    },

    emits: ['close-item-create', 'close-item-submit'],

    name: 'MenuTableItemCreate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonOne,
        ButtonAdd,
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            buttonAdd: {
                route: '',
                icon: 'cross',
                name: 'Fechar',
                tooltip: 'Fechar'
            },

            buttonOne: {
                icon: 'plus',
                type: 'button',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            buttonAddItem: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar Item'
            },

            formDate: {
                id: this.id,
                itens: []
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeMenuTableItem', this.formDate)
                    .then(() => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$emit('close-item-submit', 1)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        addItens() {
            this.formDate.itens.push({
                name: '',
                price: ''
            })
        },

        removeItens(index) {
            this.formDate.itens.splice(index, 1)
        },
    },

    computed: {

        itensAdd() {
            return this.formDate.itens
        },

    }

}

</script>

<style scoped>
.content-item {
    width: 100%;
    height: 500px;
    margin: 15px 0;
    padding: 15px 25px;
    overflow: auto;
    overflow-x: hidden;
}

.content-item-container {
    margin-bottom: 6px;
    background-color: #dddddd;
}

.button-add-item {
    top: 0;
    right: 0;
    margin: 3% 2% 5% 2%;
    position: absolute;
}

.button-delete-item {
    line-height: 20px;
    min-width: 42px;
    height: 42px;
    border-radius: 5px;
    border: 0;
    color: #fff;
    background-color: #dc3545;
    cursor: pointer;
}

.button-delete-item:not([disabled]):hover,
.button-delete-item:not([disabled]):active {
    background-color: #ce3343 !important;
    color: white;
}

.button-delete-item img {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(338deg) brightness(110%) contrast(101%);
}

@media (max-width:415px) {

    .mt-4{
        margin-top: 0rem !important;
    }

}

@media (max-width:375px) {
    .content-item {
        height: 345px;
    }

    .mt-4{
        margin-top: 0rem !important;
    }

}
</style>