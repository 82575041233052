<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.name }">
                    <label>Nome do Usuário:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                    :class="{ 'formError': errors.sexo }">
                    <label>Sexo:</label>

                    <input type="radio" id="feminino" name="radio" v-model="formDate.sexo" value="1">
                    <label for="feminino">Feminino</label>

                    <input type="radio" id="masculino" name="radio" v-model="formDate.sexo" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.email }">
                    <label>Login:</label>
                    <input-component :type-input="'text'" v-model="formDate.email" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.group_id }">
                    <label>Grupo:</label>
                    <multiselect v-model="formDate.group_id" :searchable="true" :options="selectGroupUser"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input " type="checkbox" id="checkBoxMaster" @click="checkAll()">
                        <label class="form-check-label">Empresas</label>
                    </div>

                    <div class="form-check" v-for="(company, index) in selectCompany" :key="index">
                        <input type="checkbox" :value="company.value" @click="checkOne(company.value)"
                            :id="'checkOne' + company.value" :class="'form-check-input checkBoxAll'">
                        <label class="form-check-label">{{ company.name }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>

        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/buttons/ButtonAdd';
import ButtonOne from '@/components/buttons/ButtonOne';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.$store.dispatch('loadSelectGroupUser')
        this.$store.dispatch('loadSelectCompany')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
    },

    name: 'UserCreate',

    components: {
        PainelComponent,
        ErrorComponent,     
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonAdd,
        ButtonOne
    },

    data() {
        return {
            switches: false,
            buttonAdd: {
                route: '/user/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                type: 'button',
                icon: 'plus',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                name: '',
                sexo: 1,
                status: '',
                email: '',
                group_id: '',
                password: '',
                companies: []
            },

            errors: [],           
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeUser', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        setTimeout(() => this.$router.push({ name: 'user-edit', params: { id: r.data } }), 2100);
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        checkOne(id) {

            let company = document.getElementById("checkOne" + id);
            if (company.checked) {
                if (!this.formDate.companies.includes(id)) {
                    this.formDate.companies.push(id)

                }
            } else {
                var indexArry = this.formDate.companies.indexOf(id);
                if (indexArry !== -1) {
                    this.formDate.companies.splice(indexArry, 1);
                }
            }

            let companyLength = this.selectCompany.length
            let listCompanys = document.getElementsByClassName("checkBoxAll");
            let contChecked = 0

            for (var i = 0; i < listCompanys.length; ++i) {
                if (listCompanys[i].checked)
                    contChecked = contChecked + 1
            }

            if (companyLength == contChecked) {
                document.getElementById("checkBoxMaster").checked = true
            } else {
                document.getElementById("checkBoxMaster").checked = false
            }

        },

        checkAll() {
            let listCompanys = document.getElementsByClassName("checkBoxAll");
            if (document.getElementById("checkBoxMaster").checked) {
                for (var i = 0; i < listCompanys.length; ++i) {
                    listCompanys[i].checked = true;
                    let valueCompany = parseInt(listCompanys[i].value);
                    if (!this.formDate.companies.includes(valueCompany)) {
                        this.formDate.companies.push(valueCompany)
                    }
                }
            } else {
                for (var cont = 0; cont < listCompanys.length; ++cont) {
                    listCompanys[cont].checked = false;
                    let valueCompany = parseInt(listCompanys[cont].value);
                    var indexArry = this.formDate.companies.indexOf(valueCompany);
                    if (indexArry !== -1) {
                        this.formDate.companies.splice(indexArry, 1);
                    }
                }
            }
        },
    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },

        selectGroupUser() {
            return this.$store.state.var.selectGroupUser
        },

        selectCompany() {
            return this.$store.state.var.selectCompany
        }
    },

    beforeMount() {
        setTimeout(() => {
            this.selectGroupUser.push({ "label": "Master", "value": 1 })
        }, 1500)
    }
}
</script>


