import AdminComponent from '@/components/dashboard/AdminComponent'
import ContentSearch from '@/views/search/ContentSearch'
import ContentFileSearch from '@/views/search/ContentFileSearch'

export default {
    path: '/content',
    component: AdminComponent,
    meta: { auth: true },
    children: [
        { path: 'search', name: 'content-search', component: ContentSearch },
        { path: 'search-file/:id', name: 'content-file-search', component: ContentFileSearch, props: true },
    ]
}