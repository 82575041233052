<template>
    <PreLoader/>

    <div class="container-navigation" :class="{'toggle' : toggle}">
        <div class="p-3 mb-3">
            <img class="img-fluid" src="@/assets/img/logo-02.png" alt="">
        </div>

        <ul class="menu">
            <template v-for="(data, index) in object.menus" :key="index">
                <li @click="drop[data.id] = !drop[data.id]">
                    {{ `0` + data.id }} - {{ data.name }}
                </li>

                <transition name="dropMenu">
                    <div class="sub-menu-container" v-if="drop[data.id]">
                        <div v-for="(dataTwo, indexTwo) in data.subMenu" :key="indexTwo">
                            <div class="sub-menu" role="button" @click="playTutorials(dataTwo)">
                                <img class="visa" src="@/assets/icones/check.svg" alt="">
                                <p class="m-0">{{ dataTwo.name }}</p>
                            </div>
                        </div>
                    </div>
                </transition>
                <br>
            </template>
        </ul>
    </div>

    <div class="video-area" :class="{'toggle-area' : toggle}">
        <div class="menu-toggle" @click="toggleMenu">
            <div class="hamburger-menu-button">
                <div class="hamburger-menu" :class="{ open: toggle }"></div>
            </div>
        </div>

        <video id="tutorials" controls loop>
            <source src="">
        </video>

        <div class="controls">
            <button @click="nextPrevious(1)">
                <img src="@/assets/icones/chevron_left_double.svg" alt="">
            </button>

            <button @click="nextPrevious(2)">
                <img src="@/assets/icones/chevron_right_double.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
import {ACTION_MESSAGES} from "@/configs/messages";
import PreLoader from "@/components/PreLoader.vue";

export default {
    name: "TutorialSearch",
    components: {PreLoader},

    created() {
        this.$store.commit("PRELOADER", true)
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            object: {},
            playlist: [],
            drop: [{
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
                9: false,
                10: false,
            }],

            toggle: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadTutorials")
                .then((response) => {
                    this.object = response

                    this.playlist = response.navigation.flatMap(el => {
                        return el.link
                    })

                }).catch(() => {
                this.$store.commit("addToast", ACTION_MESSAGES.errorDefault)
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('video-show') !== -1) {
                        this.$store.commit("PRELOADER", false)
                    } else {
                        window.close()
                    }
                }).catch(() => {
                window.close()
            })
        },

        toggleMenu() {
            this.toggle = !this.toggle;
        },

        playTutorials(data) {
            const video = document.querySelector("#tutorials")
            video.src = data.link
            video.play()

            this.toggle = false
        },

        nextPrevious(n) {
            const video = document.querySelector("#tutorials")
            if (n === 1) {
                if (this.playlist.includes(video.src)) {
                    let position = this.playlist.indexOf(video.src)
                    video.src = this.playlist[position - 1]
                    video.play()
                }
            }

            if (n === 2) {
                if (this.playlist.includes(video.src)) {
                    let position = this.playlist.indexOf(video.src)
                    video.src = this.playlist[position + 1]
                    video.play()
                }
            }
        },
    }
}
</script>

<style scoped>
.container-navigation {
    z-index: 2;
    width: 260px;
    padding: 0 10px;
    position: fixed;
    overflow-x: auto;
    min-height: 100vh;
    background: #3b3b3b;
    transition: all 0.3s linear;
}

.menu {
    padding: 0;
    list-style: none;
}

.menu li {
    width: 100%;
    height: 50px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    padding: 0 0.5rem;
    border-radius: 5px;
    background: #585858;
    align-items: center;
}

.sub-menu-container {
    transition: all 0.3s ease-in-out;
}

.sub-menu {
    height: 50px;
    display: flex;
    color: #ffffff;
    font-size: 14px;
    align-items: center;
    border-bottom: 1px solid #dddddd;
}

.sub-menu img {
    height: 25px;
    margin-right: 0.5rem;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(65deg) brightness(104%) contrast(104%);
}

.sub-menu img.visa {
    filter: invert(61%) sepia(47%) saturate(1923%) hue-rotate(338deg) brightness(101%) contrast(92%);
}

.video-area {
    float: right;
    display: grid;
    padding: 50px;
    min-height: 100vh;
    position: relative;
    place-items: center;
    background: #2f2f2f;
    width: calc(100% - 260px);
    transition: transform 0.3s linear;
}

.video-area video {
    width: 90%;
    height: 100%;
}

.controls {
    display: flex;
}

.controls button {
    border: 0;
    width: 50px;
    height: 50px;
    display: grid;
    margin: 0 1rem;
    border-radius: 50%;
    place-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.controls button img {
    width: 60%;
    height: auto;
    display: block;
    filter: invert(61%) sepia(47%) saturate(1923%) hue-rotate(338deg) brightness(101%) contrast(92%);
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
    transform: scaleY(0);
}

.menu-toggle {
    top: 10px;
    display: none;
}

.hamburger-menu,
.hamburger-menu::after {
    background: #eeeeee;
}

.toggle {
    left: 0 !important;
}

.toggle-area {
    transform: translate3d(260px, 0, 0);
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
    }

    .container-navigation {
        left: -260px;
    }

    .video-area {
        width: 100%;
        padding: 0 1rem;
    }

    .video-area video {
        width: 100%;
        height: unset;
    }

    .controls {
        display: none;
    }
}
</style>