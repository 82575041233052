<template>
    <painel-component :title="'Adicionar'">
        <ErrorComponent :errors="errors" v-if="showError"/>

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome do Usuário:</label>
                    <input-component :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 mt-4'"
                                   :class="{ 'formError': errors.sexo }">
                    <label>Sexo:</label>

                    <input type="radio" id="feminino" name="radio" v-model="formDate.sexo" value="1">
                    <label for="feminino">Feminino</label>

                    <input type="radio" id="masculino" name="radio" v-model="formDate.sexo" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.email }">
                    <label>Login:</label>
                    <input-component :type-input="'text'" v-model="formDate.email"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                                 :noResultsText="this.$store.state.var.selectNotSearch"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd"></button-add>
                </row-col-component>
            </row-component>

        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent.vue";
import ButtonAdd from "@/components/buttons/ButtonAdd.vue";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import ButtonOne from "@/components/buttons/ButtonOne.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import RowComponent from "@/components/row/RowComponent.vue";
import Multiselect from "@vueform/multiselect/src/Multiselect";
import RowColComponent from "@/components/row/RowColComponent.vue";
import {ACTION_MESSAGES} from "@/configs/messages";

export default {
    name: "UserLocationCreate",

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário Local')
        this.$store.dispatch('loadSelectGroupUser')
        this.$store.dispatch('loadSelectCompany')
        this.$store.commit('PRELOADER', true)
        this.checkACL()
    },

    components: {
        RowColComponent,
        RowComponent,
        InputComponent,
        ButtonOne,
        ErrorComponent,
        ButtonAdd,
        PainelComponent,
        Multiselect
    },

    data() {
        return {
            buttonAdd: {
                route: '/user-location/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                type: 'button',
                icon: 'plus',
                name: 'Adicionar',
                tooltip: 'Adicionar'
            },

            formDate: {
                name: '',
                sexo: '',
                status: '',
                email: ''
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeUserLocation', this.formDate)
                    .then((r) => {
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.postDefault)
                        this.$router.push({name: 'user-location-edit', params: {id: r.data}})
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.response.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('user-location-create') !== -1) {
                        this.$store.commit('PRELOADER', false)
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        },
    }
}
</script>

<style scoped>

</style>