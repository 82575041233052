<template>
    <painel-component :title="'Editar'">
        <ErrorComponent :errors="errors" v-if="showError" />

        <button-one v-if="buttonCheck.create" class="add-button" :itens="buttonTwo" @click="routeAdd" />

        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Nome do Grupo:</label>
                    <input-component :type-input="'text'" v-model="formDate.name" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <multiselect v-model="formDate.status" :searchable="true" :options="selectActiveInactive"
                        :noResultsText="this.$store.state.var.selectNotSearch" />
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                    v-for="(permission, index) in selectPermissions" :key="index">
                    <div class="form-check checkBoxHeader">
                        <input class="form-check-input" type="checkbox" @click="checkAll(index)" :value="index"
                            :id="'checkBoxMaster' + index" :name="index">
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>
                    <div class="form-check" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input type="checkbox" :value="option.value" :id="'checkOne' + option.value"
                            :class="'form-check-input checkBoxAll' + index" @click="checOne(index, permission)">
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-one :disabled="sendForm" style="width: 100%" :itens="buttonOne" @click="submit" />
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-add style="width: 100%" :itens="buttonAdd" />
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import ErrorComponent from '@/components/alerts/ErrorComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';
import InputComponent from '@/components/forms/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonOne from '@/components/buttons/ButtonOne';
import ButtonAdd from '@/components/buttons/ButtonAdd';
import { ACTION_MESSAGES } from '@/configs/messages';

export default {
    created() {
        this.loadGroupUser()
        this.$store.commit('PRELOADER', true)
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
    },

    name: 'GroupUserUpdate',
    components: {
        PainelComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect,
        ButtonOne,
        ButtonAdd,
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            buttonAdd: {
                route: '/group-user/search',
                icon: 'jump_left',
                name: 'Voltar',
                tooltip: 'Voltar'
            },

            buttonOne: {
                icon: 'floppy',
                type: 'button',
                name: 'Salvar',
                tooltip: 'Salvar'
            },

            buttonTwo: {
                icon: 'plus',
                type: 'button',
                tooltip: 'Adicionar'
            },

            buttonCheck: {
                create: false
            },

            formDate: {
                name: '',
                status: '',
                permissions: []
            },

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadGroupUser() {
            this.$store.dispatch('loadSelectPermissions', 'page');
            this.$store.dispatch('loadGroupUser', this.id).then((r) => {
                this.formDate = r;
                this.checkACL()
                setTimeout(() => {
                    this.checkBoxCheckedStart()
                }, 1100)
            }).catch(() => {
                this.$store.commit('addToast', ACTION_MESSAGES.errorDefault)
            })
        },

        routeAdd() {
            this.$router.push({ name: 'group-user-create' })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('group-user-edit') !== -1) {
                        this.$store.commit('PRELOADER', false)

                        if (r.data.indexOf('group-user-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({ name: 'home' })
                    }
                }).catch(() => {
                    this.$router.push({ name: 'home' })
                })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateGroupUser', this.formDate)
                    .then((r) => {
                        this.formDate = r.data
                        this.showError = false;
                        this.sendForm = false
                        this.errors = [];
                        this.$store.commit('addToast', ACTION_MESSAGES.updateDefault)
                    })
                    .catch((r) => {
                        this.showError = true;
                        this.sendForm = false
                        this.errors = r.data.errors;
                        this.$store.commit('addToast', ACTION_MESSAGES.errorFormDefault)
                    })
            }
        },

        checOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            var cont = 0;
            for (var i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    var indexArry = this.formDate.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArry !== -1) {
                        this.formDate.permissions.splice(indexArry, 1);
                    }
                }
            }
            if (cont >= permission.options.length) {
                document.getElementById("checkBoxMaster" + index).checked = true
            } else {
                document.getElementById("checkBoxMaster" + index).checked = false
            }
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (var i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formDate.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formDate.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (var cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    var indexArry = this.formDate.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArry !== -1) {
                        this.formDate.permissions.splice(indexArry, 1);
                    }
                }
            }
        },

        checkBoxCheckedStart() {
            let totalpage = 0;
            let cont = 0;
            let i = 0;
            let permissionsList = this.formDate.permissions;
            this.selectPermissions.forEach(function (element) {
                cont = 0;
                totalpage = element.options.length;
                element.options.forEach(function (item) {
                    if (permissionsList.includes(item.value)) {
                        document.getElementById("checkOne" + item.value).checked = true
                        cont = cont + 1;
                    }
                });
                if (cont >= totalpage) {
                    document.getElementById("checkBoxMaster" + i).checked = true
                }
                i = i + 1;
            });
        },
    },

    computed: {
        selectPermissions() {
            return this.$store.state.var.selectPermissions
        },

        selectActiveInactive() {
            return this.$store.state.var.selectActiveInactive
        }
    }
}

</script>

<style scoped>
hr {
    margin-top: 0;
}

input:focus {
    box-shadow: none;
    border-color: #b3b3b3;
}

.form-check-input:checked {
    background-color: #d67c1c;
    border-color: #d67c1c;
}

.form-check-input:not([disabled]):active {
    color: #fff;
    background-color: #d67c1c !important;
    background-color: #d67c1c;
}

.form-check-input:active {
    color: #fff;
    background-color: #d67c1c !important;
    background-color: #d67c1c;
}

.add-button {
    top: 0;
    right: 0;
    margin: 2%;
    position: absolute;
}
</style>